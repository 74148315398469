export default class LoadingService {
  private reasonsToLoad: string[];

  constructor(private setLoading: (value: boolean) => void) {
    this.reasonsToLoad = [];
  }

  public setReason(reason: string, value: boolean): void {
    if (value && !this.reasonsToLoad.includes(reason)) {
      this.reasonsToLoad.push(reason);
    }
    if (!value && this.reasonsToLoad.includes(reason)) {
      this.reasonsToLoad.splice(this.reasonsToLoad.indexOf(reason), 1);
    }
    this.setLoading(this.reasonsToLoad.length !== 0);
  }

  public setReasons(reasons: string[], value: boolean): void {
    for (const reason of reasons) {
      this.setReason(reason, value);
    }
  }

  private getUniqueReason(): string {
    let reason;
    while (!reason || this.reasonsToLoad.includes(reason)) {
      reason =  'loadService_internal_' + Math.floor(Math.random() * 1000);
    }
    return reason;
  }

  public async await<Type>(promise: Promise<Type>, cause?: string): Promise<Type> {
    return new Promise((resolve, reject) => {
      const reason = cause || this.getUniqueReason();
      this.setReason(reason, true);
      promise.then((value: Type) => {
        resolve(value);
        this.setReason(reason, false);
      }).catch((err) => {
        reject(err);
        this.setReason(reason, false);
      });
    });
  }

  public isLoading(): boolean {
    return this.reasonsToLoad.length !== 0;
  }
}
