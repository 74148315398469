import React, { useContext } from 'react'
import { useEffect, useMemo, useState } from 'react';
import styles from './GettingStart.module.scss';
import CustomInputField from '../shared/customInputField/CustomInputField';
import CustomDropdown from '../shared/CustomDropdown/customDropdown';
import { Form, Formik } from 'formik';
import CustomRangeSlider from '../shared/customRangeSlider/customRangeSlider';
import CustomLayout from '../layout/commonLayout';
import DollarIcon from '../../icons/dollar.icon';
import { useLocation, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import CustomButton from '../shared/CustomButton/customButton';
import SaveIcon from '../../icons/save.icon';
import RightLongArrowIcon from '../../icons/rightLongArrow.icon';
import TimePicker from '../shared/timePicker/timePicker';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import { Auth } from 'aws-amplify';
import LoadingService from '../../services/loading/loading.service';
import LeftArrow from '../../icons/leftArrow.icon';
import { CandidateService } from '../../services/candidate/candidate.service';
import Tooltip from '../shared/Tooltip/tooltip';
import CustomSearch from '../shared/listSearch/listSearch';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import { UserContext } from '../../context';
import { MasterDataContext } from '../../context/masterData';
import { UpdateCandidateDataRequest } from '../../services/candidate/candidate.req.model';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';
import { ScrollToFieldError } from "../../scrollToFieldError/scrollToFieldError";

export type GettingStartProps = {
  hours_weekly: number;
  commute_minutes: number;
  commitment: string;
  work_type: string;
  suburb: string;
  on_site_days: number;
  pay_type: string;
  pay_rate: string;
  pay_hourly: number;
  pay_annual: number;
  notice_period_days: string;
  preferred_role: string;
};

function GettingStart() {
  const navigate = useNavigate();
  const location = useLocation();

  const user = useContext(UserContext);
  const masterData = useContext(MasterDataContext);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [source, setSource] = useState<string | null>(null);
  const [hideSkip, setHideSkip] = useState(false);
  const [hideSaveAndExit, setHideSaveAndExit] = useState(false);
  const [roleList, setRoleList] = useState<{ value: string; text: string }[]>([]);
  const [formData, setFormData] = useState({
    hours_weekly: 0,
    commitment: '',
    work_type: '',
    suburb: '',
    commute_minutes: 0,
    on_site_days: 0,
    pay_type: '',
    pay_rate: '',
    pay_hourly: 0,
    pay_annual: 0,
    notice_period_days: '',
    preferred_role: '',
  });

  const initialVal: GettingStartProps = useMemo(() => {
    return {
      hours_weekly: formData.hours_weekly || 0,
      commitment: formData.commitment || '',
      work_type: formData.work_type || '',
      suburb: formData.suburb || '',
      commute_minutes: formData.commute_minutes || 0,
      on_site_days: formData.on_site_days || 0,
      pay_type: formData.pay_type || '',
      pay_rate: formData.pay_rate || '',
      pay_hourly: formData.pay_hourly || 0,
      pay_annual: formData.pay_annual || 0,
      notice_period_days: formData.notice_period_days || '',
      preferred_role: formData.preferred_role || '',
    };
  }, [formData]);

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  useEffect(() => {
    //scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.from) {
        setSource(location.state.from);
      }
      if (location.state.hideSkip) {
        setHideSkip(location.state.hideSkip);
      }
      if (location.state.hideSaveAndExit) {
        setHideSaveAndExit(location.state.hideSaveAndExit);
      }
    }
  }, [location.state]);

  useEffect(() => {
    if (masterData?.masterData) {
      setRoleList(masterData.masterData.filter((fd:any)=> fd.category === 'finance_jobs')
        .sort((a: any, b: any) => a.order - b.order)
        .map((item:any)=>({ value: item.id, text: item.value })));
    } else {
      if (masterData.isError) {
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }


  }, [masterData]);

  useEffect(() => {
    loadingService.await(candidateService.updateLastPage('gettingStart'));
    async function fetchData() {
      try {
        let savedValues =  user?.candidateData;
        if (savedValues && Object.entries(savedValues)?.length === 0) {
          savedValues = await candidateService.getCandidateDataT()
        }
        if (savedValues) {
          setFormData({
            hours_weekly: savedValues?.hours_weekly,
            commitment: savedValues?.commitment,
            work_type: savedValues?.work_type,
            suburb: savedValues?.suburb,
            commute_minutes: savedValues?.commute_minutes,
            on_site_days: savedValues?.on_site_days,
            pay_type: savedValues?.pay_annual === 0 ? 'hourly' : 'annual',
            pay_rate: savedValues?.pay_annual === 0 ? savedValues?.pay_hourly : savedValues?.pay_annual,
            pay_hourly: savedValues?.pay_hourly,
            pay_annual: savedValues?.pay_annual,
            notice_period_days: `${savedValues?.notice_period_days}`,
            preferred_role: savedValues?.preferred_role,
          });
        }
      } catch (err :any) {
        setErrorMessage(err.message)
      }
    }
    fetchData().then();
  }, [navigate, candidateService, user, loadingService]);

  const validationSchema = Yup.object().shape({
    hours_weekly: Yup.number().positive('Hours weekly is required').required('Hours weekly is required'),
    commitment: Yup.string().required('Please select commitment type'),
    work_type: Yup.string().required('Please select work type'),
    on_site_days: Yup.number().when('work_type', {
      is: (work_type: string) => work_type === 'Hybrid',
      then: Yup
        .number()
        .positive('Please select one of the options')
        .required('Please select one of the options'),
    }),
    pay_type: Yup.string().required('Please select pay type'),
    pay_rate: Yup.string()
      .required('Expected base rate is required')
      .matches(/^[^0]/, 'Expected base rate is required')
      .matches(/^[0-9]+$/, 'Invalid number')
      .typeError('Please enter a valid rate'),
    notice_period_days: Yup.string().notOneOf(['undefined'], 'Please select notice period').required('Please select notice period'),
    suburb: Yup.string().when('work_type', {
      is: (work_type: string) => work_type === 'Hybrid' || work_type === 'On-Site',
      then: Yup.string().required('Location is required'),
    }),
    commute_minutes: Yup.number().when('work_type', {
      is: (work_type: string) => work_type === 'Hybrid' || work_type === 'On-Site',
      then: Yup
        .number()
        .positive('This field is required')
        .required('This field is required'),
    }),
    preferred_role: Yup.string().required('Please select your preferred role'),
  });

  /**
   * Convert the form values to the request params
   * @param values form values
   * @returns request params
   */
  const getParams = (values: GettingStartProps): UpdateCandidateDataRequest => {
    return {
      hours_weekly: values.hours_weekly,
      ...(values.commitment !== '' && { commitment: values.commitment }),
      ...(values.work_type !== '' && { work_type: values.work_type }),
      on_site_days: values.work_type === 'On-Site' ? 5 : values.on_site_days,
      pay_hourly: values.pay_type === 'hourly' ? parseInt(values.pay_rate) : 0,
      pay_annual: values.pay_type === 'annual' ? parseInt(values.pay_rate) : 0,
      notice_period_days: !isNaN(parseInt(values.notice_period_days)) ? parseInt(values.notice_period_days) : 0,
      ...(values.suburb !== '' && { suburb: values.suburb }),
      commute_minutes: values.commute_minutes,
      preferred_role: values.preferred_role,
    };
  };

  const handleSave = async (values: GettingStartProps): Promise<boolean> => {
    const params = getParams(values);
    const res = await loadingService.await(candidateService.updateCandidateData(params));
    return res !== null && res.status == 'success';
  };

  const handleSubmit = async (values: GettingStartProps) => {
    try {
      // Save the values
      const res = await handleSave(values);
      if (!res) {
        setErrorMessage('Failed to save. Please try again later.');
        return;
      }
      // Update the user context with the new data
      if (Object.keys(user?.candidateData)?.length !== 0 ) {
        user.setCandidateData({
          ...user.candidateData,
          ...getParams(values),
          profile_pages: JSON.stringify({ ...JSON.parse(user.candidateData.profile_pages), gettingStart: 'complete' }),
        });

      }

      // Update the profile pages
      await loadingService.await(candidateService.updateProfilePages('gettingStart', 'complete', user.candidateData));

      // Navigate to the next page
      if (source) {
        navigate('/' + source);
      } else {
        navigate('/resumeImport', { state: { hideSkip, hideSaveAndExit } });
      }
    } catch (err) {
      setErrorMessage('Failed to save. Please try again later.');
    }
  };

  const handleSaveAndExit = async (values: GettingStartProps) => {
    const res = await handleSave(values);
    if (!res) {
      setErrorMessage('Failed to save. Please try again later.');
      return;
    }
    await loadingService.await(candidateService.updateLastPage('gettingStart'));
    localStorage.removeItem('masterData');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('login_form');
    await loadingService.await(Auth.signOut());
    navigate('/signIn?type=candidateSignIn');
  };

  const handleSkip = async () => {
    try {
      if (Object.keys(user.candidateData).length !== 0 ) {
        user.setCandidateData({
          ...user.candidateData,
          profile_pages: JSON.stringify({ ...JSON.parse(user.candidateData.profile_pages), gettingStart: 'skip' }),
        });
      }

      await loadingService.await(candidateService.updateProfilePages('gettingStart', 'skip', user.candidateData));
      navigate('/resumeImport');
    } catch (err) {
      setErrorMessage('Failed to save user data. Please try again later.');
    }
  };

  const noticePeriodList = [
    { value: '0', text: 'None, I\'m ready now' },
    { value: '7', text: '1 week' }, { value: '14', text: '2 weeks' }, { value: '21', text: '3 weeks' }, { value: '28', text: '4 weeks' },
    { value: '35', text: '5 weeks' }, { value: '42', text: '6 weeks' }, { value: '49', text: '7 weeks' }, { value: '56', text: '8 weeks' },
  ];
  const onsiteDay = [{ value: 1, text: '1 Day' }, { value: 2, text: '2 Days' }, { value: 3, text: 'More than 2 days' }];

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <ErrorBannerModal open={errorMessage !== null} onClose={() => {
          setErrorMessage(null);
        }} errorMessage={errorMessage ?? ''} />
        <CustomLayout title={'Getting Started'} subTitle={'Tell me your preferences'} pageName={'gettingStart'} hideGoBack progressValue={0} showSignUpProcess={source !== 'profile'}>

          <Formik initialValues={initialVal} validationSchema={validationSchema} onSubmit={handleSubmit} enableReinitialize>
            {({ values, setFieldValue, errors, setFieldTouched, touched }) => (
              <Form>
                <ScrollToFieldError/>
                <div>
                  <div className={styles.contentQuestion} style={{ position: 'relative' }}>
                    <h6 className={styles.widthLeft}>Number of hours you prefer to work?</h6>
                    <div className={styles.answerLength} style={{ position: 'relative' }}>
                      <CustomRangeSlider min={0} name={'hours_weekly'} max={40} step={1} defaultValue={`${values.hours_weekly}`} onClickStep={(s) => { setFieldValue('hours_weekly', parseInt(s)); }} />
                      {touched.hours_weekly && errors.hours_weekly && (
                        <div
                          className={`${styles.yupError} ${styles.topAlign}`}

                        >
                          {errors.hours_weekly}
                        </div>
                      )}
                    </div><br /><br />
                  </div>
                  <div className={styles.contentQuestion}>
                    <h6 className={styles.widthLeft}>Type of role commitment you prefer?</h6>
                    <div className={`d-flex justify-content-between ${styles.answerLength}`} style={{ position: 'relative' }}>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="commitment" id="commitment1" value='Permanent Full Time' checked={values.commitment === 'Permanent Full Time'} onChange={(e) => { setFieldValue('commitment', e.target.value); }} />
                        <label className="form-check-label" htmlFor="commitment1">
                          <h6 className={'gray-color-text text-normal'}>Permanent Full time</h6>
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="commitment" id="commitment2" value='Permanent Part Time' checked={values.commitment === 'Permanent Part Time'} onChange={(e) => { setFieldValue('commitment', e.target.value); }} />
                        <label className="form-check-label" htmlFor="commitment2">
                          <h6 className={'gray-color-text text-normal'}>Permanent Part time</h6>
                        </label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="commitment" id="commitment3" value='Casual' checked={values.commitment === 'Casual'} onChange={(e) => { setFieldValue('commitment', e.target.value); }} />
                        <label className="form-check-label" htmlFor="commitment3">
                          <h6 className={'gray-color-text text-normal'}>Casual</h6>
                        </label>
                      </div>
                      {touched.commitment && errors.commitment && (
                        <div className={styles.yupError}>{errors.commitment}</div>
                      )}
                    </div>
                  </div>
                  <div className={styles.contentQuestion}>
                    <h6 className={styles.widthLeft}>Preferred work type?</h6>
                    <div className={styles.answerLength}>
                      <div className={'d-flex justify-content-between'} style={{ position: 'relative' }}>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="work_type" id="work_type1" value='Hybrid' checked={values.work_type === 'Hybrid'} onChange={(e) => { setFieldValue('work_type', e.target.value); }} />
                          <label className="form-check-label" htmlFor="work_type1">
                            <h6 className={'text-normal gray-color-text'}>Hybrid</h6>
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="work_type" id="work_type2" value='On-Site' checked={values.work_type === 'On-Site'} onChange={(e) => { setFieldValue('work_type', e.target.value); }} />
                          <label className="form-check-label" htmlFor="work_type2">
                            <h6 className={'text-normal gray-color-text'}>Onsite</h6>
                          </label>
                        </div>
                        <div className="form-check">
                          <input className="form-check-input" type="radio" name="work_type" id="work_type3" value='Off-Site' checked={values.work_type === 'Off-Site'} onChange={(e) => {
                            setFieldValue('work_type', e.target.value);
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            values.work_type !== '' && setFieldValue('suburb', '');
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            values.work_type !== '' && setFieldValue('on_site_days', 0);
                            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
                            values.work_type !== '' && setFieldValue('commute_minutes', 0);
                          }}
                          />
                          <label className="form-check-label" htmlFor="work_type3">
                            <h6 className={'text-normal gray-color-text'}>Work From Home</h6>
                          </label>
                        </div>
                        {touched.work_type && errors.work_type && (
                          <div className={styles.yupError}>{errors.work_type}</div>
                        )}
                      </div>

                      {(values.work_type === 'Hybrid' || values.work_type === 'On-Site') && (
                        <div className={`mt-4 ${styles.resumeUploadBox}`}>
                          <div>
                            <h6 className={'gray-color-text'}>User&apos;s current location</h6>
                            {touched.suburb && errors.suburb && (
                              <div className={styles.yupError} style={{ position: 'relative', top: '3px', marginBottom: '10px' }}>
                                {errors.suburb}
                              </div>
                            )}
                            <div className={styles.noticeperiodWidth}>
                              <CustomSearch name={'suburb'} endPoint={value => 'location/autocomplete?' + new URLSearchParams({ input: value, specific: 'true' })}
                                onChange={(value: string) => setFieldValue('suburb', value)} defaultValue={values.suburb} timeout={1000} minChars={1} isLocationSearch={true}  />
                            </div>

                          </div>
                          <div className={`mt-3 ${styles.locationBox}`}>
                            <h6 className={`gray-color-text me-3 ${styles.locationQuestion}`}>How many minutes are you willing to travel each onsite day?</h6>
                            {touched.commute_minutes && errors.commute_minutes && (
                              <div className={styles.yupError} style={{ position: 'relative', top: '3px', marginBottom: '10px' }}>
                                {errors.commute_minutes}
                              </div>
                            )}
                            <div className={styles.timeDuration}>
                              <TimePicker name={'commute_minutes'} id={'commute_minutes'} handleChange={(e) => { setFieldValue('commute_minutes', parseInt(e)); }} defaultValue={values.commute_minutes ?? 0} />
                            </div>
                          </div>
                          {values.work_type === 'Hybrid' &&
                            <>

                              <h6 className={'gray-color-text mt-4'}>How many days are you willing to work on site?</h6>
                              <div className={styles.locationAnswer} style={{ position: 'relative' }}>
                                {onsiteDay.map((value, index) => (
                                  <div className="form-check mt-4" key={index} >
                                    <input className="form-check-input" type="radio" name="on_site_days" id={'on_site_days' + index} value={value.value} checked={values.on_site_days === value.value} onChange={(e) => { setFieldValue('on_site_days', parseFloat(e.target.value)); /*setFormData((prevValues) => ({...prevValues, on_site_days: parseInt(e.target.value)}));*/ }} />
                                    <label className="form-check-label" htmlFor={'on_site_days' + index}>
                                      <h6 className={'text-normal gray-color-text me-4'}>{value.text}</h6>
                                    </label>
                                  </div>
                                ))}
                                {touched.on_site_days && errors.on_site_days && (
                                  <div className={styles.yupError} style={{ marginTop: '20px' }}>
                                    {errors.on_site_days}
                                  </div>
                                )}
                              </div>
                            </>}
                        </div>)}
                    </div>
                  </div>
                  <div className={styles.contentQuestion}>
                    <div className={styles.widthLeft}>
                      <h6>Expected base rate?</h6>
                      <p className={'gray-color-text text-normal'}>excluding super - no commas or decimal places please</p>
                    </div>

                    <div className={`d-flex justify-content-between ${styles.answerLength}`}>
                      <div style={{ position: 'relative' }}>
                        {/* eslint-disable-next-line */}
                        <CustomInputField name={'pay_rate'} defaultValue={`${values.pay_rate}`} icon={<DollarIcon size={16} />} onChange={(e: any) => { setFieldValue("pay_rate", e.target.value); }} />
                      </div>
                      <div className={'d-flex justify-content-between align-items-center w-50'} style={{ position: 'relative' }}>
                        <div className={'d-flex align-items-center'}>
                          <div className="form-check me-0 me-lg-4 ms-2 ms-lg-0">
                            <input className="form-check-input" type="radio" name="pay_type" id="pay_type1" value='annual' checked={values.pay_type === 'annual'} onChange={(e) => { setFieldValue('pay_type', e.target.value); }} />
                            <label className="form-check-label" htmlFor="pay_type1">
                              <h6 className={'text-normal gray-color-text me-2'}>Annual</h6>
                            </label>
                          </div>
                          <div>
                            <Tooltip body={'To calculate your annual equivalent, please use a 40-hour week (8-hour day) as the basis of your calculation, for example, if the gross hourly rate you want is $50 per hour excluding super, the annual figure will be $104000 ($50 x 40 hours x 52 weeks) '} />
                          </div>
                        </div>
                        <div className="form-check ms-2 ms-lg-0">
                          <input className="form-check-input" type="radio" name="pay_type" id="pay_type2" value='hourly' checked={values.pay_type === 'hourly'} onChange={(e) => { setFieldValue('pay_type', e.target.value); }} />
                          <label className="form-check-label" htmlFor="pay_type2">
                            <h6 className={'text-normal gray-color-text'}>Hourly</h6>
                          </label>
                        </div>
                        {touched.pay_type && errors.pay_type && (
                          <div className={styles.yupError} style={{ marginTop: '20px' }}>{errors.pay_type}</div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className={styles.contentQuestion}>
                    <h6 className={styles.widthLeft}>Notice period?</h6>
                    <div className={styles.noticeperiodWidth} style={{ position: 'relative' }}>
                      <CustomDropdown
                        dataList={noticePeriodList}
                        name='notice_period_days'
                        onChange={(e: string) => setFieldTouched('notice_period_days', e === '' ? true : false)}
                        selectedValue={ noticePeriodList.filter((i) => { return i.value === values.notice_period_days; })[0] }
                        getSelectedItem={(i) => { setFieldValue('notice_period_days', i.value); }} />
                        {touched.notice_period_days && errors.notice_period_days && (
                          <div className={styles.yupError} style={{ marginTop: '30px' }}>
                            {errors.notice_period_days}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className={styles.contentQuestion}>
                    <h6 className={styles.widthLeft}>Preferred role?</h6>
                    <div className={styles.noticeperiodWidth} style={{ position: 'relative' }}>
                      <CustomDropdown dataList={roleList} name='pref_role'
                        selectedValue={ roleList.filter((fd)=> fd.value === values.preferred_role)[0]  }
                        getSelectedItem={(i) => { setFieldValue('preferred_role', i.value); }} />
                      {touched.preferred_role && errors.preferred_role && (
                        <div className={styles.yupError} style={{ marginTop: '30px' }}>
                          {errors.preferred_role}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'd-flex justify-content-between align-items-center mt-5'
                  }
                >
                  {source === 'profile' ? (<>
                    <div className={'d-flex align-items-center'}>
                      <CustomButton disabled={isLoading} type={'button'} text={'Go Back'} icon={<LeftArrow />} iconSide={'left'} className={styles.whiteBtn} onClick={() => navigate('/profile')} />
                      <CustomButton disabled={isLoading} type={'submit'} text={'Save & Exit'} icon={<SaveIcon />} iconSide={'left'} className={`ms-3 ${styles.outlineBtn}`} />
                    </div>
                  </>
                  ) : (
                    <>
                      <div className={'d-flex align-items-center'}>
                        {!hideSaveAndExit && (
                          <CustomButton
                            disabled={isLoading}
                            type={'button'}
                            text={'Save & Exit'}
                            icon={<SaveIcon />}
                            onClick={() => handleSaveAndExit(values)}
                            iconSide={'left'}
                            className={`ms-3 ${styles.outlineBtn}`}
                          />
                        )}
                      </div>
                      <div className={'d-flex align-items-center'}>
                        {!hideSkip && (
                          <CustomButton
                            disabled={isLoading}
                            type={'button'}
                            text={'Skip for Now'}
                            className={styles.whiteBtn}
                            onClick={handleSkip}
                          />
                        )}
                        <CustomButton
                          loading={isLoading}
                          type={'submit'}
                          text={'Continue'}
                          icon={<RightLongArrowIcon size={14} />}
                          iconSide={'right'}
                        />
                      </div>
                    </>
                  )
                  }
                </div>
              </Form>
            )}
          </Formik>
        </CustomLayout>
      </div>
    </div>
  );
}

export default GettingStart;
