import React from 'react';

interface Props {
  size?: number
  color?: string
}

const GrayDotIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      width={size ?? 18}
      height={size ?? 19}
      fill="none"

    >
      <rect y={0.5} width={18} height={18} rx={9} fill="#EDEDED" />
      <rect
        width={10}
        height={10}
        rx={5}
        transform="matrix(-1 0 0 1 14 4.5)"
        fill="#A6A6A6"
      />
    </svg>
  )
}

export default GrayDotIcon
