import React, { useContext } from 'react'
import { ReactNode, useEffect, useState } from 'react'
import TopNavigation from '../topNavigation/topNavigation'
import styles from '../gettingStart/GettingStart.module.scss'
import GrayDotIcon from '../../icons/grayDot.icon'
import GetStartIcon from '../../icons/getStart.icon'
import UploadIcon from '../../icons/upload.icon'
import QualificationIcon from '../../icons/qualification.icon'
import ExperienceIcon from '../../icons/experience.icon'
import FinishIcon from '../../icons/finish.icon'
import colors from '../../styles/variables.module.scss'
import ProgressBox from '../progressBox/progressBox'
import RightArrowIcon from '../../icons/rightArrow.icon'
import HireForIcon from '../../icons/hireFor.icon'
import OrgDetailsIcon from '../../icons/orgDetails.icon'
import BagIcon from '../../icons/bag.icon'
import { UserContext } from '../../context'

type layOutProps = {
  title?: string
  subTitle?: string
  children: ReactNode
  pageName: string
  hideGoBack?: boolean
  progressValue: number
  organization?: boolean
  showSignUpProcess?: boolean
}

function CustomLayout({
  title,
  subTitle,
  children,
  pageName,
  progressValue,
  organization,
  showSignUpProcess = true,
}: layOutProps) {
  const [content] = useState(pageName)
  const userData = useContext(UserContext)

  const [name, setName] = useState('')

  useEffect(() => {
    setName(userData.userData?.full_name || 'User')
  }, [userData.userData?.full_name])

  const leftMenu = [
    {
      value: 'gettingStart',
      text: 'Getting Started',
      icon: (
        <GetStartIcon
          color={
            pageName === 'gettingStart'
              ? colors.primaryColor
              : content === 'resumeImport'
                ? colors.successColor
                : colors.textLightColor
          }
        />
      ),
    },
    {
      value: 'resumeImport',
      text: 'Resume Import',
      icon: (
        <UploadIcon
          color={
            pageName === 'resumeImport'
              ? colors.primaryColor
              : colors.textLightColor
          }
        />
      ),
    },
    {
      value: 'qualification',
      text: 'Qualifications',
      icon: (
        <QualificationIcon
          color={
            pageName === 'qualification'
              ? colors.primaryColor
              : colors.textLightColor
          }
        />
      ),
    },
    {
      value: 'experience',
      text: 'Experience',
      icon: (
        <ExperienceIcon
          color={
            pageName === 'experience'
              ? colors.primaryColor
              : colors.textLightColor
          }
        />
      ),
    },
    {
      value: 'finishing',
      text: 'Finish',
      icon: (
        <FinishIcon
          color={
            pageName === 'finishing'
              ? colors.primaryColor
              : colors.textLightColor
          }
        />
      ),
    },
  ]
  const orgLeftMenu = [
    {
      value: 'hiringFor',
      text: 'Hiring For',
      time:'1 min',
      icon: (
        <HireForIcon
          color={
            pageName === 'hiringFor'
              ? colors.primaryColor
              : colors.textLightColor
          }
        />
      ),
    },
    {
      value: 'organisationDetails',
      text: 'Organisation Details',
      time:'5 mins',
      icon: (
        <OrgDetailsIcon
          color={
            pageName === 'organisationDetails'
              ? colors.primaryColor
              : colors.textLightColor
          }
        />
      ),
    },
    {
      value: 'roleDetails',
      text: 'Role Details',
      time:'20 mins',
      icon: (
        <BagIcon
          color={
            pageName === 'roleDetails'
              ? colors.primaryColor
              : colors.textLightColor
          }
        />
      ),
    },
  ]
  return (
    <div>
      <TopNavigation organizationLogo={organization} />
      <div className={styles.content}>
        {showSignUpProcess && (
          <div className={styles.leftSide}>
            <h1 className={`text-bolder mt-3 mt-lg-0 mb-4 ${styles.mobHeader}`}>
              Hey <span className={'primary-color-text ms-1'}>{name}</span>,
              Welcome to{' '}
              <span className={'secondary-color-text ms-1'}>BFinMatched</span>{' '}
            </h1>
            <ProgressBox value={progressValue} organization={organization} />
            {organization ? (
              <div className={styles.mobileMenu}>
                {orgLeftMenu.map((text, index) => (
                  <div key={index} className={styles.orgLeftSection}>
                    <div className={styles.segmentItem}>
                      <div className={`me-3 ${styles.dotIcon}`}>
                        <GrayDotIcon />
                      </div>
                      <div
                        key={index}
                        className={
                          pageName === text.value
                            ? styles.currentTab
                            : styles.leftMenuBox
                        }
                      >
                        <div
                          className={
                            'd-flex justify-content-between align-items-center p-5 w-100'
                          }
                        >
                          {text.icon}
                          <div className={'w-75'}>
                            <h6
                              className={`text-bold ${
                                pageName === text.value
                                  ? styles.currentTabText
                                  : 'gray-color-text'
                              }`}
                            >
                              {text.text}
                            </h6>
                            <h6
                              className={`text-normal ${
                                pageName === text.value
                                  ? styles.currentTabText
                                  : 'gray-color-text'
                              }`}
                            >
                              {text.time}
                            </h6>
                          </div>
                          <div
                            style={
                              pageName === text.value
                                ? { visibility: 'visible' }
                                : { visibility: 'hidden' }
                            }
                          >
                            <RightArrowIcon color={colors.primaryColor} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.mobileStepper}>
                      <div className={styles.stepIcon}>{text.icon}</div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.mobileMenu}>
                {leftMenu.map((text, index) => (
                  <div key={index} className={styles.orgLeftSection}>
                    <div className={styles.segmentItem}>
                      <div className={`me-3 ${styles.dotIcon}`}>
                        <GrayDotIcon />
                      </div>
                      <div
                        key={index}
                        className={
                          pageName === text.value
                            ? styles.currentTab
                            : styles.leftMenuBox
                        }
                      >
                        <div
                          className={`d-flex justify-content-between align-items-center p-5 w-100`}
                        >
                          {text.icon}
                          <div className={'w-75 ms-4'}>
                            <h6
                              className={`text-bold ${
                                pageName === text.value
                                  ? styles.currentTabText
                                  : 'gray-color-text'
                              }`}
                            >
                              {text.text}
                            </h6>
                            <h6
                              className={`text-normal ${
                                pageName === text.value
                                  ? styles.currentTabText
                                  : 'gray-color-text'
                              }`}
                            >
                              1 min
                            </h6>
                          </div>
                          <div
                            style={
                              pageName === text.value
                                ? { visibility: 'visible' }
                                : { visibility: 'hidden' }
                            }
                          >
                            <RightArrowIcon color={colors.primaryColor} />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={styles.mobileStepper}>
                      <div className={styles.stepIcon}>{text.icon}</div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        )}

        <div className={styles.rightSide}>
          <h1 className={`text-bolder mt-3 mt-lg-0 ${styles.webHeader}`}>
            Hey <span className={'primary-color-text ms-3'}>{name}</span>,
            Welcome to{' '}
            <span className={'secondary-color-text ms-3'}>BFinMatched</span>{' '}
          </h1>
          <h6 className={'mt-5 text-bold text-almostBlack'}>{title}</h6>
          <h3
            className={`mb-5 text-almostBlack text-bolder mt-3 ${styles.borderClass}`}
          >
            {subTitle}
          </h3>
          {children}
        </div>
      </div>
    </div>
  )
}

export default CustomLayout
