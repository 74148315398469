import React, { useEffect, useMemo, useState } from 'react'
import styles from './Profile.module.scss'
import EditIcon from '../../icons/edit.icon'
import CustomButton from '../shared/CustomButton/customButton'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import LoadingService from '../../services/loading/loading.service'
import { CandidateService } from '../../services/candidate/candidate.service'
import PlusIcon from '../../icons/plus.icon'
import CustomModal from '../shared/customModal/customModal'
import AddNewKeySkillModal from './newSkillModal'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import SendIcon from '../../icons/send.icon'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import BlackStarIcon from "../../icons/blackStar.icon";

export type KeySkillsProfileProps = {
  pageIsLoading: boolean;
  updateFlag: boolean;
};

const KeySkillProfile = ({ pageIsLoading, updateFlag }: KeySkillsProfileProps) => {
  const [openSkillModal, setOpenSkillModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [localUpdateFlag, setLocalUpdateFlag] = useState(false);
  const [keySkills, setKeySkills] = useState<string[]>([]);
  const [currentSkillIndex, setCurrentSkillIndex] = useState<number | null>(null);
  const [lastUpdatedAt, setLastUpdatedAt] = useState('');
  const [lastUpdatedBy, setLastUpdatedBy] = useState('');
  const [canRegenerate, setCanRegenerate] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModel, setErrorModel] = useState(false)
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  useEffect(() => {
    loadingService.await(candidateService.getSummary()).then((res) => {
      if (res !== null && res.status === 'success') {
        setKeySkills(res.result.key_skills_summary ? res.result.key_skills_summary.split('.').map((s: string) => s.trim()).filter((s: string) => s !== '') : []);
        setLastUpdatedAt(res.result.key_skills_summary_last_updated_at);
        setLastUpdatedBy(res.result.key_skills_summary_last_updated_by);
        setCanRegenerate(res.result.can_regenerate_key_skills_summary);
      }
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [candidateService, loadingService, updateFlag, localUpdateFlag]);

  // eslint-disable-next-line @typescript-eslint/no-shadow
  async function saveKeySkills(keySkills: string[]) {
    const res = await loadingService.await(candidateService.updateSummary({ key_skills_summary: keySkills.join('. ') }));
    if (res !== null && res.status === 'success') {
      setLocalUpdateFlag(!localUpdateFlag);
    } else {
      setErrorMessage('An error occurred while saving the key skills.');
    }
  }

  async function onModalSubmit(newSkill: string): Promise<void> {
    if (currentSkillIndex !== null) {
      await saveKeySkills(keySkills.map((s, i) => i === currentSkillIndex ? newSkill : s));
      setCurrentSkillIndex(null);
    } else {
      await saveKeySkills([...keySkills, newSkill]);
    }
  }

  async function removeKeySkill(index: number): Promise<void> {
    await saveKeySkills(keySkills.filter((_, i) => i !== index));
  }



  async function regenerateKeySkills() {
    try {
      const res = await loadingService.await(candidateService.generateKeySkillsSummary());
      if (res !== null && res.status === 'success') {
        setLocalUpdateFlag(!localUpdateFlag);
      } else {
        setErrorMessage('An error occurred while regenerating the key skills.');
      }
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }

  }

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
    <ErrorBannerModal open={errorMessage !== ''} onClose={() => setErrorMessage('')} errorMessage={errorMessage} />
      <div
        className={`d-md-flex align-items-center justify-content-between ${styles.borderBottom}`}
      >
        <div className={'d-flex align-items-center'}>
          <BlackStarIcon />
          <h4 className={'text-almostBlack ms-3'}>Key Skills Summary</h4>
        </div>
        <div className={'d-flex justify-content-between mt-3 mt-md-0'}>
          {canRegenerate && (
            <CustomButton
              disabled={isLoading || pageIsLoading}
              text={keySkills.length === 0 ? 'Generate' : 'Regenerate'}
              className={styles.addBtn}
              variant={'transparent'}
              icon={<SendIcon />}
              iconSide={'left'}
              onClick={() => regenerateKeySkills()}
            />
          )}
          <div>
            <CustomButton
              disabled={isLoading || pageIsLoading}
              text={'Add New Skill'}
              className={`ms-2 ${styles.addBtn}`}
              variant={'transparent'}
              icon={<PlusIcon />}
              iconSide={'left'}
              onClick={() => {
                setCurrentSkillIndex(null);
                setOpenSkillModal(true);
              }}
            />
          </div>
        </div>
      </div>
      {keySkills.length > 0 ? (<div>
        {keySkills.map((value, index) => (
          <div key={`key-skill-${index}`} className={`${styles.skillsCard}`}>
            <div className="d-flex justify-content-between">
              <div className={'d-flex align-items-center'}>
                <div className={styles.listDot} />
                <h6 className='mr-3 text-normal ms-3 w-100'>{value}</h6>
              </div>
              <div className="d-flex justify-content-end">
                <CustomButton
                  disabled={isLoading || pageIsLoading}
                  className={`${styles.nonOutlinedButton} ${styles.widthButton}`}
                  variant={'transparent'}
                  icon={<RecycleBinIcon />}
                  iconSide={'right'}
                  onClick={() => {
                    removeKeySkill(index);
                  }}
                />
                <CustomButton
                  disabled={isLoading || pageIsLoading}
                  className={`${styles.nonOutlinedButton} ${styles.widthButton}`}
                  variant={'transparent'}
                  icon={<EditIcon />}
                  iconSide={'right'}
                  onClick={() => {
                    setCurrentSkillIndex(index);
                    setOpenSkillModal(true);
                  }}
                />
              </div>
            </div>

          </div>
        ))}
      </div>) : (

        <p className={'text-almostBlack'}>No summary generated.</p>
      )}
      <div>
        {lastUpdatedAt && (
          <p>Last updated on {new Date(lastUpdatedAt).toLocaleDateString('en-UK', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
            {lastUpdatedBy && (
              ` by ${lastUpdatedBy}`
            )}
          .</p>
        )}
        <p>Powered by GPT-4.</p>
      </div>
      {openSkillModal && (
        <CustomModal
          open={openSkillModal}
          title={currentSkillIndex === null ? 'Add New Skill' : 'Update Skill'}
          onCloseModal={(val) => {
            setOpenSkillModal(val);
          }}
        >
          <AddNewKeySkillModal
            closeModal={() => {
              setOpenSkillModal(false);
            }}
            onSubmit={onModalSubmit}
            initialVal={currentSkillIndex !== null ? keySkills[currentSkillIndex] : ''}
          />
        </CustomModal>
      )}
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default KeySkillProfile
