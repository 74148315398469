import React, { ReactElement, SyntheticEvent, useCallback, useEffect, useState } from 'react'

import styles from './TimePicker.module.scss'
import DownArrowIcon from "../../../icons/downArrow.icon";
import UpArrowIcon from "../../../icons/upArrow.icon";
import colors from '../../../styles/variables.module.scss'

type inputFiledProps = {
  id: string
  name?: string
  filedText?: string
  loading?: boolean
  handleChange: (e: string) => void
  // buttonStatus?: string
  disabled?: boolean
  icon?: ReactElement
  defaultValue?: number
}
const TimePicker = ({ id,handleChange,defaultValue }: inputFiledProps) => {
  const [hours, setHours] = useState(defaultValue ? Math.floor(defaultValue / 60) : 0)
  const [minutes, setMinutes] = useState(defaultValue ? (defaultValue % 60) : 0)
  const [value ,setValue] = useState('')
  const [total ,setTotal] = useState(0)

  const handleTimeChange = useCallback(
    () => {
      setValue(`${hours}:${minutes}`)
      setTotal(hours * 60 + minutes)
      handleChange(`${total}`)
    },
    [hours,minutes,total,handleChange],
  )

  useEffect(()=>{
    handleTimeChange();
    // eslint-disable-next-line
  },[value, minutes, hours])

  const handleTime = (event:SyntheticEvent) => {
    event.stopPropagation()
    if (minutes === 55) {
      setMinutes(0)
      setHours(hours + 1)
    } else {
      setMinutes(minutes + 5)
    }
    setValue(`${hours}:${minutes}`)
    handleChange(`${total}`)
  }
  const handleTimeDecrease = () =>{
    if (minutes === 0 && hours === 0) {
      setMinutes(0)
      setHours(0)
    } else if ( hours > 0 && minutes === 0) {
      setMinutes(55)
      setHours(hours - 1)
    } else {
      setMinutes(minutes - 5)
    }
    setValue(`${hours}:${minutes}`)
    handleChange(`${total}` )
  }
  return (
    <>
      <div
        id={`${id}`}
        className={styles.textFiled}
      >
        <div>
        <p className={styles.title}>Time</p>
        {hours < 10 && minutes === 0 || hours < 10 && minutes === 5 ?
          <h6 className={styles.textColor}>{`0${hours}:0${minutes}`}</h6>
          :
          hours < 10 ?
            <h6 className={styles.textColor}>{`0${hours}:${minutes}`}</h6>
            : minutes === 5 || minutes === 0 ?
              <h6 className={styles.textColor}>{`${hours}:0${minutes}`}</h6>
              :
            <h6 className={styles.textColor}>{`${hours}:${minutes}`}</h6>
        }
        </div>
        <div className={styles.arrowBox}>
          <button type='button' className={`pointer ${styles.iconButton}`} onClick={handleTime}>
            <UpArrowIcon/>
          </button>
          <button  type='button' className={`pointer ${styles.iconButton}`} onClick={() => handleTimeDecrease()}>
            <DownArrowIcon color={colors.textLightColor} size={12}/>
          </button>
        </div>

      </div>
    </>
  )
}

export default TimePicker
