import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './Profile.module.scss'
import PersonalInfoIcon from "../../icons/personalInfo.icon";
import EditIcon from "../../icons/edit.icon";
import { Form, Formik } from "formik";
import CustomInputField from "../shared/customInputField/CustomInputField";
import CustomButton from "../shared/CustomButton/customButton";
import CloseIcon from '../../icons/close.icon';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingService from '../../services/loading/loading.service';
import * as Yup from 'yup';
import 'yup-phone';
import UserService from '../../services/user/user.service';
import { isEmpty } from 'lodash';
import CustomSearch from '../shared/listSearch/listSearch';
import { CandidateService } from '../../services/candidate/candidate.service';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';
import { UserContext } from '../../context'
import { Auth } from 'aws-amplify'

export type PersonalProfileProps = {
  fullName?: string;
  email?: string;
  location?: string;
  phone?: string;
  pageIsLoading?: boolean;
  onUpdate?: (values: PersonalProfileProps) => void;
};

function PersonalProfile({ onUpdate, pageIsLoading }: PersonalProfileProps) {
  const user = useContext(UserContext);
  const [editable, setEditable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [initialVal, setInitialVal] = useState<PersonalProfileProps>({
    email: '',
    fullName: '',
    location: '',
    phone: '',
  });
  const [canChangeEmail, setCanChangeEmail] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const userService: UserService = useMemo(() => {
    return new UserService();
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required('Please enter your full name.').matches(/^[a-zA-Z- ]+$/, 'Name can only contain uppercase letters, lowercase letters, hyphens, and spaces.'),
    email: Yup.string().required('Please enter your email address.').email('Please enter a valid email address.'),
    phone: Yup.string().matches(/^\+[1-9][0-9]{0,24}$/, 'Invalid phone number format.').required('Please enter your contact number.').phone('AU', false, 'Please enter a valid phone number.'),
    location: Yup.string().required('Please enter your city/suburb.'),
  });

  useEffect(() => {
    if (user.userData) {
      setInitialVal(() => ({
        fullName: user.userData?.full_name,
        email: user.userData?.email,
        phone:  user.userData?.mobile_number,
        location: user?.candidateData?.suburb ?? "",
      }));
    }

    loadingService.await(userService.isLinkedInAccount()).then((res) => {
      setCanChangeEmail(!res);
    });
  }, [candidateService, loadingService, userService, updateFlag, user]);
  function addCountryCode(phoneNumber: any) {
    // Remove any non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // Check if the number starts with '0'
    if (digitsOnly.startsWith('0')) {
      // Replace the '0' with '61' (Australian country code)
      return `+61${digitsOnly.substring(1)}`;
    } else {
      // If it doesn't start with '0', assume it already has the country code
      return phoneNumber;
    }
  }

  const handleSubmit = async (values: PersonalProfileProps) => {
    let res;
    if (values.location !== initialVal.location) {
      res = await loadingService.await(candidateService.updateCandidateData({
        suburb: values.location,
      }));
      user.setCandidateData({
        ...user.candidateData,
        suburb: values.location,
      });
      if (res === null) {
        setErrorMessage('Failed to update personal information.');
        return;
      }
    }
    if (values.phone !== initialVal.phone || values.email !== initialVal.email || values.fullName !== initialVal.fullName) {

      res = await loadingService.await(userService.updateUserData({
        full_name: values.fullName,
        email: values.email,
        mobile_number: `${values.phone}`,
      }));

      if (res === null) {
        setErrorMessage('Failed to update personal information.');
        return;
      }
      const currentUser = await Auth.currentAuthenticatedUser();
      const fullName = currentUser.attributes.name;
      const [firstName, lastName] = fullName.split(' ');

      await Auth.updateUserAttributes( currentUser, {
        email: res.email,
        name: firstName + ' ' + lastName,
        given_name: currentUser.attributes.given_name,
        family_name: lastName,
        phone_number: addCountryCode(currentUser.attributes.phone_number),
      });
    }
    user.setUserData(res);
    setUpdateFlag(!updateFlag);
    setInitialVal(values);
    if (onUpdate) {
      onUpdate(values);
    }
    setEditable(false);
  }

  return (
    <>
      <div className={`${!isLoading && loadingStyles.app_while_loading}`}/>
        <ErrorBannerModal open={errorMessage !== ''} onClose={() => setErrorMessage('')} errorMessage={errorMessage} />
        <Formik initialValues={initialVal} onSubmit={handleSubmit} validationSchema={validationSchema} enableReinitialize={true}>
          {({ values, setFieldValue, errors, resetForm }) => (
            <Form>
              <div className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}>
                <div className={'d-flex align-items-center'}>
                  <PersonalInfoIcon/>
                  <h4 className={'text-almostBlack ms-3'}>Personal Information</h4>
                </div>
                <div >
                    {!editable && <div onClick={() => {
                      if (!isLoading && !pageIsLoading) setEditable(true)
                    }}><EditIcon/></div> }
                    {editable && <div onClick={() => {
                      if (!isLoading && !pageIsLoading) {
                        resetForm();
                        setEditable(false);
                      }
                    }}><CloseIcon/></div>}
                </div>
              </div>
              <div className={'d-flex align-items-center row justify-content-between mt-5'}>
                <div className={'col-sm-6'}>
                  <h6 className={'text-normal gray-color-text'}>Full Name</h6>
                  <CustomInputField name={'fullName'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('fullName', event.target.value)}
                    disabled={!editable || isLoading || pageIsLoading} />
                </div>
                <div className={'col-sm-6'}>
                  <h6 className={'text-normal gray-color-text'}>E-mail</h6>
                  <CustomInputField name={'email'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('email', event.target.value)}
                    disabled={true || !editable || !canChangeEmail || isLoading || pageIsLoading} />
                </div>
              </div>
              <div className={'mt-3 row justify-content-between d-flex'}>
                <div className={'col-sm-6'}>
                  <h6 className={'text-normal gray-color-text'}>Suburb</h6>
                  {(!editable || isLoading || pageIsLoading) ?
                    <CustomInputField name={'location'}  disabled={true} />
                    :
                    <CustomSearch name={'location'} endPoint={value => 'location/autocomplete?' + new URLSearchParams({ input: value })} onChange={(value: string) => setFieldValue('location', value)}
                                  defaultValue={values.location} timeout={1000} minChars={1}/>
                  }
                </div>
                <div className={'col-sm-6 '}>
                  <h6 className={'text-normal gray-color-text'}>Phone</h6>
                  <CustomInputField name={'phone'} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setFieldValue('phone', event.target.value)}
                    disabled={!editable || isLoading || pageIsLoading } />
                </div>
              </div>
              {editable &&
                <div className={styles.bottomContent}>
                    <CustomButton text={'Save Changes'} loading={isLoading} type={'submit'} disabled={isLoading || pageIsLoading || !isEmpty(errors) || (JSON.stringify(values) === JSON.stringify(initialVal))} className={styles.saveBtn}/>
                </div>
              }
            </Form>
          )}
      </Formik>
    </>
  )
}

export default PersonalProfile
