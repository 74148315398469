import React, { useEffect, useRef } from 'react';
import { Urls } from '../context/Urls';
import axios from 'axios';


interface TokenRefreshProviderProps {
  children: React.ReactNode;
}

export interface ErrorResponse {
  response: {
    data: {
      statusCode: number;
    };
  };
}

const TokenRefreshProvider: React.FC<TokenRefreshProviderProps> = ({
  children,
}) => {
  const intervalId = useRef<NodeJS.Timeout>();

  function handleTokenExpiration() {
    localStorage.setItem('tokenExpirationKey', 'tokenExpirationValue');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    window.location.href = '/';
  }

  const checkTokenAndRefresh = async () => {
    if ( localStorage.getItem('login_form') !== "App") {

      const refreshToken = localStorage.getItem('refreshToken');
      const expiration = localStorage.getItem('tokenExpiration');

      if (refreshToken && expiration) {
        const expirationTime = parseInt(expiration) - 300000;

        const currentTime = new Date().getTime();

        if (currentTime >= expirationTime) {
          try {
            const response = await axios.post(
              `${Urls.APP_BASE_URL}/user/linkedIn/auth/refresh`,
              { refresh_token: refreshToken },
              {
              },
            );

            const newAccessToken = response.data.access_token;
            const newExpirationTime = response.data.expires_in;

            localStorage.setItem('token', newAccessToken);
            localStorage.setItem(
              'tokenExpiration',
              String(new Date().getTime() + newExpirationTime * 1000),
            );
          } catch (e) {
            const errorResponse = e as ErrorResponse;
            if (errorResponse.response.data.statusCode === 401) {
              localStorage.setItem(
                'errorMessageKey',
                'Invalid Session. Please log in again to regain access to your account',
              );
              handleTokenExpiration();
            }
          }
        }
      }
    }

  };

  // Function to set up the interval
  const setupTokenRefreshInterval = () => {
    // Set up an interval to periodically check the token
    const tokenRefreshInterval = 300000; // 5 minutes

    intervalId.current = setInterval(
      checkTokenAndRefresh,
      tokenRefreshInterval,
    );

    // // Clean up the interval when the component unmounts
    // return () => clearInterval(intervalId);
  };

  // Set up the token refresh logic when the component mounts
  useEffect(() => {
    (async () => {
      await checkTokenAndRefresh();
    })();
    setupTokenRefreshInterval();

    // Clean up the interval when the component unmounts
    return () => {
      clearInterval(intervalId.current);
    };
    // eslint-disable-next-line
  }, []);

  // Listen for visibility changes and refresh token if the tab becomes active
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // console.log('Tab is now active');
        (async () => {
          await checkTokenAndRefresh();
          await setupTokenRefreshInterval();
        })();
      } else {
        // console.log('Tab is now inactive');
        clearInterval(intervalId.current);
      }
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
    // eslint-disable-next-line
  }, []);

  return <>{children}</>;
};

export default TokenRefreshProvider;
