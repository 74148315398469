import React, { useEffect, useState } from "react"
import CustomModal from "../shared/customModal/customModal";
import SuccessIcon from "../../icons/success.icon";

type ErrorBannerModalProps = {
  open: boolean;
  onClose: (val: boolean) => void;
  Message?: string;
  linkTo?:boolean
};

function SuccessBannerModal({ open, onClose, Message ,linkTo }: ErrorBannerModalProps) {
  const [openModal, setOpenModal] = useState(open);

  useEffect(() => {
    setOpenModal(open);
  }, [open]);

  return (
    <CustomModal title={'Review added!'} open={openModal} onCloseModal={() => {
      setOpenModal(false)
      onClose(false)
    }}>
      <div >
        <>
          <div className={'d-flex align-items-center mt-3'}>
            <div >
              <SuccessIcon />
            </div>
            {linkTo ?
              <h5 className={'text-success ms-3 mt-2'}>Find out more about <a href={'https://betterbusinessbasics.com.au/'} className={'text-success'} target={'_blank'}
                                         rel={'noreferrer'}>BBB</a></h5>
              :
              <h5 className={'text-success ms-3 '}>{Message}</h5>
            }
          </div>
        </>
      </div>
    </CustomModal>
  )
}

export default SuccessBannerModal;
