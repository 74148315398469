import React from 'react'
import styles from './assesmentCard.module.scss'
import BagIcon from "../../icons/bag.icon";
import WalkingManIcon from "../../icons/walkingMan.icon";
import ClockIcon from "../../icons/clock.icon";
import CustomButton from "../shared/CustomButton/customButton";
import { useNavigate } from "react-router-dom";

type TopMatchesProps = {
  position?: string;
  description?: string;
  commitment?: string;
  workType?: string;
  hoursPerWeek?: string;
  taskExpertise?: string[];
  educationRequired?: string;
  softSkills?: string[];
  visaRequired?: string;
  experienceRequired?: number;
  startDate?: string;
}

function TopMatchesCard({
  position = 'Position Name',
  description = 'Position Description',
  commitment = 'Permanent Full-Time',
  workType = 'On-Site',
  hoursPerWeek = '40',
  taskExpertise = [],
  educationRequired,
  softSkills,
  visaRequired,
  experienceRequired,
  startDate,
}: TopMatchesProps) {
  const navigate = useNavigate()
  return (
    <div className={styles.topthreeCard}>
      <div>
        <h4 className={'text-bolder text-almostBlack'}>{position}</h4>
        <div className={'d-flex flex-wrap mt-3'}>
          {taskExpertise?.length > 0 &&
              <>
                {taskExpertise?.map((val,index)=>(

                  <div key={index} className={`mb-2 ${styles.payableTag}`}>
                    <h6>{val}</h6>
                  </div>
                ))}
              </>
          }
        </div>

      </div>
      <div className={'d-flex mt-5'}>
        <div className={'d-flex me-4'}>
          <BagIcon/>
          <h6 className={'ms-3 gray-color-text'}>{commitment}</h6>
        </div>
        <div className={styles.borderClass}>
          <WalkingManIcon/>
          <h6 className={'ms-3 gray-color-text'}>{workType}</h6>
        </div>
        <div className={'d-flex ms-4'}>
          <ClockIcon/>
          <h6 className={'ms-3 gray-color-text'}>{hoursPerWeek} Hrs per Week</h6>
        </div>
      </div>
      <div className={`justify-content-between ${styles.discription}`}>
        <h6 className={`gray-color-text text-normal mt-5 ${styles.widthDescription} `}><span
          className={styles.elipsisDes}>{description}</span><span className={'text-bold pointer'}
                                                                  onClick={() => navigate('/JobInner', {
                                                                    state: {
                                                                      position,
                                                                      description,
                                                                      commitment,
                                                                      workType,
                                                                      hoursPerWeek,
                                                                      taskExpertise,
                                                                      educationRequired,
                                                                      softSkills,
                                                                      visaRequired,
                                                                      experienceRequired,
                                                                      startDate,
                                                                    },
                                                                  })}>Read More</span></h6>
        <CustomButton text={'View Role'} className={`ms-0 ms-lg-2 ${styles.viewBtn}`} onClick={() => navigate('/JobInner', {
          state: {
            position,
            description,
            commitment,
            workType,
            hoursPerWeek,
            taskExpertise,
            educationRequired,
            softSkills,
            visaRequired,
            experienceRequired,
            startDate,
          },
        })}/>
      </div>

    </div>
  )
}

export default TopMatchesCard
