import React from 'react';

interface Props {
  size?: number
  color?: string
}

const SuccessIcon: React.FC<Props> = ({ size }) => {
  return (
    <svg
      width={size ?? 17}
      height={size ? size - 5 : 12}
      fill="none"
      viewBox={'0 0 17 12'}
    >
      <path
        d="m1 6 5 5L16 1"
        stroke="#00AE46"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SuccessIcon
