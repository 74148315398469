import React, { useMemo } from 'react'
import { createContext, useCallback, useEffect, useState } from "react";
import { GetUserDataResponse } from '../services/user/user.res.model'
import UserService from '../services/user/user.service'
import { CandidateService } from '../services/candidate/candidate.service'
import { RequestStatus } from '../services/http/http.model';

export interface ContextProps {
  readonly userData: GetUserDataResponse | null | undefined;
  readonly setUserData: (userData: GetUserDataResponse) => void;
  readonly candidateData: any | null | undefined;
  readonly setCandidateData: (candidateData: any) => void;
  readonly isAssessmentCompleted: boolean;
  readonly setIsAssessmentCompleted: (isAssessmentCompleted: boolean) => void;
}

export const UserContext = createContext<ContextProps>({
  userData: null,
  setUserData: () => null,
  candidateData: null,
  setCandidateData: () => null,
  isAssessmentCompleted: false,
  setIsAssessmentCompleted: () => null,
});

const UserProvider = ({ children }: any) => {
  const [userData, setUserDataValue] = useState<GetUserDataResponse>();
  const [candidateData, setCandidateDataValue] = useState<any>();
  const [isAssessmentCompleted, setAssessmentCompletedValue] = useState<boolean>(false);

  const setUserData = (user:any) => {
    setUserDataValue(user);
  };

  const setCandidateData = (candidate:any) => {
    setCandidateDataValue(candidate);
  };

  const setIsAssessmentCompleted = (value: boolean) => {
    setAssessmentCompletedValue(value);
  };

  const userService: UserService = useMemo(() => {
    return new UserService();
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  const createContextData = useCallback(async () => {
    try {
      const userRole:any = await userService.getUserRole();
      // const user = await Auth.currentAuthenticatedUser()
      if (userRole.role) {
        const userProfileRes = await userService.getUserDataT();
        if (userRole.role === 'user') {
          const assessmentResults = await candidateService.getAssessmentResults();
          setAssessmentCompletedValue(assessmentResults !== null && assessmentResults.status === RequestStatus.Success);
        }
        if (userRole.role === 'user') {
          try {
            const candidateDataRes = await candidateService.getCandidateDataT();

            if (candidateDataRes && Object.keys(candidateDataRes).length !== 0) {
              setCandidateData(candidateDataRes);
            } else {
              setCandidateData({
                "profile_pages": "{}",
              });
            }
          } catch (e) {
            setCandidateData({
              "profile_pages": "{}",
            });
          }

        }

        if (userProfileRes) {
          setUserData({
            ...userProfileRes,
            email:userProfileRes.email,
          });
        }

      }
    } catch (err) {
      // setUserData(undefined);
    }

  }, [userService, candidateService]);

  useEffect(() => {
    createContextData().then();
    // eslint-disable-next-line
  }, []);

  return <UserContext.Provider value={{ userData, setUserData, candidateData, setCandidateData, isAssessmentCompleted, setIsAssessmentCompleted }}>{children}</UserContext.Provider>;
};

export default UserProvider;
