import React from 'react';

interface Props {
  size?: number
  color?: string
}

const EclipseIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 128}
      height={size ?? 128}
      fill="none"
      viewBox={'0 0 128 128'}
    >
      <circle
        opacity={0.2}
        cx={64}
        cy={64}
        r={63.5}
        fill="#F0F8FA"
        stroke={color ?? "#49B0BD"}
      />
      <circle
        opacity={0.4}
        cx={64}
        cy={64}
        r={50.5}
        fill="#F0F8FA"
        stroke="#49B0BD"
      />
      <rect x={28} y={29.5} width={71} height={69} rx={34.5} fill="#F0F8FA" />
      <path
        d="m70.833 47.5 7.333 7.333m0 0-7.333 7.334m7.333-7.334H59.833m-3.667 11-7.333 7.334m0 0 7.333 7.333m-7.333-7.333h16.5"
        stroke="#49B0BD"
        strokeWidth={4}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect x={28} y={29.5} width={71} height={69} rx={34.5} stroke="#49B0BD" />
    </svg>
  )
}

export default EclipseIcon
