import React from 'react';

interface Props {
  size?: number
  color?: string
}

const rightLongArrow: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 16}
      height={size ? size - 2 : 14}
      fill="none"
      viewBox={'0 0 16 14'}
    >
      <path
        d="M1 7h14m0 0-6 6m6-6L9 1"
        stroke={color ?? "#fff"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default rightLongArrow
