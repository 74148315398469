import React, { useMemo } from 'react'
import { createContext, useCallback, useEffect, useState } from "react";
import DataService from '../services/data/data.service'
import { Auth } from 'aws-amplify'

interface ContextProps {
  readonly masterData: any | null | undefined;
  readonly setMasterData: (masterData: any) => void;
  readonly isError: any | null | undefined;

}

export const MasterDataContext = createContext<ContextProps>({
  masterData: null,
  setMasterData: () => null,
  isError: false,

});

const MasterDataProvider = ({ children }: any) => {
  const [masterData, setMasterDataValue] = useState<any>();
  const [isError, setIsError] = useState<boolean>(false);

  const setMasterData = (data:any) => {
    localStorage.setItem('masterData', JSON.stringify(data) );
    setMasterDataValue(data);
  }

  const dataService: DataService = useMemo(() => {
    return new DataService();
  }, []);



  const createContextData = useCallback(async () => {

    try {
      if (await Auth.currentAuthenticatedUser()) {
        const storedData = localStorage.getItem('masterData');
        if (storedData === "undefined") {
          try {
            const allDataSet = await dataService.getAllList();
            setIsError(false)
            if (allDataSet) {
              setMasterDataValue(allDataSet);
              localStorage.setItem('masterData', JSON.stringify(allDataSet) );
            }
          } catch (error) {
            setIsError(true)
          }

        }
      }

    } catch (err) {
      setIsError(true)
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    createContextData().then();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const storedData:any = localStorage.getItem('masterData');
    if (storedData !== null && storedData !== "undefined") {
      setMasterDataValue(JSON.parse(storedData));
    } else {
      createContextData().then();
    }
  }, [createContextData, dataService]);

  return <MasterDataContext.Provider value={{ masterData, setMasterData, isError }}>{children}</MasterDataContext.Provider>;
};

export default MasterDataProvider;
