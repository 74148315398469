import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './Profile.module.scss'
import EditIcon from "../../icons/edit.icon";
import CustomButton from "../shared/CustomButton/customButton";
import CalenderIcon from "../../icons/calender.icon";
import RecycleBinIcon from "../../icons/recycleBinIcon";
import CustomModal from "../shared/customModal/customModal";
import PlusIcon from "../../icons/plus.icon";
import CertificateIcon from "../../icons/certificate.icon";
import AddAnotherModal from "../qualification/addAnotherModal";
import CertificateProfileIcon from "../../icons/certificateProfile";
import LoadingService from '../../services/loading/loading.service';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import { CandidateService } from '../../services/candidate/candidate.service';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';
import { MasterDataContext } from '../../context/masterData'
import dayjs from 'dayjs';

export type Certification = {
  id: string;
  certification: string;
  organization: string;
  reference_id: string;
  issue_date: Date;
};

export type CertificateProfileProps = {
  pageIsLoading: boolean;
  onUpdate: () => void;
};

function CertificateProfile({ pageIsLoading, onUpdate }: CertificateProfileProps) {
  const masterData = useContext(MasterDataContext);
  const [modalOpen, setModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [certifications, setCertifications] = useState<Certification[]>([]);
  const [currentCertification, setCurrentCertification] = useState<Certification | null>(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [certificateLevels, setCertificationLevels] = useState<any[]>([]);
  const [errorModel, setErrorModel] = useState(false)
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  useEffect(() => {
    loadingService.await(candidateService.getCertifications()).then(data => {
      if (data !== null) {
        setCertifications(
          data.result.map((q: any) => ({
            ...q,
            issue_date: new Date(q.issue_date),
          })),
        );
      }
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [candidateService, loadingService, updateFlag]);

  useEffect(() => {
    if (masterData?.masterData) {
      setCertificationLevels(masterData.masterData.filter((fd:any)=> fd.category === 'licences_and_certifications').sort((a: any, b: any) => a.order - b.order));
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }

  }, [masterData]);

  function handleUpdate() {
    onUpdate();
    setUpdateFlag(!updateFlag);
  }

  function editCertification(id: string) {
    setCurrentCertification(certifications.filter(q => q.id === id)[0]);
    setModalOpen(true);
  }

  async function removeCertification(id: string) {
    const res = await loadingService.await(candidateService.removeCertifications([{ id }]));
    if (res !== null && res.status === 'success') {
      handleUpdate();
    } else {
      setErrorMessage('Unable to delete certification');
    }
  }

  async function onModalSubmit(data: Partial<Certification>) {
    let res;
    if (currentCertification) {
      data.id = currentCertification.id;
      res = await loadingService.await(candidateService.updateCertification(data));
    } else {
      delete data.id;
      res = await loadingService.await(candidateService.addCertifications([data]));
    }
    if (res !== null && res.status === 'success') {
      handleUpdate();
    } else {
      setErrorMessage('Unable to save certification');
    }
  }

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      <ErrorBannerModal open={errorMessage !== ''} onClose={() => {
        setErrorMessage('');
      }} errorMessage={errorMessage} />
      <div className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}>
        <div className={'d-flex align-items-center'}>
          <CertificateProfileIcon/>
          <h4 className={'text-almostBlack ms-3'}>Certificates & Licences</h4>
        </div>
        <CustomButton disabled={isLoading || pageIsLoading} text={'Add Another'} className={styles.addBtn} onClick={ () => { setCurrentCertification(null); setModalOpen(true) }} variant={'transparent'} icon={<PlusIcon/>} iconSide={'left'}/>
      </div>
      {certifications.map((cert: Certification, index: number) => (
        <div className={styles.educationCard} key={index}>
          <div className={'d-flex align-items-center'}>
            <CertificateIcon/>
            <div className={'ms-4'}>
              <h6 className={'text-bold gray-color-text'}>{certificateLevels?.filter((fd:any)=>fd.id === cert.certification)[0]?.value ?? cert.certification}</h6>
              <h6 className={'text-normal text font-size-semi gray-color-text'}>{cert.organization}</h6>
              <div className={'d-flex mt-1'}>
                {new Date(cert.issue_date).getFullYear() === 1970 ? <></> : <CalenderIcon/>}
              <p className={`ms-2 gray-color-text text-normal ${styles.borderRight}`}>
                {new Date(cert.issue_date).getFullYear() === 1970 ? '' : `${dayjs(cert.issue_date).format('MMM YYYY')}`}
              </p>
              <p className={'gray-color-text ms-0 ms-lg-4 '}>Ref/Member No -<span className={'ms-2 text-normal'}>{cert.reference_id}</span></p>
              </div>
            </div>
          </div>
          <div className={'d-flex'}>
            <div onClick={() => { if (!isLoading && !pageIsLoading) removeCertification(cert.id) }}>
            <RecycleBinIcon/>
            </div>
            <div className={'ms-5'} onClick={() => { if (!isLoading && !pageIsLoading) editCertification(cert.id) }}>
              <EditIcon/>
            </div>
          </div>
        </div>
      ))}
      {modalOpen &&
        <CustomModal open={modalOpen} onCloseModal={(val) => setModalOpen(val)} title={currentCertification != null ? 'Edit Certificate' : 'Add Certificate'}>
            <AddAnotherModal onSubmit={onModalSubmit} certification={currentCertification} closeModal={() => setModalOpen(false)}/>
        </CustomModal>
      }
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default CertificateProfile
