import HttpService from "../http/http.service";
import UserService from "../user/user.service";
import { NotificationSearchRequest } from "./notification.req.model";
import { GetNotificationsResponse, ModifyNotificationResponse } from "./notification.res.model";

export default class NotificationService {
  constructor(
    private readonly httpService: HttpService = new HttpService(),
    private readonly userService: UserService = new UserService(),
  ) {}

  // Get the user's notifications
  public async getNotifications(): Promise<GetNotificationsResponse> {
    const res = await this.httpService.sendGetRequest<GetNotificationsResponse | null>('notification/get', await this.userService.getUserToken());
    return res;
  }

  // Remove a notification
  public async removeNotification(params: NotificationSearchRequest): Promise<ModifyNotificationResponse> {
    const res = await this.httpService.sendGetRequest<ModifyNotificationResponse | null>('notification/remove?' + new URLSearchParams(params), await this.userService.getUserToken());
    return res;
  }
}
