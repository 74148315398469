import React, { ReactNode, useEffect, useState } from 'react'

import styles from './CustomRangeSlider.module.scss'

type arrayType = {
  text: string
  value: string
  icon?: ReactNode
}

type CustomRangeProps = {
  steps?: arrayType[]
  onClickStep?: (step: string) => void
  labelUp?: boolean
  energy?: boolean
  min: number
  max: number
  step: number
  icons?: arrayType[]
  defaultValue?: string
  name:string
}

function CustomRangeSlider({ steps, min, max, step, onClickStep, defaultValue, energy,name }: CustomRangeProps) {
  const [value, setValue] = useState(defaultValue)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    onClickStep?.(event.target.value)
  }
  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    defaultValue && setValue(defaultValue)
  }, [defaultValue])

  return (
    <>
      <div className={`d-flex justify-content-around mb-4 `}>
        {steps?.map((index, icons) => (
          <div key={icons}>{index.icon}</div>
        ))}
      </div>
      <div className={energy ? styles.energyMain : styles.main}>
        <input
          type="range"
          min={min.toString()}
          max={max?.toString()}
          step={step?.toString()}
          name={name}
          value={value}
          className={styles.inputRange}
          onChange={(e) => handleChange(e)}
        />

        <div className={styles.range} style={{ width: `${(100 / (max - min)) * (Number(value) - min)}%` }}/>
        {value !== min?.toString() && value !== max.toString() &&
            <h6 style={{ position: "absolute", left: `${(100 / (max - min)) * (Number(value) - min) - 3}%`, top: '-30px' }} className={'gray-color-text'}>{value}<span>Hrs</span></h6>
        }
        <div className={styles.step}>
         <h6 className={'gray-color-text'}>{min}<span className={'ms-2 text-normal'}>Hrs</span></h6>
          <h6 className={'gray-color-text'}>{max}<span className={'ms-2 text-normal'}>Hrs</span></h6>
        </div>
      </div>
    </>
  )
}

export default CustomRangeSlider
