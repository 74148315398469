import React, { useContext, useEffect, useMemo, useState } from 'react'

import styles from './../gettingStart/GettingStart.module.scss'

import { ErrorMessage, FieldArray, FieldArrayRenderProps, Form, Formik } from "formik";
import CustomImageUpload from "../shared/CustomImageUpload/customImageUpload";
import ResumeIcon from "../../icons/resume.icon";
import FileUpload from "../shared/FileUpload/fileUpload";
import CustomLayout from "../layout/commonLayout";
import CustomButton from '../shared/CustomButton/customButton';
import LeftArrow from '../../icons/leftArrow.icon';
import RightLongArrowIcon from '../../icons/rightLongArrow.icon';
import SaveIcon from '../../icons/save.icon';
import { useNavigate } from 'react-router';
import * as Yup from 'yup';
import FileService from '../../services/file/file.service';
import { UploadedFile } from '../../services/file/file.model';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import UserService from '../../services/user/user.service';
import LoadingService from '../../services/loading/loading.service';
import CustomDropdown, { DropdownDataList } from '../shared/CustomDropdown/customDropdown';
import RecycleBinIcon from "../../icons/recycleBinIcon";
import CustomSearch from '../shared/listSearch/listSearch';
import { Auth } from 'aws-amplify';
import { CandidateService } from '../../services/candidate/candidate.service';
import { useLocation } from 'react-router-dom';
import CustomInputField from "../shared/customInputField/CustomInputField";
import PlusIcon from "../../icons/plus.icon";
import CloseIcon from '../../icons/close.icon';
import { AnyObject } from 'yup/lib/types';

type Referee = {
  id?: string;
  name: string;
  email: string;
  phone: string;
  relationship: string;
  organization: string;
};
import { UserContext } from '../../context'
import { MasterDataContext } from '../../context/masterData'
import { ScrollToFieldError } from "../../scrollToFieldError/scrollToFieldError";
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type FinishingPageProps = {
  profile_image?: UploadedFile;
  location?: string;
  resume?: UploadedFile;
  cover_letter?: UploadedFile;
  right_to_work?: string;
  visa?: string;
  how_did_you_hear_about_us?: string;
  referees?: Referee[];
  refereeName?: string;
  otherField?: string;
};

type YupRefereeContext = Yup.TestContext<AnyObject> & {
  from: [{ value: Referee }, { value: FinishingPageProps }]
};

enum RightToWork {
  Citizen = 'Australian Citizen',
  PR = 'Permanent Resident',
  Visa = 'Visa Holder',
}

function FinishingPage() {
  const userData = useContext(UserContext);
  const masterData = useContext(MasterDataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [profileImageError, setProfileImageError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [initialVal, setInitialVal] = useState<FinishingPageProps>({});
  const [howDidYouHearAboutUsOptions, setHowDidYouHearAboutUsOptions] = useState<any[]>([]);
  const [visaTypes, setVisaTypes] = useState<any[]>([]);
  const [hideSkip, setHideSkip] = useState(false);
  const [hideSaveAndExit, setHideSaveAndExit] = useState(false);
  const [relationshipOptions, setRelationshipOptions] = useState<any[]>([]);
  const [refereesToRemove, setRefereesToRemove] = useState<string[]>([]);
  const [profileImageTouched, setProfileImageTouched] = useState(false);
  const [resumeTouched, setResumeTouched] = useState(false);
  const [coverLetterTouched, setCoverLetterTouched] = useState(false);
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const fileService: FileService = useMemo(() => {
    return new FileService();
  }, []);
  const userService: UserService = useMemo(() => {
    return new UserService();
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);
  useEffect(() => {
    //scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  useEffect(() => {
    if (location.state !== null) {
      if (location.state.hideSkip) {
        setHideSkip(location.state.hideSkip);
      }
      if (location.state.hideSaveAndExit) {
        setHideSaveAndExit(location.state.hideSaveAndExit);
      }
    }
  }, [location.state]);

  useEffect(() => {
    loadingService.await(candidateService.updateLastPage('finish'));
    async function fetchData() {
      const candidateData:any = await candidateService.getCandidateDataT();
      const referees = await candidateService.getReferees();
      let cover_letter_url:any;
      let resume_url:any

      if (candidateData.resume_key) {
        const resume_key  = candidateData.resume_key?.split("/");
        resume_url = {
          name: resume_key[2],
          contents: '', type: 'url',
        }
      }
      if (candidateData.cover_letter_key) {
        const cover_letter_key  = candidateData.cover_letter_key?.split("/");
        cover_letter_url = {
          name: cover_letter_key[2],
          contents: '', type: 'url',
        }
      }
      let profile_image = undefined
      try {
        profile_image =  await fileService.readBackendImage(await userService.getProfileImage()) ?? undefined
      } catch (error: any) {
        setErrorModel(true)
        setErrorMessage(error.message)
      }


      setInitialVal({
        profile_image: profile_image,
        resume:  resume_url ?? undefined,
        cover_letter: cover_letter_url ?? undefined,
        right_to_work: candidateData?.right_to_work ?? "",
        location: candidateData?.suburb ?? "",
        how_did_you_hear_about_us: candidateData?.how_did_you_hear_about_us ?? "",
        visa: candidateData?.visa ?? "",
        referees: referees?.result ?? [],
        refereeName: candidateData?.referral ?? '',
      });
    }
    loadingService.await(fetchData()).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
    loadingService.await(userService.isLoggedIn()).then((res) => {
      if (!res) {
        navigate('/signIn?type=candidateSignIn');
      }
    });
    if (masterData?.masterData) {
      setHowDidYouHearAboutUsOptions( masterData.masterData.filter((fd:any)=> fd.category === 'how_did_you_hear_about_us').sort((a: any, b: any) => a.order - b.order));
      setVisaTypes(masterData.masterData.filter((fd:any)=> fd.category === 'visa_type').sort((a: any, b: any) => a.order - b.order));
      setRelationshipOptions( masterData.masterData.filter((fd:any)=> fd.category === 'referee_relationship').sort((a: any, b: any) => a.order - b.order));
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }

  }, [navigate, candidateService, fileService, userService, loadingService, masterData, userData]);

  const validationSchema = Yup.object().shape({
    location: Yup.string().required('Please enter your city or suburb.'),
    right_to_work: Yup.string().required('Please enter your citizenship status.'),
    how_did_you_hear_about_us: Yup.string().required('Please let us know how you heard about us.'),
    visa: Yup.string().when('right_to_work', {
      is: (right_to_work: string) => right_to_work === RightToWork.Visa,
      then: Yup.string().trim().min(1, 'Please enter your visa type.').required('Please enter your visa type.'),
    }),
    referees: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required('Please enter referee name.').test('unique', 'Please enter a unique name.', function (value) {
          const referees = (this as YupRefereeContext).from[1].value.referees ?? [];
          return !(referees.filter((referee: Referee) => referee.name === value).length > 1);
        }),
        email: Yup.string().required('Please enter referee email.').email('Please enter a valid email.').test('unique', 'Please enter a unique email.', function (value) {
          const referees = (this as YupRefereeContext).from[1].value.referees ?? [];
          return !(referees.filter((referee: Referee) => referee.email === value).length > 1);
        }),
        phone: Yup.string().required('Please enter referee phone number.').phone('AU', false, 'Please enter a valid phone number.').test('unique', 'Please enter a unique phone number.', function (value) {
          const referees = (this as YupRefereeContext).from[1].value.referees ?? [];
          return !(referees.filter((referee: Referee) => referee.phone === value).length > 1);
        }),
        relationship: Yup.string().required('Please select referee relationship.'),
        organization: Yup.string().required('Please enter the referee organisation.'),

      }),
    ),
    refereeName: Yup.string().when('how_did_you_hear_about_us', {
      is: (value: any) => value === '9f2f77a6-1cb0-4fee-a478-253f7fc2b763',
      then: Yup.string().required('Please enter a name'),
      otherwise: Yup.string().when('how_did_you_hear_about_us', {
        is: '6a3d259a-7839-4710-b205-0cc669b28236',
        then: Yup.string().required('Please enter a name'),
        otherwise: Yup.string(),
      }),
    }),
  });

  async function updateProfileImage(image: UploadedFile) {
    if (image.original?.size && image.original?.size > 10 * 1024 * 1024) {
      setProfileImageError('Image size must be less than 10MB');
      return;
    }
    setProfileImageError('');
    setInitialVal((prev) => ({ ...prev, profile_image: image }));
    setProfileImageTouched(true);
  }

  function handleBack() {
    navigate('/experience', { state: { hideSkip, hideSaveAndExit } });
  }

  async function handleSave(values: FinishingPageProps): Promise<boolean> {
    if (loadingService.isLoading()) {
      return false;
    }
    if (values.profile_image && profileImageTouched) {
      const res = await loadingService.await(userService.updateProfileImage(values.profile_image));
      if (res === null) {
        return false;
      }
    }
    if (values.resume && resumeTouched) {
      const res = await loadingService.await(candidateService.updateResume(values.resume));
      if (res === null || res.status !== 'success') {
        return false;
      }
    }
    if (values.cover_letter && coverLetterTouched) {
      const res = await loadingService.await(candidateService.updateCoverLetter(values.cover_letter));
      if (res === null || res.status !== 'success') {
        return false;
      }
    }
    if (values.right_to_work || values.location || values.how_did_you_hear_about_us || values.visa) {
      const res = await loadingService.await(candidateService.updateCandidateData({
        ...(values.right_to_work !== '' && { right_to_work: values.right_to_work }),
        suburb: values.location,
        how_did_you_hear_about_us: values.how_did_you_hear_about_us,
        visa: values.visa,
        referral :values.refereeName,
      }));
      if (res === null || res.status !== 'success') {
        return false;
      }
    }
    if (refereesToRemove.length > 0) {
      const res = await loadingService.await(candidateService.removeReferee(refereesToRemove.map((id) => ({ id }))));
      if (res === null || res.status !== 'success') {
        return false;
      }
    }
    if (values.referees && values.referees.length > 0) {
      const refereesToUpdate = values.referees.filter((ref) => ref.id !== undefined);
      if (refereesToUpdate.length > 0) {
        for (const ref of refereesToUpdate) {
          const res = await loadingService.await(candidateService.updateReferee(ref));
          if (res === null || res.status !== 'success') {
            return false;
          }
        }
      }
      const refereesToAdd = values.referees.filter((ref) => ref.id === undefined);
      if (refereesToAdd.length > 0) {
        for (const ref of refereesToAdd) {
          const res = await loadingService.await(candidateService.insertReferee(ref));
          if (res === null || res.status !== 'success') {
            return false;
          }
        }
      }
    }
    return true;
  }

  function handleAddReferee(values: FinishingPageProps, setFieldValue: <type>(key: string, value: type) => void) {
    if ((values.referees ?? []).length < 3) {
      setFieldValue<Referee[]>('referees', [...(values.referees ?? []), { name: '', email: '', phone: '', relationship: '', organization: '' }]);
    }
  }

  async function handleSaveAndExit(values: FinishingPageProps) {
    if (await handleSave(values)) {
      localStorage.removeItem('masterData');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('login_form');
      await loadingService.await(Auth.signOut());
      navigate('/signIn?type=candidateSignIn');
    }
  }

  async function handleSkip() {
    if (Object.keys(userData.candidateData).length !== 0) {
      const cData = (userData.candidateData);
      let paged: any
      try {
        paged = JSON.parse(cData.profile_pages);
      } catch (e) {
        paged = cData.profile_pages;
      }
      const updatePage = JSON.stringify({ ...paged, ...{ finish: 'skip' } })
      const updatePages = {
        ...cData,
        profile_pages: updatePage,
      }

      userData.setCandidateData(updatePages);
    }
    await loadingService.await(candidateService.updateProfilePagesWithAllFunctions("finish", "skip", userData.candidateData));
    if (localStorage.getItem('login_form') === 'LinkedIn') {
      navigate('/dashboard');
    } else {
      navigate('/emailVerification');
    }

  }

  async function handleSubmit(values: FinishingPageProps) {
    if (await handleSave(values)) {
      if (Object.keys(userData.candidateData).length !== 0) {
        const cData = (userData.candidateData);
        let paged: any
        try {
          paged = JSON.parse(cData.profile_pages);
        } catch (e) {
          paged = cData.profile_pages;
        }
        const updatePage = JSON.stringify({ ...paged,... {  finish: 'complete' } })
        const updatePages = {
          ...cData,

          profile_pages:updatePage,
        }
        userData.setCandidateData({
          ...updatePages,
          suburb: values.location,
          how_did_you_hear_about_us: values.how_did_you_hear_about_us,
          visa: values.visa,
          referral :values.refereeName,
        });
      }

      await loadingService.await(candidateService.updateProfilePagesWithAllFunctions("finish", "complete", userData.candidateData));
      if (localStorage.getItem('login_form') === 'LinkedIn') {
        navigate('/dashboard');
      } else {
        navigate('/emailVerification');
      }
    }
  }

  function setFileValue(
    key: string,
    value: UploadedFile,
    setFieldValue: <value>(key: string, value: value) => void,
    setFieldError: (key: string, value: string | undefined) => void,
  ) {
    if (value.original) {
      if (value.original.size > 10 * 1024 * 1024) {
        setFieldError(key, 'File is larger than 10MB.');
        return;
      }
    }
    setFieldValue(key, value);
    if (key === 'resume') {
      setResumeTouched(true);
    } else if (key === 'cover_letter') {
      setCoverLetterTouched(true);
    }
  }

  const openResumePDFInNewTab = async () => {
    const url:any  = await candidateService.getPreSignUrlResume()
    const pdfUrl = url.result
    window.open(pdfUrl, '_blank');
  };

  const openCoverLetterPDFInNewTab = async () => {
    const url:any  = await candidateService.getPreSignUrlCoverLetter()
    const pdfUrl = url.result
    window.open(pdfUrl, '_blank');
  };

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <CustomLayout title={'Final Few Touches'} subTitle={'Set your profile info'} pageName={'finishing'} progressValue={80}>
          <Formik enableReinitialize={true} validationSchema={validationSchema} initialValues={initialVal} onSubmit={handleSubmit}>
            {({ values, setFieldValue, errors, touched, setFieldError, submitForm }) => (
              <Form>
                <ScrollToFieldError/>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>Profile Picture</h6>
                  <div className={styles.timeDuration}>
                    <div className={'d-flex justify-content-between align-items-center '}>
                      <CustomImageUpload
                        maxSize={2 * 1024 * 1024}
                        oversizeError={'Image size must be less than 2MB'} onError={setProfileImageError} disabled={isLoading}
                        defaultFile={values.profile_image?.contents}
                        id={'profile'}
                        fileSelect={async (file: File) => {
                          updateProfileImage(await fileService.readFile(file))
                        }} />
                      {profileImageError && (
                        <div className={styles.error}>{profileImageError}</div>
                      )}
                      <div>
                        <h6 className={'gray-color-text text-normal'}>Max file size 2MB</h6>
                        <h6 className={'gray-color-text '}>Supported files - .png , .jpeg, .jpg</h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>Location</h6>
                  <div className={styles.locationSearchWidth}>
                    <CustomSearch name={'location'} endPoint={value => 'location/autocomplete?' + new URLSearchParams({ input: value, specific: 'true' })}
                      onChange={(value: string) => setFieldValue('location', value)} defaultValue={values.location} timeout={1000} minChars={1} isLocationSearch={true} />
                    {errors.location && touched.location && (
                      <div className={styles.error}>{errors.location}</div>
                    )}
                  </div>
                </div>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>Citizenship Status</h6>
                  <div className={`d-md-flex justify-content-between align-items-center ${styles.answerLength}`}>
                    <div className={'d-flex justify-content-between  w-100'}>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="right_to_work" id="flexRadioDefault1" value={RightToWork.Citizen} onChange={(e) => { setFieldValue('right_to_work', e.target.value); values.visa !== "" && setFieldValue('visa', " "); }} checked={values.right_to_work === RightToWork.Citizen} />
                      <label className="form-check-label" htmlFor="flexRadioDefault1">
                        <h6 className={'gray-color-text text-normal'}>Citizen</h6>
                      </label>
                    </div>
                    <div className="form-check ms-0 ms-md-4 ms-xxl-0">
                      <input className="form-check-input" type="radio" name="right_to_work" id="flexRadioDefault2" value={RightToWork.PR} onChange={(e) => { setFieldValue('right_to_work', e.target.value); values.visa !== "" && setFieldValue('visa', " "); }} checked={values.right_to_work === RightToWork.PR} />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        <h6 className={'gray-color-text text-normal'}>Permanent Resident</h6>
                      </label>
                    </div>
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="right_to_work" id="flexRadioDefault2" value={RightToWork.Visa} onChange={(e) => setFieldValue('right_to_work', e.target.value)} checked={values.right_to_work === RightToWork.Visa} />
                      <label className="form-check-label" htmlFor="flexRadioDefault2">
                        <h6 className={'gray-color-text text-normal'}>Visa Holder</h6>
                      </label>
                    </div>
                    {errors.right_to_work && touched.right_to_work && (
                      <div className={styles.error}>{errors.right_to_work}</div>
                    )}
                    </div>
                    <div className={`ms-0 ms-md-3 ${styles.visaDropdownCandidate}`} hidden={values.right_to_work !== RightToWork.Visa}>
                      <CustomDropdown placeHolder={'Visa Type *'} dataList={visaTypes.map((visa:any) => { return { text: visa.value, value: visa.id } })}
                        selectedValue={visaTypes.filter((fd:any)=> fd.id === values.visa).map((md:any)=> ({ value: md.id, text: md.value }))[0]}
                        getSelectedItem={(i: DropdownDataList) => { setFieldValue("visa", i.value); }}></CustomDropdown>
                      {errors.visa && touched.visa && (
                        <div className={styles.error}>{errors.visa}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>Resume & Cover Letter</h6>
                  <div className={styles.uploadBox}>
                    <div className={styles.resumeUploadBox}>
                      <div className={'d-flex mb-3'}><ResumeIcon />
                        <h6 className={'ms-3 text-bold gray-color-text'}>Resume</h6>
                      </div>
                      <div className={styles.widthUpload}>
                        <FileUpload disabled={isLoading} id={'resume'} filedText={'Select file from your computer'} fileSelect={(file: File | null) => {
                          if (file !== null) fileService.readFile(file).then(value => setFileValue('resume', value, setFieldValue, setFieldError));
                        }} />
                        <h6 className={`gray-color-text   ${styles.borderBottom}`}>Supported file type - .pdf</h6>
                      </div>
                      {values.resume && (
                        <div className={styles.selectedResume}>
                          <h6 className={'ms-3 text-bold gray-color-text'}>Selected File</h6>
                          <div className={`d-flex ${styles.borderTop}`}>
                            <div className={'d-flex justify-content-between align-items-center w-100'}>
                              <h6 className={'text-normal gray-color-text d-flex justify-content-between w-100'}><span className={styles.elipse}>{values.resume ? values.resume.name : ''}</span> <span hidden={isLoading} className={styles.link} onClick={()=>{
                                values.resume?.type !== 'file' ? openResumePDFInNewTab() : fileService.openFile(values.resume)
                              }}>View File</span></h6>
                              <div className={'pointer ms-3'} onClick={() => setFieldValue('resume', '')}>
                                <RecycleBinIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className={`mt-3 ${styles.resumeUploadBox}`}>
                      <div className={'d-flex mb-3'}><ResumeIcon />
                        <h6 className={'ms-3 text-bold gray-color-text'}>Cover Letter</h6>
                      </div>
                      <div className={styles.widthUpload}>
                        <FileUpload accept={'.pdf,.docx'} acceptDropTypes={['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document']}
                          disabled={isLoading} id={'cover_letter'} filedText={'Select file from your computer'} fileSelect={(file: File | null) => {
                            if (file !== null) fileService.readFile(file).then(value => setFileValue('cover_letter', value, setFieldValue, setFieldError));
                          }} />
                        <h6 className={`gray-color-text  ${styles.borderBottom}`}>Supported file type - .pdf, .docx</h6>
                      </div>
                      {values.cover_letter && (
                        <div className={styles.selectedResume}>
                          <h6 className={'ms-3 text-bold gray-color-text'}>Selected File</h6>
                          <div className={`d-flex ${styles.borderTop}`}>
                            <div className={'d-flex justify-content-between align-items-center w-100'}>
                              <h6 className={'text-normal gray-color-text d-flex justify-content-between w-100'}><span className={`${styles.elipse}`}>{values.cover_letter ? values.cover_letter.name : ''} </span><span className={styles.link} onClick={() => {
                                values.cover_letter?.type !== 'file' ? openCoverLetterPDFInNewTab() : fileService.openFile(values.cover_letter)
                              } }>View File</span></h6>
                              <div className={'pointer ms-3'} onClick={() => setFieldValue('cover_letter', '')}>
                                <RecycleBinIcon />
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className={styles.contentQuestion}>
                  <h6 className={styles.widthLeft}>Add referees</h6>
                  <FieldArray name="referees" render={(arrayHelpers: FieldArrayRenderProps) => (
                    <div className={styles.answerLength}>
                      {values.referees?.map((value, index) => (
                        <div key={index} className={'mb-5 mb-md-0'}>
                          <div className={`d-flex justify-content-between align-items-center ${styles.width100}`}>
                            <div className={'w-100 me-4 me-md-0'}>
                              <CustomInputField name={`referees.${index}.name`} placeholder={'Full Name *'}
                                                className={`${styles.locationSearchWidthMobile} mt-4`}
                                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                  value.name = e.target.value;
                                                  setFieldValue('referees', values.referees);
                                                }} />
                            </div>
                            <div onClick={() => {
                              if (!isLoading) {
                                if (value.id) {
                                  setRefereesToRemove([...refereesToRemove, value.id]);
                                }
                                arrayHelpers.remove(index);
                              }
                            }} className={styles.closeBtn}>
                              <CloseIcon />
                            </div>
                          </div>
                          <CustomInputField name={`referees.${index}.email`} placeholder={'Email *'}
                                            className={`${styles.locationSearchWidth} mt-3`}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              value.email = e.target.value;
                                              setFieldValue('referees', values.referees);
                                            }} />
                          <CustomInputField name={`referees.${index}.phone`} placeholder={'Phone *'}
                                            className={`${styles.locationSearchWidth} mt-3`}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              value.phone = e.target.value;
                                              setFieldValue('referees', values.referees);
                                            }} />
                          <CustomInputField name={`referees.${index}.organization`} placeholder={'Organisation *'}
                                            className={`${styles.locationSearchWidth} mt-3`}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                              value.organization = e.target.value;
                                              setFieldValue('referees', values.referees);
                                            }} />
                          <div className={`mt-3 ${styles.locationSearchWidth}`}>
                            <CustomDropdown name={`referees.${index}.relationship`} placeHolder={'Relationship *'}
                                            dataList={relationshipOptions.map((o:any) => ({ text: o.value, value: o.id }))}
                                            getSelectedItem={(val) => {
                                              value.relationship = val.value ?? "";
                                              setFieldValue('referees', values.referees);
                                            }}
                                            selectedValue={relationshipOptions.filter((fd)=> fd.id === value.relationship).map((md:any)=>({ text: md.value, value: md.id }))[0] } />
                            <div className={styles.error}>
                              <ErrorMessage name={`referees.${index}.relationship`} />
                            </div>
                          </div>
                        </div>
                      ))}

                      {(values.referees ?? []).length < 3 && (
                        <div className={'mt-3 d-flex justify-content-end align-items-center'}>
                          <CustomButton text={'Add Referee'} className={styles.fontColor} icon={<PlusIcon />}
                                        onClick={() => handleAddReferee(values, setFieldValue)}
                                        variant={'transparent'} />
                        </div>
                      )}
                    </div>
                  )} />
                </div>
                <div
                  className={styles.contentQuestion}
                  style={{ position: 'relative' }}
                >
                  <h6 className={styles.widthLeft}>
                    How did you hear about us?
                  </h6>
                  <div className={styles.answerLength}>
                    {howDidYouHearAboutUsOptions.map(
                      (option: any, index: number) => (
                        <div className="form-check" key={index}>
                          <input
                            className="form-check-input"
                            type="radio"
                            value={option.id}
                            name="how_did_you_hear_about_us"
                            id={`how_did_you_hear_about_us-${index}`}
                            onChange={(e) => {
                              setFieldValue(
                                'how_did_you_hear_about_us',
                                e.target.value,
                              )
                            }}
                            checked={
                              values.how_did_you_hear_about_us === option.id
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`how_did_you_hear_about_us-${index}`}
                          >
                            <h6 className={'text-normal gray-color-text'}>
                              {option.value}
                            </h6>
                          </label>
                        </div>
                      ),
                    )}
                    {errors.how_did_you_hear_about_us &&
                      touched.how_did_you_hear_about_us && (
                        <div className={styles.error}>
                          {errors.how_did_you_hear_about_us}
                        </div>
                    )}

                    {(( ['Accountant Referral', 'Other'].some((ele)=> howDidYouHearAboutUsOptions?.filter((fd:any)=> fd.id === values.how_did_you_hear_about_us)?.map((md:any)=>(md.value)).includes(ele)))) && (
                      <div className="mt-3">
                        <div className={styles.answerLength}>
                          <CustomInputField
                            name="refereeName"
                            placeholder="Name *"
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                              setFieldValue(
                                'refereeName',
                                e.target.value,
                              )
                            }
                            value={values.refereeName}
                          />
                        </div>
                      </div>

                    )}
                  </div>
                </div>

                <div className={'d-flex justify-content-between align-items-center mt-5'}>
                  <div className={'d-flex align-items-center'}>
                    <CustomButton disabled={isLoading} type={'button'} text={'Go Back'} icon={<LeftArrow />} iconSide={'left'} className={styles.whiteBtn} onClick={handleBack} />
                    {!hideSaveAndExit && (
                      <CustomButton disabled={isLoading} type={'submit'} text={'Save & Exit'} icon={<SaveIcon />} iconSide={'left'} className={`ms-3 ${styles.outlineBtn}`} onClick={() => handleSaveAndExit(values)} />
                    )}
                  </div>
                  <div className={'d-flex align-items-center'}>
                    {!hideSkip && (
                      <CustomButton disabled={isLoading} type={'button'} text={'Skip for Now'} className={styles.whiteBtn} onClick={handleSkip} />
                    )}
                    <CustomButton loading={isLoading} type={'submit'} text={'Continue'} icon={<RightLongArrowIcon size={14} />} iconSide={'right'} onClick={submitForm} />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CustomLayout>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default FinishingPage;
