import React, { useContext, useEffect, useMemo, useState } from 'react'

import styles from './sinIn.module.scss'
import SignInImage from '../../../src/images/Professional Sign Up.jpg'
import { Form, Formik } from "formik";
import CustomButton from "../shared/CustomButton/customButton";
import CustomInputField from "../shared/customInputField/CustomInputField";
import { useNavigate } from "react-router-dom";
import LoadingService from '../../services/loading/loading.service';
import * as Yup from 'yup';
import 'yup-phone';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import { Auth } from 'aws-amplify';
import UserService from '../../services/user/user.service';
import OrgUserService from '../../services/organization/organization.service';
import OrgLogo from "../../images/BBB Logo_Organisation.jpg";
import { UserContext } from '../../context'
import { MasterDataContext } from '../../context/masterData'
import DataService from '../../services/data/data.service'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'


type OrgSignUpProps = {
  tradingName: string;
  webAddress: string;
  contactPersonFirstName: string;
  contactPersonLastName: string;
  email: string;
  contactNumber: string;
  password: string;
  confirmPassword: string;
  userAgreement: boolean;
}

function SignUp() {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModel, setErrorModel] = useState(false)
  const navigate = useNavigate();
  const user:any = useContext(UserContext);
  const masterData = useContext(MasterDataContext);
  const initialVal: OrgSignUpProps = useMemo(() => {
    return {
      tradingName: '',
      webAddress: '',
      contactPersonFirstName: '',
      contactPersonLastName: '',
      email: '',
      contactNumber: '',
      password: '',
      confirmPassword: '',
      userAgreement: false,
    };
  }, []);

  const validationSchema = Yup.object().shape({
    tradingName: Yup.string().required('Please enter your trading name.'),
    webAddress: Yup.string().matches(/^\S*$/, "Web address couldn't contain any blank spaces"),
    contactPersonFirstName: Yup.string().matches(/^[A-Za-zÁÉÍÓÚÜÑñáéíóúü-\s]+$/, 'Name can only contain uppercase and lowercase letters').required('Please enter your first name.'),
    contactPersonLastName: Yup.string().matches(/^[A-Za-zÁÉÍÓÚÜÑñáéíóúü-\s]+$/, 'Name can only contain uppercase and lowercase letters').required('Please enter your last name.'),
    email: Yup.string().required('Please enter your email address.').email('Please enter a valid email address.'),
    contactNumber: Yup.string().matches(/^(?:\+61\d{9}|04\d{8})$/, 'Please use the format +61XXXXXXXXX or 04XXXXXXXX').required('Please enter your contact number.').phone('AU', false, 'Please use the format +61XXXXXXXXX or 04XXXXXXXX.')
      .phone('AU', false, 'Please enter a valid phone number.'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character')
      .required('Please enter your password.'),
    confirmPassword: Yup.string().required('Please confirm your password').oneOf([Yup.ref<string>('password')], 'Passwords do not match.'),
    userAgreement: Yup.boolean().required().isTrue('Please agree to our privacy policy and our terms/conditions.'),
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const loadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const userService = useMemo(() => {
    return new UserService();
  }, []);

  const orgUserService = useMemo(() => {
    return new OrgUserService();
  }, []);

  const dataService: DataService = useMemo(() => {
    return new DataService();
  }, []);

  useEffect(() => {
    loadingService.await(userService.isLoggedIn()).then((isLoggedIn) => {
      if (isLoggedIn) {
        navigate('/');
      }
    });
  }, [loadingService, userService, navigate]);


  // Example async functions
  function asyncFunction1() {
    return new Promise((resolve) => {
      resolve(userService.getUserDataT())
    });
  }

  function asyncFunction3() {
    return new Promise((resolve) => {
      resolve(dataService.getAllList())
    });
  }

  async function fetchData(userData:any) {
    try {
      const [data1, data3]:any = await Promise.all([
        asyncFunction1(),
        asyncFunction3(),
      ]);
      userData.setUserData(data1);
      masterData.setMasterData(data3)

    } catch (error :any) {
      setErrorMessage(error.message);
      setErrorModel(true)
    }
  }

  function addCountryCode(phoneNumber:any) {
    // Remove any non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // Check if the number starts with '0'
    if (digitsOnly.startsWith('0')) {
      // Replace the '0' with '61' (Australian country code)
      return `+61${digitsOnly.substring(1)}`;
    } else {
      // If it doesn't start with '0', assume it already has the country code
      return phoneNumber;
    }
  }

  async function handleSubmit(values: OrgSignUpProps, { setFieldError }: any) {
    try {
      const isMobileValid = await loadingService.await(userService.validateMobileNumber(values.contactNumber));
      if (!isMobileValid) {
        setFieldError('contactNumber', 'Please enter a new mobile number! This number is already registered.');
        return;
      }
      await loadingService.await(Auth.signUp({
        username: values.email,
        password: values.password,
        attributes: {
          email: values.email,
          name: `${values.contactPersonFirstName} ${values.contactPersonLastName}`,
          given_name: values.contactPersonFirstName,
          family_name: values.contactPersonLastName,
          'custom:role': 'org_user',
          phone_number:  addCountryCode(values.contactNumber),
        },
      }));
      localStorage.setItem('login_form', 'App');
      await loadingService.await(Auth.signIn(values.email, values.password));
      await loadingService.await(Auth.verifyCurrentUserAttribute('email'));
      await loadingService.await(userService.updateUserData({
        mobile_number: values.contactNumber,
      }));
      await loadingService.await(orgUserService.createOrg({
        name: values.tradingName,
        website: values.webAddress,
        email: values.email,
        phone: values.contactNumber,
      }));
      await  fetchData(user);
      navigate('/hireFor');
    // eslint-disable-next-line
    } catch (err: any) {
      if (err.name === 'UsernameExistsException') {
        setFieldError('email', 'Email address is already in use.');
      } else if (err.name === 'InvalidPasswordException') {
        setFieldError('password', 'Password is invalid.');
      } else if (err.name === 'UserLambdaValidationException') {
        setFieldError('email', err.message);
      } else {
        setFieldError('email', 'An error occurred. Please try again.');
      }
    }
  }

  return (
    <div>
      {isLoading && <LoadingSpinner/>}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik initialValues={initialVal} onSubmit={handleSubmit} validationSchema={validationSchema} validateOnChange>
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className={'d-flex justify-content-between w-100'}>
                <div className={styles.formContainerOrg}>
                  <div className={styles.logo}>
                    <img src={OrgLogo} alt="react logo" className={styles.logoImage}/>
                  </div>
                  <div className={styles.leftContentBox}>
                    <div className={styles.middleContent}>
                      <h1 className={'text-almostBlack text-bolder mt-3'}>Sign Up</h1>
                      <h6 className={'mt-5 text-normal signInLineHeight'}>There’s a brand new way to be matched to the best Finance professionals in the market based on the role you need to fill, your organisation’s industry sector and the number of transactions you have every month. Let the market tell you what the role should be costing you. Tell us what you need; we’ll tell you what’s out there right now.</h6>
                      <div className={`d-flex mt-5 w-100 ${styles.borderBottom}`}>
                        {/* eslint-disable-next-line */}
                        <CustomInputField name={'tradingName'} placeholder={'Organisation Name (Trading Name) *'} className={'w-100'} onChange={(e: any) => { if (!isLoading) setFieldValue('tradingName', e.target.value)}}/>
                      </div>
                      <h6 className={'text-normal mt-3'}>Main contact person</h6>
                      <div className={'d-flex justify-content-between mt-1 w-100'}>
                        {/* eslint-disable-next-line */}
                        <CustomInputField name={'contactPersonFirstName'} placeholder={'First Name *'} className={styles.nameCol} onChange={(e: any) => { if (!isLoading) setFieldValue('contactPersonFirstName', e.target.value)}}/>
                        {/* eslint-disable-next-line */}
                        <CustomInputField name={'contactPersonLastName'} placeholder={'Last Name *'} className={`ms-2 ${styles.nameCol}`} onChange={(e: any) => { if (!isLoading) setFieldValue('contactPersonLastName', e.target.value)}}/>
                      </div>
                      <div className={'mt-3'}>
                        {/* eslint-disable-next-line */}
                        <CustomInputField name={'email'} placeholder={'Email *'} onChange={(e: any) => { if (!isLoading) setFieldValue('email', e.target.value)}}/>
                      </div>
                      <div className={'mt-3'}>
                        {/* eslint-disable-next-line */}
                        <CustomInputField name={'contactNumber'} placeholder={'Mobile Phone *'} onChange={(e: any) => { if (!isLoading) setFieldValue('contactNumber', e.target.value)}}/>
                      </div>
                      <div className={'mt-3'}>
                        {/* eslint-disable-next-line */}
                        <CustomInputField name={'webAddress'} placeholder={'Web Address'} onChange={(e: any) => { if (!isLoading) setFieldValue('webAddress', e.target.value)}}/>
                      </div>
                      <div className={'mt-3'}>
                        {/* eslint-disable-next-line */}
                        <CustomInputField type={'password'} placeholder={'Password *'} name={'password'} onChange={(e: any) => { if (!isLoading) {setFieldValue('password', e.target.value); touched.password = true}}} pwError/>
                      </div>
                      {errors.password && touched.password && <div className={styles.error}>{errors.password}</div> }
                      <div  className={'mt-3'}>
                        {/* eslint-disable-next-line */}
                        <CustomInputField type={'password'} placeholder={'Confirm Password *'} name={'confirmPassword'} onChange={(e: any) => { if (!isLoading) {setFieldValue('confirmPassword', e.target.value); touched.confirmPassword = true}}} pwError/>
                      </div>
                      {errors.confirmPassword && touched.confirmPassword && <div className={styles.error}>{errors.confirmPassword}</div> }
                      <div className={'d-flex justify-content-between w-100 mt-3'}>
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onChange={(e) => { if (isLoading) { e.preventDefault() } else { setFieldValue('userAgreement', !values.userAgreement) }}}/>
                          <label className="form-check-label">
                            <p className={'text-almostBlack text-bold font-size-semi'}>I have read and agree to the <a className={'text-decoration-underline text-almostBlack'} rel={'noreferrer'} href={'https://betterbusinessbasics.com.au/privacy-policy/'} target={'_blank'}>privacy policy</a> and <span className={'text-decoration-underline'}>terms /conditions</span></p>
                          </label>
                          {touched.userAgreement && errors.userAgreement ? <div className={styles.error}>{errors.userAgreement}</div> : null}
                        </div>
                      </div>
                      <div className={'mt-4'}>
                        <CustomButton disabled={isLoading} type={'submit'} text={'Sign Up with BBB'}/>
                      </div>
                    </div>
                    <p className={'text-almostBlack text-normal mt-5 font-size-semi'}>Already have an account? <span className={'text-bold pointer '} onClick={() => { if (!isLoading) navigate('/signIn?type=organizationSignIn') }}>Sign In</span></p>
                  </div>
                </div>
                <div className={styles.imageContainer}>
                  <img src={SignInImage} alt={'signUp'} className={styles.imageorg}/>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default SignUp
