import React from 'react';

interface Props {
  size?: number
  color?: string
}

const PhoneIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size ?? 19}
    height={size ?? 19}
    viewBox={'0 0 19 19'}
    fill="none"
  >
    <path
      stroke={color ?? "#636363"}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 1h4l2 5-2.5 1.5a11 11 0 0 0 5 5L13 10l5 2v4a2 2 0 0 1-2 2A16 16 0 0 1 1 3a2 2 0 0 1 2-2Z"
    />
  </svg>
  )
}

export default PhoneIcon