import React from 'react';

interface Props {
  size?: number
  color?: string
}

const EyeIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 22}
      height={size ? size - 6 : 16}
      fill="none"
      viewBox={'0 0 22 16'}
    >
      <path
        d="M11 10a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"
        stroke={color ?? "#9CA3AF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 8c-2.667 4.667-6 7-10 7S3.667 12.667 1 8c2.667-4.667 6-7 10-7s7.333 2.333 10 7Z"
        stroke={color ?? "#9CA3AF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default EyeIcon
