import React, { useEffect, useMemo, useState } from 'react'
import styles from './Profile.module.scss'
import colors from '../../styles/variables.module.scss'
import CustomButton from "../shared/CustomButton/customButton";
import RightArrowIcon from "../../icons/rightArrow.icon";
import LoadingService from '../../services/loading/loading.service';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import StartAssessmentModal from '../assesment/startAssessmentModal';
import CustomModal from '../shared/customModal/customModal';
import { CandidateService } from '../../services/candidate/candidate.service';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type AssessmentResultCardProps = {
  overallScore: number;
  categoryScores: { category: string; score: number }[];
  expectedPay: number;
};

// The order of the categories in the result card
const CATEGORY_ORDER = [
  'Bookkeeping Basics',
  'Debits & Credits',
  'Accounts Receivable',
  'Accounts Payable',
  'Bank Reconciliations',
  'Payroll',
  'Inventory',
  'Accruals & Prepayments',
  'Adjusting Entries',
  'Accounting Transactions & Entries',
  'Balance Sheet & P&L Statements',
  'Financial Statements',
  'Accounting Ratios',
  // These two are the same thing, but the DB value is currently 'Break Even' when it should be 'Break Even Analysis'
  'Break Even Analysis',
  'Break Even',
  'Statements of Cash Flow',
];

function ResultCard() {
  const [isLoading, setIsLoading] = useState(false);
  const [assessmentCompletionTime, setAssessmentCompletedTime] = useState<string>('');
  const [values, setValues] = useState<AssessmentResultCardProps>({
    overallScore: 0,
    categoryScores: [],
    expectedPay: 0,
  });
  const [modal, setModal] = useState(false);
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState('');
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  // Function to format the duration
  const formatDuration = (hours: number, minutes: number, seconds: number): string  => {
    const parts = [];

    if (hours > 0) {
      parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
    }
    if (minutes > 0 || parts.length > 0) {
      parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
    }
    if (seconds > 0 || parts.length === 0) {
      parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
    }

    return parts.join(' ');
  }

  useEffect(() => {
    loadingService.await(candidateService.getAssessmentResults()).then((res: any) => {
      if (res !== null && res.status === 'success') {
        if (res.result.duration === null) {
          setAssessmentCompletedTime('');
        }
        const durationObject = JSON.parse(res.result.duration);

        if (durationObject && durationObject.duration) {
          const { hours, minutes, seconds } = JSON.parse(durationObject.duration);

          // Format the duration
          const formattedDuration = formatDuration(hours, minutes, seconds);
          setAssessmentCompletedTime(formattedDuration);
          setValues((prev: any) => ({
            ...prev,
            overallScore: Math.floor(res.result.last_score / res.result.max_score * 100),
            categoryScores: res?.result.category_results?.map((value: any) => ({
              category: value.category,
              score: Math.floor(value.last_score / value.max_score * 100),
            })),
          }));
        }
      }
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [loadingService, candidateService]);


  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      <h5 className={'text-bolder text-almostBlack'}>Assessment Result</h5>
      <div className={`mt-5 ${styles.green}`}>
        <div className={'d-flex align-items-center'}>
          <h1 className={'text-success'}>{values.overallScore || 0}%</h1>
        </div>
      </div>
      <div className={styles.greenBorderBottom}>
        {values?.categoryScores?.sort((a, b) => CATEGORY_ORDER.indexOf(a.category) - CATEGORY_ORDER.indexOf(b.category))?.map((value, index) => (
          <div className={'d-flex justify-content-between align-items-center mt-5'} key={index}>
            <h6 className={'text-normal gray-color-text'}>{value.category}</h6>
            <h5 className={'text-success'}>{value.score}%</h5>
          </div>
        ))}
      </div>
      <div className='d-flex justify-content-center mt-4 mb-4'>
        <h5>Duration : <span className='text-bold text-success'>{assessmentCompletionTime}</span></h5>
      </div>
      <p className={'text-normal gray-color-text mt-3'}>To get more detailed report and analysis contact BBB via <span className={'text-bold'}>1300 991 059</span> or <span className={'text-bold'}>careers@betterbusinessbasics.com.au</span></p>
      <CustomButton text={'Re-Assess'} icon={<RightArrowIcon color={colors.grayColor} />} disabled={isLoading} iconSide={'right'} className={styles.assestBtn} variant={'transparent'} onClick={() => setModal(true)} />
      <CustomModal open={modal} onCloseModal={(val) => setModal(val)}>
        <StartAssessmentModal />
      </CustomModal>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default ResultCard
