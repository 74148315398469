import React from 'react';

interface Props {
  size?: number
  color?: string
}

const InProgressIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 19}
      height={size ?? 19}
      fill="none"
      viewBox={'0 0 19 19'}

    >
      <path
        d="M6 2.05a8.031 8.031 0 0 1 6 14.9m0-4.45v5h5M2.63 4.66v.01M1.06 8.5v.01m.57 4.09v.01m2.53 3.26v.01M8 17.44v.01"
        stroke={color ?? "#F98F12"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default InProgressIcon
