import React from 'react';

interface Props {
  size?: number
  color?: string
}

const ReferenceIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 20}
      height={size ?? 20}
      fill="none"
      viewBox={'0 0 20 20'}
    >
      <path
        stroke={color ?? "#1F1F1F"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 19v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2m1-17.87a4 4 0 0 1 0 7.75M19 19v-2a4 4 0 0 0-3-3.85M11 5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
      />
    </svg>
  )
}

export default ReferenceIcon
