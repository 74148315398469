import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './dashboard.module.scss'
import TopNavigation from "../topNavigation/topNavigation";
import UnlockIcon from "../../icons/unlock.icon";
import AssessmentCard from "../assesmentCard/assessmentCard";
import ProfileStatusCard from "../assesmentCard/profileStatusCard";
import CustomButton from "../shared/CustomButton/customButton";
import RightArrowIcon from "../../icons/rightArrow.icon";
import LoadingService from '../../services/loading/loading.service';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import UserService from '../../services/user/user.service';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import CustomModal from '../shared/customModal/customModal';
import StartAssessmentModal from '../assesment/startAssessmentModal';
import { UploadedFile } from "../../services/file/file.model";
import { CandidateService } from '../../services/candidate/candidate.service';
import FileService from '../../services/file/file.service';
import { JobDetails } from '../../services/job/job.res.model';
import { UserContext } from '../../context';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type DashboardProps = {
  fullName: string;
  email: string;
  profileStatus: number;
  top3Matches: Array<JobDetails>;
  profileImage?: UploadedFile | null
}

function Dashboard() {
  const user = useContext(UserContext);
  const navigate = useNavigate();
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false);

  const [values, setValues] = useState<DashboardProps>({
    email: '',
    fullName: '',
    profileStatus: 0,
    top3Matches: [],
    profileImage: null,
  });

  const [modal, setModal] = useState(false);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  const userService: UserService = useMemo(() => {
    return new UserService();
  }, []);

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const fileService: FileService = useMemo(() => {
    return new FileService();
  }, []);

  useEffect(() => {
    loadingService.await(userService.isLoggedIn()).then(res => !res ? navigate('/') : undefined);
    async function fetchData(): Promise<void> {
      const userData:any = user.userData;
      const image = await userService.getProfileImage();
      const profileImage = image ? await fileService.readBackendImage(image) : null;
      if (userData) {

        setValues((val) => ({
          ...val,
          fullName: userData ? userData.full_name : '',
          email: userData ? userData.email : '',
          profileStatus: userData ? parseInt(userData.status) ?? 0 : 0,
          profileImage: profileImage,
        }));
      }

    }
    loadingService.await(fetchData()).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [navigate, loadingService, userService, candidateService, fileService, user.userData]);

  // If the user has completed the assessment, redirect to the top matches page
  useEffect(() => {
    loadingService.await(candidateService.getAssessmentResults()).then((res:any) => {
      if (res !== null && res.status === 'success' && res.result.time != null) {
        navigate('/dashboard/topmatches')
      }
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [candidateService, loadingService, navigate]);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <TopNavigation dashboard tabValue={'0'} />
        <div className={styles.content}>
          <div>
            <div className={styles.yellowBox}>
              <div className={styles.introTitle}>
                <div className={'d-flex justify-content-center align-items-center'}>
                  <div>
                    <UnlockIcon />
                  </div>

                  <div className={'ms-1 ms-lg-5'}>
                    <h6 className={'text-almostBlack'}>Complete Your</h6>
                    <h1 className={'text-almostBlack text-bolder'}>Technical Ability Assessment</h1>
                  </div>
                </div>
                <h6 className={`gray-color-text ms-5 text-normal ${styles.widthBox}`}>Your technical ability assessment is pending, please complete your assessments to <span className={'text-bold'}>unlock your best 3 role matches</span></h6>
              </div>
              <div className={'row mt-5'}>
                <div className={'col-sm-12 col-lg-8'}>
                  <AssessmentCard title={'Your Technical Ability Assessment'} description={'This assessment is not a pass or fail test. It compares your knowledge against a number of financial tasks so that we can better match you to roles, provide you with role suggestions that might elevate your learning, and highlight what skills might need a refresher to increase your enjoyment and rate.'} />
                </div>
                <div className={'col-sm-12 col-lg-4 mt-3 mt-lg-0'}>
                  <AssessmentCard softSkill title={'Your Soft Skill Assessment'} description={'part of the success of any role is about ‘fit’. This assessment looks at the attributes that the best role will need to suit your personality and commas style.'} />
                </div>
              </div>
            </div>
            <div className={'row mt-5'}>
              <div className={'col-sm-12 col-lg-4'}>
                <ProfileStatusCard email={values.email} full_name={values.fullName} profilePic={values.profileImage?.contents ?? ""} />

              </div>
              <div className={`col-sm-12 col-lg-8 `}>
                <div className={styles.cardProfile}>
                  <div className={'d-flex align-items-center justify-content-center'}>
                    <div>
                      <UnlockIcon />
                    </div>

                    <div className={'ms-5'}>
                      <h6 className={'text-almostBlack'}>Complete Your</h6>
                      <h1 className={'text-almostBlack text-bolder'}>Technical Ability Assessment</h1>
                    </div>
                  </div>
                  <h6 className={'gray-color-text ms-5 mt-5 text-normal'}>Your Technical Ability assessment is pending, please complete your
                    assessments to <span className={'text-bold'}>unlock your best 3 role matches</span></h6>
                  <CustomButton text={'Start Now'} icon={<RightArrowIcon />} iconSide={'right'} className={`mt-5 ${styles.popUpbutton}`} onClick={() => setModal(true)} />
                  <CustomModal title={'Welcome to the Technical Ability Assessment'} open={modal} onCloseModal={(val) => setModal(val)} >
                    <StartAssessmentModal />
                  </CustomModal>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Dashboard;
