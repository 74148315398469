import React, { ReactElement, useState, useEffect } from 'react';
import styles from './TimePicker.module.scss';
import DownArrowIcon from "../../../icons/downArrow.icon";
import UpArrowIcon from "../../../icons/upArrow.icon";
import colors from '../../../styles/variables.module.scss';

type inputFiledProps = {
  id: string;
  name?: string;
  filedText?: string;
  loading?: boolean;
  handleChange: (e: string) => void;
  // buttonStatus?: string;
  disabled?: boolean;
  icon?: ReactElement;
  defaultValue?: number;
  isHours?: boolean;
};

const YearSelect = ({ id, handleChange, isHours, defaultValue }: inputFiledProps) => {
  const [year, setYear] = useState(defaultValue || 0);

  useEffect(() => {
    if (defaultValue !== undefined) {
      setYear(defaultValue);
      handleChange(`${defaultValue}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  const handleYear = () => {
    setYear(year + 1);
    handleChange(`${year + 1}`);
  };

  const handleYearDecrease = () => {
    if (year === 0) {
      setYear(0);
      handleChange(`${year}`);
    } else {
      setYear(year - 1);
      handleChange(`${year - 1}`);
    }
  };

  return (
    <>
      <div id={id} className={styles.textFiled}>
        <div>
          <p className={styles.title}>{isHours ? 'Hours *' : 'Years *'}</p>
          {year < 10 ? (
            <h6 className={styles.textColor}>{`${year}`}</h6>
          ) : (
            <h6 className={styles.textColor}>{`${year}`}</h6>
          )}
        </div>
        <div className={styles.arrowBox}>
          <button type="button" className={`pointer ${styles.iconButton}`} onClick={handleYear}>
            <UpArrowIcon />
          </button>
          <button type="button" className={`pointer ${styles.iconButton}`} onClick={handleYearDecrease}>
            <DownArrowIcon color={colors.textLightColor} size={12} />
          </button>
        </div>
      </div>
    </>
  );
};

export default YearSelect;
