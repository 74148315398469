import React, { useEffect, useMemo } from 'react';
import { useState } from 'react'
import styles from './../dashboard/dashboard.module.scss'
import TopNavigation from "../topNavigation/topNavigation";

import { useNavigate } from 'react-router';
import CompanyCard from "./companyCard";
import OrganizationService from '../../services/organization/organization.service';
import LoadingService from '../../services/loading/loading.service';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import { Auth } from 'aws-amplify'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

type Company = {
  id: string;
  name: string;
  financials_connected: boolean;
  location: string;
  systemExpertise?: any
};

function CompanyList() {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModel, setErrorModel] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [companies, setCompanies] = useState<Company[]>([]);
  const [user, setUser] = useState<any>(null);

  const loadingService = useMemo(() => new LoadingService(setIsLoading), []);
  const orgService = useMemo(() => new OrganizationService(), []);

  // If the user is not logged in, redirect to the home page
  useEffect(() => {
    loadingService.await(orgService.isLoggedIn()).then((res) => {
      if (!res) {
        navigate('/');
      }
    });
    Auth.currentAuthenticatedUser().then((res) => setUser(res.attributes)).catch((error) => {
      setErrorModel(true)
      setErrorMessage(error.message)
    })
  }, [navigate, loadingService, orgService]);

  // If the user is not an organization user, redirect to the home page
  useEffect(() => {
    loadingService.await(orgService.isOrgUser()).then((res) => {
      if (!res) {
        navigate('/');
      }
    });
  }, [navigate, loadingService, orgService]);

  // Get the list of companies
  useEffect(() => {
    loadingService.await(orgService.getOrgData()).then((res) => {
      if (res !== null && res.status === 'success' && res.result !== null) {
        setCompanies((prev) => [...prev, {
          id: res.result?.id ?? '',
          name: res.result?.name ?? '',
          location: res.result?.address ?? '',
          financials_connected: res.result?.financials_connected ?? false,
          systemExpertise: res.result?.__jobs__[0]?.skills
            .filter((fd:any) => fd?.type === 'system_expertise')
            .map((val: any) => val?.value),
        }].filter((company, index, self) => index === self.findIndex((t) => (t.id === company.id))));
      }
    }).catch((error: any)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
    loadingService.await(orgService.getClients()).then((res) => {
      if (res !== null && res.status === 'success' && res.result !== null) {

        setCompanies((prev) => [...prev, ...res.result.map((company) => ({
          id: company.id,
          name: company.name,
          location: company.address,
          financials_connected: company.financials_connected,
          systemExpertise:company.__jobs__[0]?.skills
            .filter((fd:any) => fd?.type === 'system_expertise')
            .map((val: any) => val?.value),
        }))].filter((company, index, self) => index === self.findIndex((t) => (t.id === company.id))));
      }
    });
  }, [loadingService, orgService]);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <div className={'w-100'}>
          <TopNavigation organization tabValue={'3'} organizationLogo/>
          <div className={styles.contentCompany}>
            <div className={'w-100'}>
              <h3 className={'text-bolder mb-5'}>Organisations </h3>
              {
                companies.map((company: Company, index: number) => (
                  <div className={'mt-3'} key={index} >
                    <CompanyCard
                      id={company.id}
                      name={company.name}
                      accountHolder={user ?? ''}
                      financials_connected={company.financials_connected}
                      location={company.location} pageIsLoading={isLoading} systemExpertise={company.systemExpertise}
                    />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  );
}

export default CompanyList;
