import React, {  useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import CustomButton from "../shared/CustomButton/customButton";
import RecycleBinIcon from "../../icons/recycleBinIcon";
import CustomInputField from "../shared/customInputField/CustomInputField";
import { Formik,Form } from "formik";
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import LoadingService from '../../services/loading/loading.service';
import { CandidateService } from '../../services/candidate/candidate.service';

interface ChangePasswordFormProps {
  onSuccess: () => void;
  onFailure: (error: Error) => void;
  closeModal: () => void;
}

export type UpdatePasswordProps = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const UpdatePassword: React.FC<ChangePasswordFormProps> = ({ onSuccess, onFailure, closeModal }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentPassword, setCurrentPassword] = useState('');

  const [err, setErr] = useState({ status: false, text: '' });
  const navigate = useNavigate();

  const initialVal: UpdatePasswordProps = useMemo(() => {
    return {
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
    }
  }, [])

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const candidateService = useMemo(() => new CandidateService(), []);

  const validationSchema = Yup.object().shape({
    currentPassword: Yup.string().required('Current password is required'),
    newPassword: Yup.string()
      .notOneOf([Yup.ref<string>('currentPassword')], 'New password cannot be the same as the current password')
      .min(8, 'Password must be at least 8 characters')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.',
      ).required('Password is required'),
    confirmPassword: Yup.string()
      .notOneOf([Yup.ref<string>('currentPassword')], 'Confirm password cannot be the same as the current password')
      .required('Please confirm your password')
      .oneOf([Yup.ref<string>('newPassword')], 'Passwords do not match.'),
  });

  const handleOkClick = async () => {
    localStorage.removeItem('masterData');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('login_form');
    await Auth.signOut();
    navigate('/signIn?type=candidateSignIn');
  }

  const showAlert = async () => {
    window.alert('Password has been updated successfully');
    handleOkClick();
  }

  const handleSubmit = async (values: UpdatePasswordProps) => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      setIsLoading(true);
      await Auth.changePassword(user, values.currentPassword, values.newPassword);
      onSuccess();
      await loadingService.await(candidateService.updateLastPage('jobs'));
      setIsLoading(false);
      await showAlert();
      // eslint-disable-next-line
    } catch (error: any) {
      setIsLoading(false)
      setErr({ status: true, text: "Current password is incorrect" });
      onFailure(error);
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      error.message === "Attempt limit exceeded, please try after some time." ?
        setErr({ status: true, text: "Attempt limit exceeded, please try after some time" }) :
        setErr({ status: true, text: "Current password is incorrect" });
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setErr({ status: false, text: '' });
  }, [currentPassword])


  return (
    <div>
    {isLoading && <LoadingSpinner/>}
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
    <Formik initialValues={initialVal} enableReinitialize={true} onSubmit={handleSubmit} validationSchema={validationSchema}>
      {({ values, setFieldValue, errors }) => (
        <Form>
          <div>
            <div className={`mt-3 justify-content-between ${styles.borderBottomLight}`}>
                {/* eslint-disable-next-line */}
                <CustomInputField type='password' name={'currentPW'} placeholder={'Current Password *'} className={'mt-2'} value={values.currentPassword} onChange={(event: any) => {setCurrentPassword(event.target.value); setFieldValue("currentPassword", event.target.value);}}/>
                {errors.currentPassword && <div className={styles.error}>{errors.currentPassword}</div>}
                {err.status && <div className={styles.error}>{err.text}</div>}
            </div>
            <div className={`mt-4 justify-content-between ${styles.borderBottomLight}`}>
                {/* eslint-disable-next-line */}
                <CustomInputField type='password' name={'newPW'} placeholder={'New Password *'} className={'mt-2'} value={values.newPassword} onChange={(event: any) => { setFieldValue("newPassword", event.target.value);}}/>
                {errors.newPassword && <div className={styles.error}>{errors.newPassword}</div>}
                {/* eslint-disable-next-line */}
                <CustomInputField type='password' name={'confirmPW'} placeholder={'Confirm New Password *'} className={'mt-2'} value={values.confirmPassword} onChange={(event: any) => {setFieldValue("confirmPassword", event.target.value);}}/>
                {errors.confirmPassword && <div className={styles.error}>{errors.confirmPassword}</div>}
            </div>
            <div className={'d-flex justify-content-between mt-5'}>
                <div>
                    <CustomButton type={'button'} className={`${styles.outlineDiscardBtn}`} icon={<RecycleBinIcon/>} iconSide={'left'} text={'Discard'} onClick={closeModal} />
                </div>
                    <div>
                        <CustomButton type={'submit'} loading={isLoading} text={'Update Password'} className={styles.modalBtn} disabled={isLoading || err.status || Object.keys(errors).length !== 0 || Object.values(values).every(value => value === '')}/>
                    </div>
                </div>
          </div>
        </Form>
      )}
    </Formik>
    </div>
    </div>
  )
}

export default UpdatePassword
