import React, { useContext, useRef } from 'react'
import { useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import { Form, Formik } from "formik";
import CustomButton from "../shared/CustomButton/customButton";
import CustomLayout from "../layout/commonLayout";
import * as Yup from 'yup';
import SaveIcon from '../../icons/save.icon';
import RightLongArrowIcon from '../../icons/rightLongArrow.icon';
import LeftArrow from '../../icons/leftArrow.icon';
import { useNavigate } from 'react-router';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import { Auth } from 'aws-amplify';
import LoadingService from '../../services/loading/loading.service';
import { CandidateService } from '../../services/candidate/candidate.service';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context'
import { MasterDataContext } from '../../context/masterData'
import WorkExperience, { Role } from './workExperience';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';
import { ScrollToFieldError } from "../../scrollToFieldError/scrollToFieldError";
import CustomDropdown from "../shared/CustomDropdown/customDropdown";
import DefaultCloseIcon from "../../icons/defaultClose.icon";
import { matches } from "../profile/profile";

export const KeyCodes = {
  comma: 188,
  enter: 13,
};

export type ExperienceProps = {
  years_of_overall_experience: number;
  skills?: { skill_name: string, skill_type: string }[];
  roles: { id: string, role: string, years_of_experience: string }[];
}

export const delimiters = [KeyCodes.comma, KeyCodes.enter];

function Experience() {
  const userData = useContext(UserContext);
  const masterData = useContext(MasterDataContext);
  const navigate = useNavigate();
  const [roleList, setRoleList] = useState<any[]>([]);
  // const [suggestions, setSuggestions] = useState<{ id: string, text: string }[]>([]);
  const [industryExpertiseInput, setIndustryExpertiseInput] = useState('');
  const [systemExpertiseInput, setSystemExpertiseInput] = useState('');
  const [taskExpertiseInput, setTaskExpertiseInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [systemExpTagValidationErrors, setSystemExpTagValidationErrors] = useState<string>('');
  const [taskExpTagValidationErrors, setTaskExpTagValidationErrors] = useState<string>('');
  const [industryExpTagValidationErrors, setIndustryExpTagValidationErrors] = useState<string>('');

  const [formData, setFormData] = useState<ExperienceProps>({
    years_of_overall_experience: 0,
    skills: [],
    roles: [],
  });
  const [removeSpinner] = useState({ index: "", status: false });
  const [editable, setEditable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const [roles, setRoles] = useState<Role[]>([]);
  const [areRolesValid, setAreRolesValid] = useState(false);

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const candidateService = useMemo(() => new CandidateService(), []);
  const [taskExpertiseSuggestions, setTaskExpertiseSuggestions] = useState<string[]>([]);
  const [systemExpertiseSuggestions, setSystemExpertiseSuggestions] = useState<string[]>([]);
  const [industryExpertiseSuggestions, setIndustryExpertiseSuggestions] = useState<string[]>([]);

  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);
  useEffect(() => {
    setEditable(true)
  }, []);

  useEffect(() => {
    if (masterData?.masterData) {
      setTaskExpertiseSuggestions(masterData.masterData.filter((fd: any) => fd.category === 'tasks_expertise').sort((a: any, b: any) => a.order - b.order).map((item: any) => item.value));
      setSystemExpertiseSuggestions(masterData.masterData.filter((fd: any) => fd.category === 'systems_expertise').sort((a: any, b: any) => a.order - b.order).map((item: any) => item.value));
      setIndustryExpertiseSuggestions(masterData.masterData.filter((fd: any) => fd.category === 'industry_expertise').sort((a: any, b: any) => a.order - b.order).map((item: any) => item.value));
      setRoleList(masterData.masterData.filter((fd: any) => fd.category === 'finance_jobs').sort((a: any, b: any) => a.order - b.order));
    } else {
      if (masterData.isError) {
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }

  }, [masterData]);
  const dragItem = useRef(0);
  const dragOverItem = useRef(0);
  const [hideSkip, setHideSkip] = useState(false);
  const [hideSaveAndExit, setHideSaveAndExit] = useState(false);
  const location = useLocation();
  useEffect(() => {
    if (location.state !== null) {
      if (location.state.hideSkip) {
        setHideSkip(location.state.hideSkip);
      }
      if (location.state.hideSaveAndExit) {
        setHideSaveAndExit(location.state.hideSaveAndExit);
      }
    }
  }, [location.state]);

  useEffect(() => {
    loadingService.await(candidateService.updateLastPage('experience'));
    // eslint-disable-next-line
  }, [loadingService, candidateService]);

  const [tags, setTags] = useState<string[]>([]);
  const [taskTag, setTaskTag] = useState<string[]>([]);
  const [systemTags, setSystemTaskTag] = useState<string[]>([]);

  const initialVal: ExperienceProps = useMemo(() => {
    return {
      years_of_overall_experience: formData.years_of_overall_experience < 6 ? formData.years_of_overall_experience : 0 || 0,
      skills: [],
      roles: formData.roles || [],
    }
  }, [formData])

  const validationSchema = Yup.object().shape({
    years_of_overall_experience: Yup.number().positive("Years of experience is required").required("Years of experience is required"),
  });
  const handleSave = async (val: ExperienceProps): Promise<boolean> => {
    let res = await loadingService.await(candidateService.addWorkExperience(roles.filter((role) => {
      return role.role !== '' && role.years_of_experience !== '';
    })));
    if (res === null || res.status !== 'success') {
      return false;
    }

    res = await loadingService.await(candidateService.updateCandidateData({
      years_of_experience: val.years_of_overall_experience,
      task_expertise: taskTag.map((value) => value),
      system_expertise: systemTags.map((value) => value),
      industry_expertise: tags.map((value) => value),
    }));
    if (res === null || res.status !== 'success') {
      return false;
    }

    return true;
  }

  const handleSubmit = async (val: ExperienceProps) => {
    const res = await handleSave(val);
    if (res) {
      if (Object.keys(userData.candidateData).length !== 0) {
        const cData = (userData.candidateData);
        let paged: any
        try {
          paged = JSON.parse(cData.profile_pages);
        } catch (e) {
          paged = cData.profile_pages;
        }
        const updatePage = JSON.stringify({ ...paged, ...{ experience: 'complete' } })
        const updatePages = {
          ...cData,
          profile_pages: updatePage,
        }
        userData.setCandidateData(updatePages);
      }
      await loadingService.await(candidateService.updateProfilePages("experience", "complete", userData.candidateData));
      navigate('/finish', { state: { hideSkip, hideSaveAndExit } });
    } else {
      setErrorMessage("Something went wrong. Please try again later.");
    }
  }

  function handleDelete(tag: string) {
    setTags((prev) => prev.filter(exp => exp !== tag));
  }

  function handleAddition() {
    if (industryExpertiseInput.trim() !== '' && tags.find(exp => exp === industryExpertiseInput) === undefined) {
      setTags((prev) => [...prev, industryExpertiseInput]);
    } else {
      setIndustryExpTagValidationErrors('Please enter a valid industry expertise');
      setIndustryExpertiseInput('');
    }
    setIndustryExpertiseInput('');
  }

  function handleSystemDelete(tag: string) {
    setSystemTaskTag((prev) => prev.filter(exp => exp !== tag));
  }

  function handleSystemAddition() {
    if (systemExpertiseInput.trim() !== '' && systemTags.find(exp => exp === systemExpertiseInput) === undefined) {
      setSystemTaskTag((prev) => [...prev, systemExpertiseInput]);
      setSystemExpTagValidationErrors('')
    } else {
      setSystemExpTagValidationErrors('Please enter a valid system expertise');
      setSystemExpertiseInput('');
    }
    setSystemExpertiseInput('');
  }

  function addTaskExpertise() {
    if (taskExpertiseInput.trim() !== '' && taskTag.find(exp => exp === taskExpertiseInput) === undefined) {
      setTaskTag((prev) => [...prev, taskExpertiseInput]);
    } else {
      setTaskExpTagValidationErrors('Please enter a valid task expertise');
      setTaskExpertiseInput('');
    }
    setTaskExpertiseInput('');
  }

  function removeTaskExpertise(tag: string) {
    setTaskTag((prev) => prev.filter(exp => exp !== tag));
  }

  const yearsOfExperience = [{ value: '1', text: 'Less than 1 year' }, { value: '2', text: '1-2 Years' }, {
    value: '3',
    text: '3-4 Years',
  }, { value: '4', text: '5-10 Years' }, { value: '5', text: 'More than 10 years' }]

  const handleSaveAndExit = async (values: ExperienceProps) => {
    await handleSave(values);
    if (errorMessage === '') {
      await loadingService.await(candidateService.updateLastPage('experience'));
      localStorage.removeItem('masterData');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('login_form');
      await loadingService.await(Auth.signOut());
      navigate('/signIn?type=candidateSignIn');
    }
  }

  const handleSkip = async () => {
    setIsLoading(true)
    try {
      if (Object.keys(userData.candidateData).length !== 0) {
        const cData = (userData.candidateData);
        let paged: any
        try {
          paged = JSON.parse(cData.profile_pages);
        } catch (e) {
          paged = cData.profile_pages;
        }
        const updatePage = JSON.stringify({ ...paged, ...{ experience: 'skip' } })
        const updatePages = {
          ...cData,
          profile_pages: updatePage,
        }
        userData.setCandidateData(updatePages);
      }
      await loadingService.await(candidateService.updateProfilePages("experience", "skip", userData.candidateData));
      setIsLoading(false)
      navigate('/finish', { state: { hideSkip, hideSaveAndExit } });
    } catch (error :any) {
      setIsLoading(false);
      setErrorMessage(error.message)
    }
  }

  const handleBack = async () => {
    navigate('/qualification', { state: { hideSkip, hideSaveAndExit } });
  }
  const dragStartIndustry = (e: any, position: any) => {
    dragItem.current = position;
  };
  const dragEnterIndustry = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const dropIndustry = () => {

    const copyListItems = [...tags];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    setTags(copyListItems);
  };
  const touchIndustry = () => {

    const copyListItems = [...tags];
    if (dragItem.current != 0) {
      const dragItemContent = copyListItems[dragItem.current];
      const temp = copyListItems[dragItem.current - 1]
      copyListItems[dragItem.current - 1] = dragItemContent
      copyListItems[dragItem.current] = temp
    }
    setTags(copyListItems);
  };
  const dragStartSystem = (e: any, position: any) => {
    dragItem.current = position;
  };
  const dragEnterSystem = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const dropSystem = () => {
    const copyListItems = [...systemTags];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = 0;
    dragOverItem.current = 0;
    setSystemTaskTag(copyListItems);
  };
  const touchSystem = () => {
    const copyListItems = [...systemTags];
    if (dragItem.current != 0) {
      const dragItemContent = copyListItems[dragItem.current];
      const temp = copyListItems[dragItem.current - 1]
      copyListItems[dragItem.current - 1] = dragItemContent
      copyListItems[dragItem.current] = temp
    }
    setSystemTaskTag(copyListItems);
  };
  const dragStartTask = (e: any, position: any) => {
    dragItem.current = position;
  };
  const dragEnterTask = (e: any, position: any) => {
    dragOverItem.current = position;
  };

  const dropTask = () => {
    const copyListItems = [...taskTag];
    const dragItemContent = copyListItems[dragItem.current];

    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);

    dragItem.current = 0;
    dragOverItem.current = 0;
    setTaskTag(copyListItems);
  };
  const touchTask = () => {
    const copyListItems = [...taskTag];
    if (dragItem.current != 0) {
      const dragItemContent = copyListItems[dragItem.current];
      const temp = copyListItems[dragItem.current - 1]
      copyListItems[dragItem.current - 1] = dragItemContent
      copyListItems[dragItem.current] = temp
    }
    setTaskTag(copyListItems);
  };
  useEffect(() => {
    async function fetchData() {
      try {
        if (!removeSpinner.status) {
          setIsLoading(true);
        } else {
          setIsLoading(false);
        }
        const savedValues = await loadingService.await(candidateService.getCandidateDataT());
        userData.setCandidateData(savedValues);
        const savedWorkExperience = await loadingService.await(candidateService.getWorkExperience());
        setFormData((formData) => ({
          ...formData,
          years_of_overall_experience: savedValues?.years_of_experience ?? 0,
          roles: savedWorkExperience?.result ?? [],
        }));
        setTaskTag(savedValues?.task_expertise ?? []);
        setSystemTaskTag(savedValues?.system_expertise ?? []);
        setTags(savedValues?.industry_expertise ?? []);
      } catch (error :any) {
        setIsLoading(false);
        setErrorMessage(error.message)
      }
      setIsLoading(false);
    }

    fetchData();
    // eslint-disable-next-line
  }, [navigate]);

  return (
    <div>
      {isLoading && <center>
          <div><LoadingSpinner/></div>
      </center>}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <CustomLayout title={'Experience'} subTitle={'Tell us about your professional experience'}
                      pageName={'experience'} progressValue={60}>
          <ErrorBannerModal open={errorMessage !== ''} onClose={() => {
            setErrorMessage('')
          }} errorMessage={errorMessage}/>
          <Formik initialValues={initialVal} validationSchema={validationSchema} onSubmit={handleSubmit}
                  enableReinitialize>
            {({ values, setFieldValue, errors }) => (
              <Form>
                <>
                  <ScrollToFieldError/>
                  <div className={styles.contentQuestion}>
                    <h6 className={styles.widthLeft}>How many years of experience do you have in finance roles
                      overall?</h6>
                    <div className={styles.answerLength}>
                      {yearsOfExperience.map((text, index) => (
                        <div className={'form-check'} style={{ position: 'relative' }} key={ index }>
                          <input className="form-check-input" type="radio" name="years_of_overall_experience"
                                 id={`years_of_overall_experience${text.value}`} value={text.value}
                                 checked={`${values.years_of_overall_experience}` === text.value} onChange={(e) => {
                                   setFieldValue("years_of_overall_experience", parseInt(e.target.value));
                                 }}/>
                          <label className="form-check-label" htmlFor={`years_of_overall_experience${text.value}`}>
                            <h6 className={'text-normal gray-color-text'}>{text.text}</h6>
                          </label>
                        </div>
                      ))}
                      {errors.years_of_overall_experience && (
                        <div className={styles.yupError} style={{ marginTop: '-20px', position: 'relative' }}>
                          {errors.years_of_overall_experience}
                        </div>
                      )}
                    </div>
                  </div>

                  <WorkExperience
                    existingRoles={values.roles}
                    disabled={isLoading}
                    roleList={roleList}
                    getRoles={setRoles}
                    getCanSubmit={setAreRolesValid}
                  />

                  <h6 className={'gray-color-text mt-3 text-semi-bold mb-3'}>Please review and edit the Task, System and
                    Industry expertise we have uploaded from your resume or LinkedIn Profile. Start typing to add what
                    we already have as a tag or list your own. To ensure that the strongest area of expertise is rated
                    more highly, please ‘drag and drop’ the tags in each section to ensure that your top three areas
                    where you shine are at the top of the list </h6>
                  <div className={styles.contentQuestionSkill}>
                    <h6 className={styles.widthLeft}>Task expertise</h6>
                    <div className={styles.answerLength}>
                      <div>

                        <div className={styles.bottomContent}>
                          <div className={styles.searchBar}>
                            <CustomDropdown
                              placeHolder={'New task expertise'}
                              dataList={taskExpertiseSuggestions.filter((e) => matches(e, taskExpertiseInput)).map((e) => ({
                                text: e,
                                value: e,
                              }))}
                              onChange={(e: any) => {
                                setTaskExpertiseInput(e);
                                setTaskExpTagValidationErrors('')
                              }}
                              getSelectedItem={(item) => {
                                setTaskExpertiseInput(item.value)
                                setTaskExpTagValidationErrors('')
                              }}
                              selectedValue={{ text: taskExpertiseInput, value: taskExpertiseInput }}
                              sortDropdown={true}
                              />
                          </div>
                          <CustomButton text={'Add Task Expertise'} className={`mt-3 mt-md-0 ${styles.addButtonFixWidth}`}
                                        onClick={addTaskExpertise}
                                        disabled={isLoading || taskExpertiseInput.length === 0}/>
                        </div>
                        {taskExpTagValidationErrors.trim() !== '' && (
                          <div className={styles.yupError} style={{ marginTop: '-16px', position: 'relative', marginBottom: '22px' }}>
                            {taskExpTagValidationErrors}
                          </div>
                        )}
                        <div className={styles.tagBox} onDragOver={(e) => e.preventDefault()}>
                          {taskTag.length > 0 &&
                          <h6 className={'text-normal mt-3'}>Selected Skills</h6>}
                          <div className={'d-flex align-items-center w-100 flex-wrap'}>
                            {
                              taskTag.map((exp: string, index) => (
                                <div key={exp} className={`me-3 ${styles.payableTag}`} draggable={editable}>
                                     <div
                                     draggable={editable}
                                     onDragStart={(e) => dragStartTask(e, index)}
                                     onDragEnter={(e) => dragEnterTask(e, index)}
                                     onDragEnd={dropTask}
                                     onTouchStart={(e) => dragStartTask(e, index)}
                                     onTouchMove={(e) => dragEnterTask(e, index)}
                                     onTouchEnd={touchTask}
                                >
                                  {exp}
                          </div>
                                  <div className={styles.tagDelete} onClick={() => removeTaskExpertise(exp)}>
                                    <DefaultCloseIcon/>
                                  </div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div className={styles.contentQuestionSkill}>
                    <h6 className={styles.widthLeft}>System expertise</h6>
                    <div className={styles.answerLength}>
                      <div>

                        <div className={styles.bottomContent}>
                          <div className={styles.searchBar}>
                            <CustomDropdown
                              placeHolder={'New system expertise'}
                              dataList={systemExpertiseSuggestions.filter((e) => matches(e, systemExpertiseInput)).map((e) => ({
                                text: e,
                                value: e,
                              }))}
                              onChange={(e: any) => {
                                setSystemExpertiseInput(e);
                                setSystemExpTagValidationErrors('')
                              }}
                              getSelectedItem={(item) => {
                                setSystemExpertiseInput(item.value)
                                setSystemExpTagValidationErrors('')
                              }}
                              selectedValue={{ text: systemExpertiseInput, value: systemExpertiseInput }}
                              sortDropdown={true}
                              />
                          </div>
                          <CustomButton text={'Add System Expertise'} className={`mt-3 mt-md-0 ${styles.addButtonFixWidth}`}
                                        onClick={handleSystemAddition}
                                        disabled={isLoading || systemExpertiseInput.length === 0}/>

                        </div>
                        {systemExpTagValidationErrors.trim() !== '' && (
                          <div className={styles.yupError} style={{ marginTop: '-16px', position: 'relative', marginBottom: '22px' }}>
                            {systemExpTagValidationErrors}
                          </div>
                        )}
                        <div className={styles.tagBox} onDragOver={(e) => e.preventDefault()}>
                          {systemTags.length > 0 &&
                          <h6 className={'text-normal mt-3'}>Selected Skills</h6>}
                          <div className={'d-flex align-items-center w-100 flex-wrap'}>
                            {
                              systemTags.map((exp: string, index) => (
                                <div key={index} className={`me-3 ${styles.payableTag}`} draggable={editable}>
                                     <div
                                     draggable={editable}
                                     onDragStart={(e) => dragStartSystem(e, index)}
                                     onDragEnter={(e) => dragEnterSystem(e, index)}
                                     onDragEnd={dropSystem}
                                     onTouchStart={(e) => dragStartSystem(e, index)}
                                     onTouchMove={(e) => dragEnterSystem(e, index)}
                                     onTouchEnd={touchSystem}

                                >
                                  {exp}
                          </div>
                                  <div className={styles.tagDelete} onClick={() => handleSystemDelete(exp)}>
                                    <DefaultCloseIcon/></div>
                                </div>
                              ))
                            }
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                  <div className={styles.contentQuestion}>
                    <h6 className={styles.widthLeft}>Industry expertise</h6>
                    <div className={styles.answerLength}>
                      <div>
                        <div className={styles.bottomContent}>
                          <div className={styles.searchBar}>
                            <CustomDropdown
                              placeHolder={'New industry expertise'}
                              dataList={industryExpertiseSuggestions.filter((e) => matches(e, industryExpertiseInput)).map((e) => ({
                                text: e,
                                value: e,
                              }))}
                              onChange={(e: any) => {
                                setIndustryExpertiseInput(e);
                                setIndustryExpTagValidationErrors('')
                              }}
                              getSelectedItem={(item) => {
                                setIndustryExpertiseInput(item.value)
                                setIndustryExpTagValidationErrors('')
                              }}
                              selectedValue={{
                                text: industryExpertiseInput,
                                value: industryExpertiseInput,
                              }}
                              sortDropdown={true}
                              />
                          </div>
                          <CustomButton text={'Add Industry Expertise'} className={`mt-3 mt-md-0 ${styles.addButtonFixWidth}`}
                                        onClick={handleAddition}
                                        disabled={isLoading || industryExpertiseInput.length === 0}/>
                        </div>
                        {industryExpTagValidationErrors.trim() !== '' && (
                          <div className={styles.yupError} style={{ marginTop: '-16px', position: 'relative', marginBottom: '22px' }}>
                            {industryExpTagValidationErrors}
                          </div>
                        )}
                        <div className={styles.tagBox} onDragOver={(e) => e.preventDefault()}>
                          {tags.length > 0 &&
                          <h6 className={'text-normal mt-3'}>Selected Skills</h6>}
                          <div className={'d-flex align-items-center w-100 flex-wrap'}>
                            {
                              tags.map((exp, index) => (
                                <div key={index} className={`me-3 ${styles.payableTag}`} draggable={"true"}>
                                     <div
                                     draggable={"true"}
                                     onDragStart={(e) => dragStartIndustry(e, index)}
                                     onDragEnter={(e) => dragEnterIndustry(e, index)}
                                     onDragEnd={dropIndustry}
                                     onTouchStart={(e) => dragStartIndustry(e, index)}
                                     onTouchMove={(e) => dragEnterIndustry(e, index)}
                                     onTouchEnd={touchIndustry}

                                >
                                  {exp}
                          </div>
                                  <div className={styles.tagDelete} onClick={() => handleDelete(exp)}>
                                    <DefaultCloseIcon/></div>
                                </div>
                              ))
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={'d-flex justify-content-between align-items-center mt-5'}>
                    <div className={'d-flex align-items-center'}>
                      <CustomButton disabled={isLoading} type={'button'} text={'Go Back'} icon={<LeftArrow/>}
                                    iconSide={'left'} className={styles.whiteBtn} onClick={handleBack}/>
                      {!hideSaveAndExit && (
                        <CustomButton
                          disabled={isLoading || errorMessage !== "" || !areRolesValid || errors.years_of_overall_experience === "Years of experience is required"}
                          type={'button'} text={'Save & Exit'} icon={<SaveIcon/>} iconSide={'left'}
                          className={`ms-3 ${styles.outlineBtn}`} onClick={() => handleSaveAndExit(values)}/>
                      )}
                    </div>
                    <div className={'d-flex align-items-center'}>
                      {!hideSkip && (
                        <CustomButton disabled={isLoading} type={'button'} text={'Skip for Now'}
                                      className={styles.whiteBtn} onClick={handleSkip}/>
                      )}
                      <CustomButton loading={isLoading}
                                    disabled={isLoading || errorMessage !== "" || !areRolesValid || errors.years_of_overall_experience === "Years of experience is required"}
                                    type={'submit'} text={'Continue'} icon={<RightLongArrowIcon size={14}/>}
                                    iconSide={'right'}/>
                    </div>
                  </div>
                </>
              </Form>
            )}
          </Formik>
        </CustomLayout>
      </div>
    </div>
  )
}

export default Experience
