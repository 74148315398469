import React from 'react';
interface Props {
  size?: number
  color?: string
}

const OverviewSearchIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 17}
      height={size ?? 21}
      fill="none"
      viewBox={'0 0 17 21'}
    >
      <path
        stroke={color ?? "#1F1F1F"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M1 19v-2a4 4 0 0 1 4-4h1m7.5 4.5L16 20M11 5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm3 10.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      />
    </svg>
  )
}

export default OverviewSearchIcon
