import axios from "axios";
import { Urls as URLS } from "../../context/Urls";

export default class HttpService {
  public async sendGetRequest<ResponseType>(endPoint: string, userToken?: string): Promise<ResponseType | any> {
    let res;
    try {
      res = await axios.get(URLS.APP_BASE_URL + '/' + endPoint, {
        headers: userToken ? {
          Authorization: `Bearer ${userToken}`,
        } : {},
      });
      return res.data;
    } catch (err: any) {
      throw new Error(err?.response?.data?.message ?? 'Something went wrong. Please contact admin')
    }
  }

  public async sendPostRequest<ResponseType>(endPoint: string, userToken?: string, body?: object | null): Promise<ResponseType | null> {
    let res;
    try {
      res = await axios.post(URLS.APP_BASE_URL + '/' + endPoint, body ?? null, {
        headers: userToken ? {
          Authorization: `Bearer ${userToken}`,
        } : {},
      });
      return res.data;
    } catch (err: any) {
      return err;
    }
  }
}
