import React from 'react';

interface Props {
  size?: number
  color?: string
}

const WalkingManIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 14}
      height={size ?? 20}
      fill="none"
      viewBox={'0 0 14 20'}
    >
      <path
        d="m2 19 3-4m6 4-2-4-3-3 1-6m0 0L3 7l-2 3m6-4 3 3 3 1M9 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z"
        stroke={color ?? "#636363"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default WalkingManIcon
