import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import { Form, Formik } from "formik";
import FileUpload from "../shared/FileUpload/fileUpload";
import ResumeIcon from "../../icons/resume.icon";
import LinkedInIcon from "../../icons/linkedInIcon";
import InfoIcon from "../../icons/info.icon";
import CustomLayout from "../layout/commonLayout";
import { useNavigate } from 'react-router';
import CustomButton from '../shared/CustomButton/customButton';
import LeftArrow from '../../icons/leftArrow.icon';
import RightLongArrowIcon from '../../icons/rightLongArrow.icon';
import SaveIcon from '../../icons/save.icon';
import { UploadedFile } from '../../services/file/file.model'
import FileService from '../../services/file/file.service';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import UserService from '../../services/user/user.service';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingService from '../../services/loading/loading.service';
import RecycleBinIcon from "../../icons/recycleBinIcon";
import { Auth } from 'aws-amplify';
import { CandidateService } from '../../services/candidate/candidate.service';
import { useLocation } from 'react-router-dom';
import { UserContext } from '../../context'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

export type ResumeImportProps = {
  resume?: UploadedFile;
  linkedIn?: UploadedFile;
};


function ResumeImport() {
  const userData = useContext(UserContext);
  const navigate = useNavigate();
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const location = useLocation();

  const [isLoading, setIsLoading] = useState(true);
  const [initialVal, setInitialVal] = useState<ResumeImportProps>({});
  const [hideSkip, setHideSkip] = useState(false);
  const [hideSaveAndExit, setHideSaveAndExit] = useState(false);

  const fileService: FileService = useMemo(() => {
    return new FileService();
  }, []);
  const userService: UserService = useMemo(() => {
    return new UserService();
  }, []);
  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  useEffect(() => {
    if (location.state !== null) {
      if (location.state.hideSkip) {
        setHideSkip(location.state.hideSkip);
      }
      if (location.state.hideSaveAndExit) {
        setHideSaveAndExit(location.state.hideSaveAndExit);
      }
    }
  }, [location.state]);

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await candidateService.getResume();
        if (data !== null && data.result !== null &&  data.result.key !== "no_key_found") {
          if (data.result.key.substring(0, 7) === 'resumes') {
            setInitialVal({
              resume: await fileService.readBackendFile(data.result) ?? undefined,
              linkedIn: undefined,
            });
          } else {
            setInitialVal({
              resume: undefined ,
              linkedIn: await fileService.readBackendFile(data.result) ?? undefined,
            });
          }

        }
      } catch (error: any) {
        setErrorModel(true)
        setErrorMessage(error.message)
      }
    }
    loadingService.await(userService.isLoggedIn()).then(res => {
      if (!res) {
        navigate('/signIn?type=candidateSignIn');
      }
    });
    loadingService.await(candidateService.updateLastPage('resumeImport')).then();
    loadingService.await(fetchData()).then();
  }, [navigate, userService, fileService, loadingService, candidateService, userData.userData]);

  // Upload document to backend.
  async function uploadDocument({ file, fileType }: { file: UploadedFile, fileType: string }): Promise<any> {
    const res = await candidateService.extractData(file, fileType);
    return res;
  }

  function setFileValue(key: string, value: UploadedFile, setFieldValue: (key: string, value: UploadedFile) => void, setFieldError: (key: string, value: string) => void) {
    if (value.original) {
      if (value.original.size > 10 * 1024 * 1024) {
        setFieldError(key, 'File is larger than 10MB.');
        return;
      }
    }
    setFieldValue(key, value);
  }

  function handleBack() {
    navigate('/gettingStart', { state: { hideSkip, hideSaveAndExit } });
  }

  async function handleSave(values: ResumeImportProps, setFieldError: (key: string, value: string) => void): Promise<boolean> {
    if (loadingService.isLoading()) {
      return false;
    }
    if (values.resume) {
      const res =   (await loadingService.await(uploadDocument({ file: values.resume, fileType: 'resume' })));
      if (res.status !== 'success') {
        if (res?.response.data?.statusCode === 406) {
          setFieldError('resume', res.response.data.message);
          return false;
        } else {
          setFieldError('resume', 'Could not extract data.');
          return false;
        }

      }
    }
    if (values.linkedIn) {
      const res =   (await loadingService.await(uploadDocument({ file: values.linkedIn, fileType: 'linkedIn' })))
      if (res.status !== 'success') {
        if (res?.response.data?.statusCode === 406) {
          setFieldError('resume', res.response.data.message);
          return false;
        } else {
          setFieldError('resume', 'Could not extract data.');
          return false;
        }

      }

    }
    return true;
  }

  async function handleSaveAndExit(values: ResumeImportProps, setFieldError: (key: string, value: string) => void) {
    if (await handleSave(values, setFieldError)) {
      localStorage.removeItem('masterData');
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('tokenExpiration');
      localStorage.removeItem('login_form');
      await loadingService.await(Auth.signOut());
      navigate('/signIn?type=candidateSignIn');
    }
  }

  async function handleSkip() {
    try {
      if (Object.keys(userData.candidateData).length !== 0) {
        const cData = (userData.candidateData);
        let paged: any
        try {
          paged = JSON.parse(cData.profile_pages);
        } catch (e) {
          paged = cData.profile_pages;
        }
        const updatePage = { ...paged, ...{ resumeImport: 'skip' } }
        const updatePages = {
          ...cData,
          profile_pages: updatePage,
        }
        userData.setCandidateData(updatePages);
      }
      await candidateService.updateProfilePages("resumeImport", "skip",userData.candidateData)
      navigate('/qualification', { state: { hideSkip, hideSaveAndExit } });
    } catch (error :any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  async function handleSubmit(values: ResumeImportProps, { setFieldError }: { setFieldError: (field: string, message: string | undefined) => void }) {
    try {
      if (!values.resume && !values.linkedIn) {
        setFieldError('linkedIn', 'Please upload a document.');
        return;
      }
      if (!(await handleSave(values, setFieldError))) {
        return;
      }
      if (Object.keys(userData.candidateData).length !== 0) {
        const cData = (userData.candidateData);
        let paged: any
        try {
          paged = JSON.parse(cData.profile_pages);
        } catch (e) {
          paged = cData.profile_pages;
        }
        const updatePage = JSON.stringify({ ...paged,... {  resumeImport: 'complete' } })
        const updatePages = {
          ...cData,
          profile_pages:updatePage,
        }
        userData.setCandidateData(updatePages);
      }

      await loadingService.await(candidateService.updateProfilePages("resumeImport", "complete",userData.candidateData));
      navigate('/qualification', { state: { hideSkip, hideSaveAndExit } });
    } catch (error :any) {
      console.log("ddddddd",error)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  return (
    <div>
    {isLoading && <LoadingSpinner/>}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <CustomLayout title={''} subTitle={'Import your qualifications & experience from your resume or LinkedIn'} pageName={'resumeImport'} progressValue={20}>
          <Formik initialValues={initialVal} enableReinitialize={true} onSubmit={handleSubmit}>
            {({ values, setFieldValue, errors, setFieldError, submitForm }) => (
              <Form>
                <div className={`mt-5 ${styles.resumeContainer}`}>
                  <div className={styles.resumeUploadBox}>
                    <div className={'d-flex mb-3'}><ResumeIcon/>
                      <h6 className={'ms-3 text-bold gray-color-text'}>Resume</h6>
                    </div>
                    <div className={styles.widthUpload}>
                      <FileUpload disabled={isLoading} id={'resume'} filedText={'Select file from your computer'} fileSelect={(file: File | null) => {
                        if (file !== null) fileService.readFile(file).then(value => setFileValue('resume', value, setFieldValue, setFieldError));
                      }}/>
                      <h6 className={`gray-color-text  mt-2 ${styles.borderBottom}`}>Supported file type: .pdf</h6>
                    </div>
                    {values.resume && (
                      <div className={styles.selectedResume}>
                        <h6 className={'ms-3 text-bold gray-color-text'}>Selected File</h6>
                        <div className={`d-flex ${styles.borderTop}`}>
                          <div className={'d-flex justify-content-between align-items-center w-100'}>
                            <h6 className={'text-normal gray-color-text d-flex'}><span className={styles.elipse}>{values.resume.name}</span> <span hidden={isLoading} className={styles.link} onClick={() => fileService.openFile(values.resume)}>View File</span></h6>
                         <div className={'pointer'} onClick={()=>setFieldValue('resume', '')}>
                          <RecycleBinIcon/>
                         </div>
                          </div>
                          </div>
                      </div>
                    )}
                    {errors.resume && (
                      <div className={styles.error}>{ errors.resume }</div>
                    )}
                  </div>
                  <div className={styles.heightDivideBox}>
                    <div className={styles.heightDivider}/>
                  <p className={'ms-2 me-2 d-flex align-items-center placeHolder-color'}>or</p>
                    <div className={styles.heightDivider}/>
                  </div>
                  <div className={styles.resumeUploadBox}>
                    <div className={'d-flex mb-3'}><LinkedInIcon/>
                      <h6 className={'ms-3 text-bold gray-color-text'}>LinkedIn Profile</h6>
                    </div>
                    <div className={styles.widthUpload}>
                      <FileUpload disabled={isLoading} id={'linkedln'} filedText={'Select file from your computer'} fileSelect={(file: File | null) => {
                        if (file !== null) fileService.readFile(file).then(value => setFileValue('linkedIn', value, setFieldValue, setFieldError));
                      }}/>
                      <h6 className={`gray-color-text  mt-2 ${styles.borderBottom}`}>Supported file type: .pdf</h6>
                    </div>
                    <div className={styles.infoBox}>
                      <InfoIcon/>
                      <p className={'ms-3 info-text w-90'}>As an alternative to your resume, LinkedIn has a helpful feature that can save your LI Profile as a pdf (assuming you have been keeping your LI profile up to date!) It's easy to save your Profile as a pdf: Click the ...More icon in the top section of your Profile, click Save to PDF and then upload it here</p>
                    </div>
                    {values.linkedIn && (
                      <div className={styles.selectedResume}>
                        <p className={'ms-3 text-bold'}>Selected File</p>
                        <div className={`d-flex ${styles.borderTop}`}>
                          <div className={'d-flex justify-content-between align-items-center w-100'}>
                            <h6 className={`text-normal gray-color-text d-flex justify-content-between w-100 `}><span className={styles.elipse}>{values.linkedIn.name}</span> <span hidden={isLoading} className={styles.link} onClick={() => fileService.openFile(values.linkedIn)}>View File</span></h6>
                          </div>
                            <div className={'pointer ms-3'} onClick={()=>setFieldValue('linkedIn', '')}>
                              <RecycleBinIcon/>
                            </div>

                          </div>
                      </div>
                    )}
                    {errors.linkedIn && (
                      <div className={styles.error}>{ errors.linkedIn }</div>
                    )}
                  </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center mt-5'}>
                  <div className={'d-flex align-items-center'}>
                    <CustomButton disabled={isLoading} type={'button'} text={'Go Back'} icon={<LeftArrow/>} iconSide={'left'} className={styles.whiteBtn} onClick={handleBack}/>
                    { !hideSaveAndExit && (
                      <CustomButton disabled={isLoading} type={'submit'} text={'Save & Exit'} icon={<SaveIcon/>} iconSide={'left'} className={`ms-3 ${styles.outlineBtn}`} onClick={() => handleSaveAndExit(values, setFieldError)}/>
                    )}
                  </div>
                  <div className={'d-flex align-items-center'}>
                    { !hideSkip && (
                      <CustomButton disabled={isLoading} type={'button'} text={'Skip for Now'} className={styles.whiteBtn} onClick={handleSkip}/>
                    )}
                    <CustomButton loading={isLoading} type={'submit'} text={'Continue'} icon={<RightLongArrowIcon size={14}/>} iconSide={'right'} onClick={submitForm}/>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CustomLayout>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default ResumeImport
