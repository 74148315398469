import React, { useMemo, useState } from 'react'
import HttpService from "../../../services/http/http.service";
import CustomDropdown from "../CustomDropdown/customDropdown";


type CustomSearchProps = {
  name?: string;
  defaultValue?: string;
  onChange: (newValue: string) => void;
  endPoint: (input: string) => string;
  placeholder?: string;
  timeout?: number;
  mapResults?: (res: any) => string[];
  minChars?: number;
  forcedValue?: string;
  place?: boolean;
  isLocationSearch?:boolean
};

type DropdownDataList = {
  text: string;
  value: string;
};

let lastRequest: NodeJS.Timeout | null;

export default function CustomSearch({ name, defaultValue = '', onChange, endPoint, placeholder = 'Search...', timeout = 0, mapResults = res => res, minChars = 0, forcedValue, place, isLocationSearch = false }: CustomSearchProps) {
  const [values, setValues] = useState<DropdownDataList[]>([]);
  const [value, setValue] = useState<string | null>(null);
  const httpService: HttpService = useMemo(() => {
    return new HttpService();
  }, []);

  function handleChange(value: string) {
    onChange(value);
    setValue(value);
    if (value.length < minChars) {
      setValues([]);
      return;
    }
    if (lastRequest) {
      clearTimeout(lastRequest);
    }
    lastRequest = setTimeout(async () => {
      const res = await httpService.sendGetRequest(endPoint(value),localStorage.getItem('token') ?? "") || [];
      setValues(mapResults(res).map(function (data: any): DropdownDataList {
        return {
          text: place ? data.address : data,
          value: place ? data.address : data,
        };
      }));
    }, timeout ?? 0);
  }
  return (
    <CustomDropdown name={name} placeHolder={placeholder} onChange={handleChange} dataList={values} getSelectedItem={(item: DropdownDataList) => { setValue(item.value); onChange(item.value) }}
                    selectedValue={{ text: forcedValue !== null && forcedValue !== undefined ? forcedValue : value || defaultValue,
                      value: forcedValue !== null && forcedValue !== undefined ? forcedValue : value || defaultValue }} isLocationSearch={isLocationSearch} />
  )
}
