import React from 'react';

interface Props {
  size?: number
  color?: string
}

const QuestionMark: React.FC<Props> = (props) => {
  return (
    <svg
      xmlns="
http://www.w3.org/2000/svg"
      width={props.size ?? 24}
      height={props.size ?? 24}
      fill="none"
      {...props}
      viewBox={"0 0 24 24"}
    >
      <g fill={props.color ?? "#D9D9D9"} clipPath="url(#a)">
        <path d="M12 0a12 12 0 1 0 12 12A12.013 12.013 0 0 0 12 0Zm0 22a10 10 0 1 1 10-10 10.011 10.011 0 0 1-10 10Z" />
        <path d="M12.717 5.063A4 4 0 0 0 8 9a1 1 0 0 0 2 0 2 2 0 0 1 3.414-1.414c.285.284.48.647.557 1.042a2 2 0 0 1-1 2.125 3.954 3.954 0 0 0-1.97 3.504V15a1 1 0 0 0 2 0v-.743a1.982 1.982 0 0 1 .93-1.752 4 4 0 0 0-1.214-7.442ZM13 18a1 1 0 1 0-2 0 1 1 0 0 0 2 0Z" />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default QuestionMark


