import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from './Profile.module.scss'
import EditIcon from "../../icons/edit.icon";
import CustomButton from "../shared/CustomButton/customButton";
import DegreeCapIcon from "../../icons/degreeCap.icon";
import DegreeIcon from "../../icons/degree.icon";
import CalenderIcon from "../../icons/calender.icon";
import RecycleBinIcon from "../../icons/recycleBinIcon";
import CustomModal from "../shared/customModal/customModal";
import AddEducationModal from "../qualification/addEducationModal";
import PlusIcon from "../../icons/plus.icon";
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingService from '../../services/loading/loading.service';
import { CandidateService } from '../../services/candidate/candidate.service';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';
import colors from '../../styles/variables.module.scss'
import { MasterDataContext } from '../../context/masterData'
import dayjs from 'dayjs';
export type Qualification = {
  id: string;
  qualification: string;
  institution: string;
  field_of_study: string;
  start_date: Date;
  end_date: Date;
};

export type EducationProfileProps = {
  pageIsLoading: boolean;
  onUpdate: () => void;
};

function EducationProfile({ pageIsLoading, onUpdate }: EducationProfileProps) {
  const masterData = useContext(MasterDataContext);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [qualifications, setQualifications] = useState<Qualification[]>([]);
  const [currentQualification, setCurrentQualification] = useState<Qualification | null>(null);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [qualificationLevels, setQualificationLevels] = useState<any[]>([]);
  const [errorModel, setErrorModel] = useState(false)
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  useEffect(() => {
    if (masterData?.masterData) {
      setQualificationLevels(masterData.masterData.filter((fd:any)=> fd.category === 'qualification').sort((a: any, b: any) => a.order - b.order));
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }

  }, [masterData]);

  useEffect(() => {
    loadingService.await(candidateService.getQualifications()).then(async (data) => {
      if (data !== null && data.status === 'success') {
        if (masterData && masterData.masterData) {
          const rank = masterData.masterData.filter((a: any) => a?.category?.toString()?.endsWith('rank')).map((d: any) => {
            return {
              ...d,
              qualification: d.category.split('_')[0],
            }
          });
          /**
           * Array of qualifications data with start_date and end_date properties converted to Date objects.
           * @type {Array<Qualification>}
           */
          const qualificationsData: Array<Qualification> = data.result.map((q: any) => ({
            ...q,
            start_date: new Date(q.start_date),
            end_date: new Date(q.end_date),
          }));

          qualificationsData.map((q: any) => {
            q.sortValue = rank.filter((fd: any) => fd.qualification === q.qualification)[0]?.value ?? 0;
          });

          qualificationsData.sort((a: any, b: any) => b.sortValue - a.sortValue);

          qualificationsData.forEach((q: any) => {
            q.certification = masterData.masterData.filter((fd: any) => fd.category === 'licences_and_certifications').filter((fd: any) => fd.id === q.certification)[0]?.value ?? q.certification;
          });

          setQualifications(qualificationsData);
        }
      }
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [candidateService, loadingService, masterData, updateFlag]);

  function handleUpdate() {
    onUpdate();
    setUpdateFlag(!updateFlag);
  }

  function editQualification(id: string) {
    setCurrentQualification(qualifications.find(q => q.id === id) ?? null);
    setOpen(true);
  }

  async function removeQualification(id: string) {
    const res = await loadingService.await(candidateService.removeQualifications([{ id }]));
    if (res !== null && res.status === 'success') {
      handleUpdate();
    } else {
      setErrorMessage('Something went wrong, please try again later')
    }
  }

  async function onModalSubmit(data: Qualification) {
    let res;
    if (currentQualification) {
      res = await loadingService.await(candidateService.updateQualification({
        id: currentQualification.id,
        qualification: data.qualification,
        institution: data.institution,
        field_of_study: data.field_of_study,
        start_date: data.start_date,
        end_date: data.end_date,
      }));
    } else {
      res = await loadingService.await(candidateService.addQualifications([{
        qualification: data.qualification,
        institution: data.institution,
        field_of_study: data.field_of_study,
        start_date: data.start_date,
        end_date: data.end_date,
      }]));
    }
    if (res !== null && res.status === 'success') {
      handleUpdate();
    } else {
      setErrorMessage('Something went wrong, please try again later')
    }
  }

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      <ErrorBannerModal open={errorMessage !== ''} onClose={() => {
        setErrorMessage('');
      }} errorMessage={errorMessage} />
      <div className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}>
        <div className={'d-flex align-items-center'}>
          <DegreeCapIcon color={colors.blackColor}/>
          <h4 className={'text-almostBlack ms-3'}>Australian Education</h4>
        </div>
        <CustomButton disabled={isLoading || pageIsLoading} text={'Add Education'} className={styles.addBtn} onClick={() => { setCurrentQualification(null); setOpen(true); }} variant={'transparent'} icon={<PlusIcon/>} iconSide={'left'}/>
      </div>
      {
        qualifications.map((qualification, index) => (
          <div className={styles.educationCard} key={index}>
          <div className={'d-flex'}>
          <div className={'d-flex align-items-center'}>
            <DegreeIcon/>
          </div>
          <div className={'ms-4'}>
            <h6 className={'text-bold gray-color-text'}>{ qualificationLevels?.filter((fd:any)=>fd.id === qualification.qualification.toLowerCase())[0]?.value ?? qualification.qualification}</h6>
            <h6 className={'text-normal text font-size-semi gray-color-text mt-2'}>{qualification.field_of_study}</h6>
            <h6 className={'text-normal text font-size-semi gray-color-text mt-2'}>{qualification.institution}</h6>
            <div className={'d-flex mt-2'}>
              {new Date(qualification.start_date).getFullYear() !== 1970 || new Date(qualification.end_date).getFullYear() !== 1970 ? (
                <>
                  <CalenderIcon/>
                    <h6 className={'ms-2 gray-color-text'}>
                      {new Date(qualification.start_date).getFullYear() === 1970
                        ? dayjs(qualification.end_date).format('MMM YYYY') :
                        new Date(qualification.end_date).getFullYear() === 1970 ?
                          dayjs(qualification.start_date).format('MMM YYYY') :
                          `${dayjs(qualification.start_date).format('MMM YYYY')} - ${dayjs(qualification.end_date).format('MMM YYYY')}`}                      </h6>
                </>
              ) : (
                <></>
              )}
            </div>
          </div>
          </div>
          <div className={'d-flex'}>
            <div onClick={() => { if (!isLoading && !pageIsLoading) removeQualification(qualification.id) }}>
              <RecycleBinIcon/>
            </div>
            <div onClick={() => { if (!isLoading && !pageIsLoading) editQualification(qualification.id) }} className={'ms-5'}>
              <EditIcon/>
            </div>
          </div>
        </div>
        ))
      }
      {open &&
        <CustomModal open={open} onCloseModal={(val) => {setOpen(val)}} title={currentQualification != null ? 'Edit Education' : 'Add New Education'}>
            <AddEducationModal qualification={currentQualification} onSubmit={onModalSubmit} closeModal={() => {setOpen(false)}} />
        </CustomModal>
      }
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default EducationProfile
