import React from 'react';

interface Props {
  size?: number
  color?: string
}

const PersonalInfoIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 14}
      height={size ?? 20}
      fill="none"
      viewBox={'0 0 14 20'}
    >
      <path
        d="M1 19v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2M11 5a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        stroke={color ?? "#1F1F1F"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PersonalInfoIcon
