import React, { forwardRef, useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'

import styles from './calender.module.scss'
import DownArrowIcon from "../../../icons/downArrow.icon";
import colors from '../../../styles/variables.module.scss'
import CalenderIcon from "../../../icons/calender.icon";

type Props = { value?: string; onClick?: () => void }
export type Ref = HTMLButtonElement

type CalendarProps = {
  SelectYear?: Date
  minDate?: Date
  maxDate?: Date
  label?: string
  getSelectedYear?: (selectedDate: Date) => void
  isDisabled?: boolean
  dateSelect?:boolean
  icon?:boolean
}

function Calender({ SelectYear, minDate, maxDate, label, getSelectedYear, isDisabled ,dateSelect,icon }: CalendarProps) {
  const [startDate, setStartDate] = useState(SelectYear )

  useEffect(() => {
    setStartDate(SelectYear )
  }, [SelectYear])

  const ExampleCustomInput = forwardRef<Ref, Props>(({ value, onClick }, ref) => (
    <button type="button" className={styles.button} onClick={onClick} ref={ref}>
      <div >
        {icon &&
          <div className={'me-3'}>
            <CalenderIcon/>
          </div>
        }
        {label && (
          <p className={['light-text', styles.outward].join(' ')} style={{ lineHeight: 1 }}>
            {label}
          </p>
        )}
        <p className={['black-text', styles.dateText].join(' ')} style={{ lineHeight: 1 }}>
          {value}
        </p>
      </div>
      <DownArrowIcon color={colors.textLightColor}/>
    </button>
  ))
  ExampleCustomInput.displayName = 'ExampleCustomInput'

  const handleFatePick = (date: Date) => {
    setStartDate(date)
    getSelectedYear?.(date)
  }

  return (
    <>
      <div className={styles.calender}>
        {!dateSelect ?
          <DatePicker
            className="react-datepicker"
            selected={startDate}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat="yyyy"
            onChange={handleFatePick}
            customInput={<ExampleCustomInput/>}
            disabled={isDisabled}
            showYearPicker
          />
          :
          <DatePicker
            className="react-datepicker"
            selected={startDate}
            minDate={minDate}
            maxDate={maxDate}
            // dateFormat=""
            onChange={handleFatePick}
            customInput={<ExampleCustomInput/>}
            disabled={isDisabled}
          />
        }
      </div>

    </>
  )
}

export default Calender