import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import { Formik, Form, ErrorMessage } from "formik";
import CustomInputField from "../shared/customInputField/CustomInputField";
import CustomButton from "../shared/CustomButton/customButton";
import RecycleBinIcon from "../../icons/recycleBinIcon";
import LoadingService from '../../services/loading/loading.service';
import { CandidateService } from '../../services/candidate/candidate.service';
import * as Yup from 'yup';
import 'yup-phone';
import CustomDropdown from '../shared/CustomDropdown/customDropdown';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';

type Referee = {
  id?: string;
  name: string;
  email: string;
  phone: string;
  relationship: string;
  organization: string;
};
import { MasterDataContext } from '../../context/masterData'

type AddReferenceModalProps = {
  referees: (Referee & { status: string })[];
  currentIndex: number | null;
  closeModal: () => void;
  updateReferees: () => void;
};

function AddReferenceModal({ referees, currentIndex, closeModal, updateReferees }: AddReferenceModalProps) {
  const masterData = useContext(MasterDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [relationshipOptions, setRelationshipOptions] = useState<any[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const loadingService: LoadingService = useMemo(() => new LoadingService(setIsLoading), []);
  const candidateService: CandidateService = useMemo(() => new CandidateService(), []);

  useEffect(() => {
    if ( masterData?.masterData) {
      setRelationshipOptions( masterData.masterData.filter((fd:any)=> fd.category === 'referee_relationship').sort((a: any, b: any) => a.order - b.order));
    } else {
      if (masterData.isError) {
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }

  }, [masterData]);

  const validationSchema = Yup.object({
    name: Yup.string().required('Referee name is required')
      .test('unique', 'Referee name already exists', (value) => !(referees.filter((ref, index) => index !== currentIndex && ref.name === value).length > 0)),
    email: Yup.string().required('Referee email is required').email('Invalid email address')
      .test('unique', 'Referee email already exists', (value) => !(referees.filter((ref, index) => index !== currentIndex && ref.email === value).length > 0)),
    phone: Yup.string().required('Referee phone is required').phone('AU', false, 'Please enter a valid phone number.')
      .test('unique', 'Referee phone already exists', (value) => !(referees.filter((ref, index) => index !== currentIndex && ref.phone === value).length > 0)),
    relationship: Yup.string().required('Referee relationship is required'),
    organization: Yup.string().required('Referee organisation is required'),
  });

  const initialVal = useMemo(() => {
    return {
      name: currentIndex !== null ? referees[currentIndex].name : '',
      email: currentIndex !== null ? referees[currentIndex].email : '',
      phone: currentIndex !== null ? referees[currentIndex].phone : '',
      relationship: currentIndex !== null ? referees[currentIndex].relationship : '',
      organization: currentIndex !== null ? referees[currentIndex].organization : '',
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function handleSubmit(
    values: Referee,
    { setFieldValue }: { setFieldValue: <value>(field: string, value: value) => void },
  ) {
    if (currentIndex !== null) {
      const params: Partial<Referee> = {
        id: referees[currentIndex].id,
        name: values.name,
        email: values.email,
        phone: values.phone,
        relationship: values.relationship,
        organization: values.organization,
      };
      if (referees[currentIndex].status === 'posted_comment') {
        delete params.email;
      }
      const res = await loadingService.await(candidateService.updateReferee(params));
      if (res !== null && res.status === 'success') {
        updateReferees();
        closeModal();
      } else if (res !== null && res.status === 'error' && res.refereeStatus === 'posted_comment') {
        referees[currentIndex].status = 'posted_comment';
        setFieldValue('email', referees[currentIndex].email);
        if (values.email !== referees[currentIndex].email) {
          setErrorMessage('This referee has already posted a comment. You cannot now update their email.');
        } else {
          setErrorMessage('Something went wrong. Please try again later.');
        }
      } else {
        setErrorMessage('Something went wrong. Please try again later.');
      }
    } else {
      const res = await loadingService.await(candidateService.insertReferee(values));
      if (res !== null && res.status === 'success') {
        updateReferees();
        closeModal();
      } else {
        setErrorMessage('Something went wrong. Please try again later.')
      }
    }
  }

  return (
    <>
    <div className={`${isLoading && loadingStyles.app_while_loading}`}/>
      <ErrorBannerModal open={errorMessage !== ''} onClose={() => setErrorMessage('')} errorMessage={errorMessage} />
      <Formik initialValues={initialVal} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={validationSchema} >
        {({ values, setFieldValue, errors }) => (
          <Form className={styles.modal}>
            <div className={styles.borderBottomLight}>
              <CustomInputField name={'name'} placeholder={'Full Name *'} className={'mt-4'} disabled={isLoading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('name', e.target.value)} />
              <CustomInputField name={'email'} placeholder={'Email *'} className={'mt-3'}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('email', e.target.value)}
                disabled={isLoading || (currentIndex !== null && referees[currentIndex].status === 'posted_comment')} />
              <CustomInputField name={'phone'} placeholder={'Phone *'} className={'mt-3 mb-3'} disabled={isLoading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('phone', e.target.value)} />
              <CustomInputField name={'organization'} placeholder={'Organisation *'} className={'mt-3'} disabled={isLoading}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setFieldValue('organization', e.target.value)} />
              <div className={'mt-3'}>
              <CustomDropdown dropUp name={'relationship'} placeHolder={'Relationship *'} dataList={relationshipOptions.map((v) => ({ value: v.id, text: v.value }))}
                disabled={isLoading}
                getSelectedItem={(item) => setFieldValue('relationship', item.value)}
                selectedValue={relationshipOptions.filter((fd)=> fd.id === values?.relationship).map((md:any)=>({ text: md.value, value: md.id }))[0] } />
              {errors.relationship && (
                <div className={'text-danger'}><ErrorMessage name={'relationship'} /></div>
              )}
              </div>
            </div>
              <div className={`mt-5 d-flex justify-content-between align-items-center`}>
                <CustomButton className={styles.discardBTn} text={'Discard'} icon={<RecycleBinIcon />} disabled={isLoading} onClick={closeModal} />
                <CustomButton className={styles.widthBtn} text={currentIndex === null ? 'Add Referee' : 'Update Referee'} disabled={isLoading || Object.keys(errors).length > 0} type={'submit'} />
              </div>

          </Form>
        )}
      </Formik>
      </>

  )
}

export default AddReferenceModal
