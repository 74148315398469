import React from 'react';

interface Props {
  size?: number
  color?: string
}

const ResumeIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 16}
      height={size ?? 20}
      fill="none"
    >
      <path
        d="M10 1v4a1 1 0 0 0 1 1h4m-5-5H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V6m-5-5 5 5M5 7h1m-1 4h6m-6 4h6"
        stroke={color ?? "#9CA3AF"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ResumeIcon
