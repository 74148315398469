import React from 'react';

interface Props {
  size?: number
  color?: string
}

const CertificateProfileIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 12}
      height={size ?? 18}
      fill="none"
     viewBox={'0 0 12 18'}
    >
      <path
        d="M6 1v3M2 1v6m8-6v6m-4 8.5L3 17l.5-3.5-2-2 3-.5L6 8l1.5 3 3 .5-2 2L9 17l-3-1.5Z"
        stroke={color ?? "#1F1F1F"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CertificateProfileIcon
