import React from 'react';

interface Props {
  size?: number
  color?: string
}

const FinishIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 20}
      height={size ?? 21}
      fill="none"
      viewBox={'0 0 20 21'}
    >
      <path
        d="M7 7.5h.01m5.99 0h.01m5.99 3a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-13 1a4 4 0 1 0 8 0H6Z"
        stroke={color ?? "#D9D9D9"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default FinishIcon
