import React, { useEffect, useMemo, useState } from 'react';
import styles from '../jobInner/JobInner.module.scss';
import TopNavigation from '../topNavigation/topNavigation';
import CustomButton from '../shared/CustomButton/customButton';
import colors from '../../styles/variables.module.scss';
import SendIcon from '../../icons/send.icon';
import LoadingService from '../../services/loading/loading.service';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CandidateService } from '../../services/candidate/candidate.service';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';
import SuccessBannerModal from '../errorBannerModal/successBannerModal';

type Responses = {
  response1: string;
  response2: string;
  response3: string;
  response4: string;
  additionalComments: string;
  [key: string]: string;
};

type FieldTouched = {
  response1: boolean;
  response2: boolean;
  response3: boolean;
  response4: boolean;
  additionalComments: boolean;
  [key: string]: boolean;
};

function AddReview() {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [refID, setRefID] = useState<string>('');
  const [refCode, setRefCode] = useState<string>('');
  const [responses, setResponses] = useState<Responses>({
    response1: '',
    response2: '',
    response3: '',
    response4: '',
    additionalComments: '',
  });
  const [touched, setTouched] = useState<FieldTouched>({
    response1: false,
    response2: false,
    response3: false,
    response4: false,
    additionalComments: false,
  });
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [navigateAfterError, setNavigateAfterError] = useState<string | null>(null);
  const [errorModel, setErrorModel] = useState(false)
  const loadingService: LoadingService = useMemo(() => new LoadingService(setIsLoading), []);
  const candidateService: CandidateService = useMemo(() => new CandidateService(), []);

  useEffect(() => {
    if (searchParams.get('referee_id') && searchParams.get('referee_code')) {
      setRefID(searchParams.get('referee_id') ?? '');
      setRefCode(searchParams.get('referee_code') ?? '');
    } else {
      setErrorMessage('Invalid URL');
      setNavigateAfterError('/');
    }
  }, [searchParams]);

  useEffect(() => {
    if (refID && refCode) {
      loadingService.await(candidateService.getRefereeComments({
        referee_code: refCode,
        referee_id: refID,
      })).then((res) => {
        if (res !== null && res.status === 'success') {
          setResponses({
            response1: res.result.response1,
            response2: res.result.response2,
            response3: res.result.response3,
            response4: res.result.response4,
            additionalComments: res.result.additionalComments,
          });
        } else {
          setErrorMessage('Invalid URL');
          setNavigateAfterError('/');
        }
      }).catch((error: any)=>{
        setErrorModel(true)
        setErrorMessage(error.message)
      });
    }
  }, [refID, refCode, loadingService, candidateService]);

  const questions = [
    { text: 'What were the person’s biggest strengths?', field: 'response1' },
    { text: 'What were the person’s biggest areas of improvement back then?', field: 'response2' },
    { text: 'How would you rate their overall performance in that job on a 1-10 scale?', field: 'response3' },
    { text: 'What about their performance causes you to give that rating?', field: 'response4' },
    { text: 'Additional Comments', field: 'additionalComments' },
  ];

  async function submitResponses() {
    const res = await loadingService.await(candidateService.addRefereeComments({
      referee_code: refCode,
      referee_id: refID,
      ...responses,
    }));
    if (res !== null && res.status === 'success') {
      setSuccessMessage(true);
    } else {
      setErrorMessage('Something went wrong, please try again later.');
    }
  }

  function validateForm() {
    return responses.response1 === '' ||
      responses.response2 === '' ||
      responses.response3 === '' ||
      responses.response4 === '' ||
      responses.response1 === null ||
      responses.response2 === null ||
      responses.response3 === null ||
      responses.response4 === null;
  }

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <TopNavigation isReview />
        <ErrorBannerModal open={errorMessage !== ''} onClose={() => {
          if (navigateAfterError !== null) {
            navigate(navigateAfterError);
          } else {
            setErrorMessage('');
          }
        }} errorMessage={errorMessage} />
        <SuccessBannerModal open={successMessage} onClose={() => navigate('/signUp')} Message={'Find Out More About BBB'} linkTo/>
        <div className={`${styles.contentAssessment}`}>

          <div className={'d-flex justify-content-between align-items-center'}>
            <div>
              <h3 className={'mt-5 text-almostBlack'}>Add Review</h3>
            </div>
          </div>
          <div className={'mt-5'}>
            {questions.map((value, index) => (
              <div className={'mt-3'} key={index}>
                <h6>{value.text}</h6>
                <div className={'mt-4'} >
                  <textarea className={'form-control'} rows={3} value={responses[value.field]}
                    onClick={() => setTouched((prev: FieldTouched) => ({ ...prev, [value.field]: true }))}
                    onChange={(e) => setResponses((prev: Responses) => ({ ...prev, [value.field]: e.target.value }))} />
                  {responses[value.field] === '' && touched[value.field] && value.field !== 'additionalComments' && (
                    <p className={'text-danger'}>This field is required.</p>
                  )}
                </div>
              </div>
            ))}
          </div>

          <div className={`w-100 d-flex justify-content-center align-items-center mt-5 ${styles.borderTop}`}>
            <CustomButton className={styles.widthButton} type={'submit'} text={'Submit Review'}
              icon={<SendIcon color={colors.whiteColor} />} iconSide={'right'}
              disabled={isLoading || validateForm()}
              onClick={submitResponses} />
          </div>

        </div>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  );
}

export default AddReview;
