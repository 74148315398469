import React, { useEffect, useMemo, useState } from 'react';
import styles from './../../dashboard/dashboard.module.scss'
import styles2 from './../../assesmentCard/assesmentCard.module.scss'
import color from '../../../styles/variables.module.scss'

import WrongIcon from '../../../icons/wrong.icon';
import LoadingService from '../../../services/loading/loading.service';
import loadingStyles from '../CustomLoadingSpinner/LoadingSpinner.module.scss';
import NotificationService from '../../../services/notification/notification.service';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton/customButton';
import RightArrowIcon from '../../../icons/rightArrow.icon';
import { CandidateService } from '../../../services/candidate/candidate.service';
import ErrorBannerModal from '../../errorBannerModal/errorBannerModal';
import Tooltip from '../Tooltip/tooltip'
import InfoIcon from '../../../icons/info.icon'

type Notification = {
  id: string;
  title: string;
  body: string;
  actionText: string;
  type: string;
  data: any;
};

const TITLES: { [key: string]: string } = {
  'add-referees': 'Add Referees',
  'submit-referees': 'Request Referee Comment',
  'connect-to-accounting-software': 'Connect to Accounting Software',
  'complete-needs-analysis': 'Complete the Needs Analysis',
  'incomplete-job-details': 'Complete Role Details',
};
const BODIES: { [key: string]: ((data: any) => string) } = {
  'add-referees': () => 'Please add some referees to your profile.',
  'submit-referees': (data) => `Please request a referee comment from ${data.name}. The button below will send them a notification at ${data.email}.`,
  'connect-to-accounting-software': (data) => `Please connect your organisation ${data.name} to your accounting software.`,
  'complete-needs-analysis': (data) => `Please complete the needs analysis for ${data.title}.`,
  'incomplete-job-details': (data) => `Please complete the role details for ${data.title}.`,
};
const ACTION_TEXTS: { [key: string]: string } = {
  'add-referees': 'Profile Page',
  'submit-referees': 'Request Comment',
  'connect-to-accounting-software': 'Connect Accounting Data File',
  'complete-needs-analysis': 'Complete Needs Analysis',
  'incomplete-job-details': 'Complete Role Details',
};

type JobCardProps = {
  clickNotification?: (id:string)=> void
};

function NotificationBox({ clickNotification }:JobCardProps) {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [notifications, setNotifications] = useState<Notification[]>([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [hiddenNotifications, setHiddenNotifications] = useState<string[]>([]);

  const loadingService = useMemo(() => new LoadingService(setIsLoading), []);
  const notificationService = useMemo(() => new NotificationService(), []);
  const candidateService = useMemo(() => new CandidateService(), []);

  useEffect(() => {
    loadingService.await(notificationService.getNotifications()).then((res) => {
      if (res.status === 'success') {
        setNotifications(res.result.map((notification) => ({
          id: notification.id,
          title: TITLES[notification.type] ?? '',
          body: BODIES[notification.type] ? BODIES[notification.type](notification.data) : '',
          actionText: ACTION_TEXTS[notification.type] ?? '',
          type: notification.type,
          data: notification.data,
        })));
      }
    }).catch((error)=>{
      setErrorMessage(error.message)
    });
  }, [loadingService, notificationService, updateFlag]);

  async function removeNotification(id: string) {
    setHiddenNotifications([...hiddenNotifications, id]);
  }

  function handleAction(notification: Notification) {
    switch (notification.type) {
      case 'add-referees':
        navigate('/profile');
        break;
      case 'submit-referees':
        loadingService.await(candidateService.requestRefereeComment({ id: notification.data.id })).then(res => {
          if (res !== null && res.status === 'success') {
            setUpdateFlag(!updateFlag);
          } else {
            setErrorMessage('Something went wrong. Please try again later.');
          }
        });
        break;
      case 'connect-to-accounting-software':
        if (clickNotification) {
          clickNotification(notification.data.id)
        }
        break;
      case 'complete-needs-analysis':
        navigate('/analysis', { state: { orgID: notification.data.orgID, jobID: notification.data.id } });
        break;
      case 'incomplete-job-details':
        navigate('/jobDetails', { state: { jobID: notification.data.id, jobName: notification.data.title, from: 'jobs' } });
        break;
    }
  }

  return (
    <>
        <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <ErrorBannerModal open={errorMessage !== ''} onClose={() => {
          setErrorMessage('');
        }} errorMessage={errorMessage} />
            { notifications.filter((noti) => !hiddenNotifications.includes(noti.id)).map((notification, index) => (
                <div className={`${styles.alertBox} mb-5`} key={index}>
                    <div className={'d-flex justify-content-between'}>
                        <div className={'d-flex align-items-center'}>
                            <InfoIcon color={color.dangerColor} />
                            <h6 style={{ marginTop:6 }}  className={'text-danger ms-2'}>{ notification.title }</h6>
                          {notification.title === "Connect to Accounting Software" && <div style={{ marginLeft: 10 , marginTop:1 }}> <Tooltip
                            body={
                              'If you prefer not to establish a connection to your accounting data file, kindly navigate to the organisations page to complete the need analysis'
                            }
                          /> </div> }
                        </div>
                        <div onClick={() => { if (!isLoading) removeNotification(notification.id) }}><WrongIcon color={color.dangerColor} /></div>
                    </div>
                    <h6 className={'text-normal text-danger'}>{ notification.body }</h6>
                    { notification.actionText && (<>
                        <CustomButton disabled={isLoading} text={notification.actionText} type={'button'} iconSide={'right'} icon={<RightArrowIcon color={color.teritaryColor500}/>}
                                    className={`mt-4 ${styles2.outlineProgressBtn}`} onClick={() => { if (!isLoading) handleAction(notification) }}/></>

                    )}
                </div>
            ))}
        </div>
    </>
  )
}

export default NotificationBox;
