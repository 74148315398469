import React from 'react';

interface Props {
  size?: number
  color?: string
}

const LeftArrow: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 8}
      height={size ?? 14}
      fill="none"
    >
      <path
        d="M7 1 1 7l6 6"
        stroke={color ?? "#1A3665"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default LeftArrow
