import React from 'react';

interface Props {
  size?: number
  color?: string
}

const PlusIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 16}
      height={size ?? 16}
      fill="none"
    >
      <path
        d="M8 1v14M1 8h14"
        stroke={color ?? "#102A2D"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default PlusIcon
