import React from 'react';

interface Props {
  size?: number
  color?: string
}

const ManIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 14}
      height={size ?? 21}
      fill="none"
      viewBox={'0 0 14 11'}
    >
      <path
        d="M1 19.5v-2a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v2m-2-14a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z"
        stroke={color ?? "#636363"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default ManIcon
