import HttpService from "../http/http.service";
import UserService from "../user/user.service";
import { GetDataResponse, GetListResponse, GetSuburbsResponse, GetUniversitiesResponse } from "./data.res.model";


export default class DataService {
  constructor(private readonly httpService: HttpService = new HttpService(), private readonly userService: UserService = new UserService()) {}

  public async getData(key: string): Promise<GetDataResponse | null> {
    return this.httpService.sendGetRequest('data/get?' + new URLSearchParams({ key }), await this.userService.getUserToken());
  }

  public async getList(key: string): Promise<string[]> {
    const res = await this.httpService.sendGetRequest<GetListResponse>('data/list?' + new URLSearchParams({ key }), await this.userService.getUserToken());
    return res.result;
  }

  public async getUniversities(input: string): Promise<GetUniversitiesResponse | null> {
    return this.httpService.sendGetRequest('data/universities?' + new URLSearchParams({ input }), await this.userService.getUserToken());
  }

  public async getSuburbs(input: string): Promise<GetSuburbsResponse | null> {
    return this.httpService.sendGetRequest('location/autocomplete?' + new URLSearchParams({ input }), await this.userService.getUserToken());
  }

  public async getAllList(): Promise<string[]> {
    const res = await this.httpService.sendGetRequest<GetListResponse>('data/getAll' , await this.userService.getUserToken());
    return res.result;
  }
}
