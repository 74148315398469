
import { getRequestQueue } from './requestQueue.service'


export async function requestQueueHandler(reqKey: string): Promise<any> {
  const rq: any = await getRequestQueue(reqKey)
  if (rq?.result?.response != null || rq?.result?.error  != null) {
    if (rq?.status === 'error') throw rq?.error
    return rq
  }
  await new Promise<void>((resolve) => {
    setTimeout(() => resolve(), 2500)
  })
  return requestQueueHandler(reqKey)
}
