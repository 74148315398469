import React, { useCallback, useEffect, useMemo, useState } from "react";
import styles from '../gettingStart/GettingStart.module.scss';
import CustomInputField from "../shared/customInputField/CustomInputField";
import CustomDropdown  from "../shared/CustomDropdown/customDropdown";
import CustomButton from "../shared/CustomButton/customButton";
import PlusIcon from "../../icons/plus.icon";
import MinusIcon from "../../icons/close.icon";
import { CandidateService } from "../../services/candidate/candidate.service";
import SpinnerIcon from "../../icons/Spinner.icon";
import colors from '../../styles/variables.module.scss';
import styles2 from '../shared/customInputField/CustomInputField.module.scss'
export interface Role {
  role: string;
  years_of_experience: string;
}

interface SavedRole {
  id: string;
  role: string;
  years_of_experience: string;
  removeSpinner?: boolean;
}

interface WorkExperienceProps {
  existingRoles: SavedRole[];
  disabled: boolean;
  roleList: any[];
  getCanSubmit?: (canSubmit: boolean) => void;
  getRoles?: (roles: Role[]) => void;
}

const WorkExperience = ({ existingRoles, disabled, roleList, getCanSubmit, getRoles }: WorkExperienceProps) => {

  const [roles, setRoles] = useState<Role[]>([{ role: '', years_of_experience: '' }]);
  const [savedRoles, setSavedRoles] = useState<SavedRole[]>([]);
  const [canSubmit, setCanSubmit] = useState(true);
  const [roleNameValidation, setRoleNameValidation] = useState<boolean>(false);
  const [validationMessage, setValidationMessage] = useState('');

  const candidateService: CandidateService = useMemo(() => new CandidateService(), []);

  useEffect(() => {
    setSavedRoles(existingRoles.map((role) => ({ ...role, removeSpinner: false })));
  }, [existingRoles]);

  useEffect(() => {
    if (getCanSubmit) getCanSubmit(canSubmit);
  }, [canSubmit, getCanSubmit]);

  const validateRoleName = useCallback((role: Role): boolean => {
    return roles.concat(savedRoles).filter(r => r.role === role.role).length <= 1 && role.role !== '';
  }, [roles, savedRoles]);

  const validateRoleYears = useCallback((role: Role): boolean => {
    const regex = /^[0-9]*$/;
    return regex.test(role.years_of_experience) && role.years_of_experience !== '' && parseInt(role.years_of_experience) > 0
  }, []);

  useEffect(() => {
    if (getRoles) getRoles(roles.filter((role) => role.role !== '' && role.years_of_experience !== ''));
    const validRoles = roles.filter((role) => validateRoleName(role) && validateRoleYears(role));
    const checkRoleName = roles.filter((role) => validateRoleName(role));
    setRoleNameValidation(checkRoleName.length + roles.filter((role) => role.role === '' && role.years_of_experience === '').length === roles.length);
    setCanSubmit(validRoles.length + roles.filter((role) => role.role === '' && role.years_of_experience === '').length === roles.length);    
  }, [roles, getRoles, setCanSubmit, validateRoleYears, validateRoleName]);  

  useEffect(() => {
    if (roleNameValidation) {
      setValidationMessage('');
    } else {
      setValidationMessage('There are duplicate roles. Please correct them before proceeding.');
    }
  }, [roleNameValidation]);  
  

  const addBlankRole = (): void => {
    setRoles([...roles, { role: '', years_of_experience: '' }]);
  };

  const handleTitleChange = (title: string, index: number) => {
    setRoles([...roles.filter((_r, i) => i < index), { role: title, years_of_experience: roles.find((_r, i) => i === index)?.years_of_experience ?? '' }, ...roles.filter((_r, i) => i > index)]);
  };

  const handleYearsChange = (years: string, index: number) => {
    setRoles([...roles.filter((_r, i) => i < index), { role: roles.find((_r, i) => i === index)?.role ?? '', years_of_experience: years }, ...roles.filter((_r, i) => i > index)]);
  };

  const handleRemoveRole = (index: number) => {
    setRoles(roles.filter(value => roles.indexOf(value) !== index));
  };

  const handleRemoveSavedRole = async (index: number) => {
    const role = savedRoles.find((_r, i) => i === index) ?? { id: '', role: '', years_of_experience: '' };
    if (savedRoles.find((r) => r.removeSpinner)) return;
    setSavedRoles([...savedRoles.filter((_r, i) => i < index), { ...role, removeSpinner: true }, ...savedRoles.filter((_r, i) => i > index)]);
    candidateService.removeWorkExperience([{ id: savedRoles[index].id }]).then((res) => {
      if (res !== null && res.status === 'success') {
        setSavedRoles(savedRoles.filter((r) => r.id !== role.id));
      } else {
        setSavedRoles([...savedRoles.filter((_r, i) => i < index), { ...role, removeSpinner: false }, ...savedRoles.filter((_r, i) => i > index)]);
      }
    });
  };  

  return (
    <div className={styles.contentQuestion}>
      <h6 className={styles.widthLeft}>Work Experience</h6>
      <div className={styles.answerLength}>
        <div className={'d-flex'}>
          <h6 className={`text-normal gray-color-text w-50`}>Role Title</h6>
          <h6 className={`text-normal gray-color-text w-50`}>Relevant Experience</h6>
        </div>

        {savedRoles.map((role: SavedRole, index: number) => (
          <div className={'d-flex mt-3 w-100 justify-content-between'} key={index}>
            <div className={styles.job}>
              <CustomInputField name={`preferredRole${index}`} placeholder={roleList.filter((fd)=> fd.id === role.role)[0]?.value ?? role.role} readOnly disabled />
            </div>
            <div>
              <CustomInputField name={`yearsInRole${index}`} placeholder={`${role.years_of_experience}`} className={'d-flex ms-3 '} readOnly disabled />
            </div>
            <div className={'d-flex justify-content-end mt-0 ms-4 align-items-center'} onClick={() => handleRemoveSavedRole(index)} >
              {role.removeSpinner ? <SpinnerIcon color={colors.primaryColor} size={40} /> : <MinusIcon />}
            </div>
          </div>
        ))}
        <div>
          {roles.map((role: Role, index: number) => (
            <>
            <div className={'d-flex mt-3 justify-content-between '} key={index}>
                <div className={styles.job}>
                  <CustomDropdown name={`preferredRole${index}`} placeHolder={'Job Role *'} selectedValue={ roleList.filter((fd)=> fd.id === role.role).map((md)=>({ value: md.id, text: md.value }))[0]} dataList={roleList.map((value:any) => ({ value: value.id, text: value.value }))} getSelectedItem={(i) => { handleTitleChange(i.value, index) }} />
                </div>
                <div>
                  <div className={'w-100'}>
                    <div>
                      <input value={role.years_of_experience} placeholder={'Years *'}  className={`${styles2.customField} ms-3`} name={`yearsInRole${index}`}   onChange={(event: any) =>  handleYearsChange(event.target.value, index)}  />
                    </div>
                  </div>

                </div>
              {roles.length >= 1 &&  (
                <div className={'d-flex justify-content-end mt-0 ms-5 align-items-center'}
                  onClick={() => handleRemoveRole(index)}>
                  <MinusIcon />
                </div>
              )}
            </div>
            </>
          ))}
          {!roleNameValidation && 
            <div className={styles.errorStyle}>
              {validationMessage}
            </div>
          }
          <div className={'d-flex justify-content-end mt-3 ms-3'} style={{ position: 'relative' }}>
            <CustomButton text={'Add Another Role'} icon={<PlusIcon />} iconSide={'left'}
              variant={'transparent'} className={styles.fontColor} onClick={addBlankRole}
              disabled={disabled} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;
