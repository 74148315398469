import React, { useContext, useEffect, useMemo, useState } from 'react'

import styles from './sinIn.module.scss'
import SignInImage from '../../../src/images/Professional Sign Up.jpg'
import { Form, Formik } from "formik";
import CustomButton from "../shared/CustomButton/customButton";
import CustomInputField from "../shared/customInputField/CustomInputField";
import LinkedInBlackIcon from "../../icons/linkedInBlackIcon";
import {  useNavigate } from 'react-router-dom'
import { Auth } from 'aws-amplify';
import * as Yup from 'yup';
import 'yup-phone';
import UserService from '../../services/user/user.service';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingService from '../../services/loading/loading.service';
import ProfLogo from "../../images/BBB Logo_Professional.jpg";
import { UserContext } from '../../context'
import { CandidateService } from '../../services/candidate/candidate.service'
import DataService from '../../services/data/data.service'
import { MasterDataContext } from '../../context/masterData'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import { Urls } from '../../context/Urls'

type SignUpProps = {
  firstName: string;
  lastName: string;
  email: string;
  contactNumber: string;
  password: string;
  confirmPassword: string;
  userAgreement: boolean;
};

function SignUp() {
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModel, setErrorModel] = useState(false)
  const navigate = useNavigate();
  const user = useContext(UserContext);
  const masterData = useContext(MasterDataContext);
  const userService: UserService = useMemo(() => {
    return new UserService();
  }, []);
  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);
  const [isLoading, setIsLoading] = useState(false);
  const [emailForm, setEmailForm] = useState(false);
  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const dataService: DataService = useMemo(() => {
    return new DataService();
  }, []);

  const initialVal: SignUpProps = useMemo(function () {
    return {
      firstName: '',
      lastName: '',
      email: '',
      contactNumber: '',
      password: '',
      confirmPassword: '',
      userAgreement: false,
    };
  }, []);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().matches(/^[A-Za-zÁÉÍÓÚÜÑñáéíóúü-\s]+$/, 'Name can only contain uppercase letters, lowercase letters').required('Please enter your first name.'),
    lastName: Yup.string().matches(/^[A-Za-zÁÉÍÓÚÜÑñáéíóúü-\s]+$/, 'Name can only contain uppercase letters, lowercase letters').required('Please enter your last name.'),
    email: Yup.string().required('Please enter your email address.').email('Please enter a valid email address.'),
    contactNumber: Yup.string().matches(/^(?:\+61\d{9}|04\d{8})$/, 'Please use the format +61XXXXXXXXX or 04XXXXXXXX').required('Please enter your contact number.').phone('AU', false, 'Please use the format +61XXXXXXXXX or 04XXXXXXXX.'),
    password: Yup.string()
      .min(8, 'Password must be at least 8 characters.')
      .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
        'Password must be at least 8 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character.')
      .required('Password is required'),
    confirmPassword: Yup.string().required('Please confirm your password.').oneOf([Yup.ref<string>('password')], 'Passwords do not match.'),
    userAgreement: Yup.boolean().required().isTrue('Please agree to our privacy policy and our terms/conditions.'),
  });

  useEffect(() => {
    loadingService.setReason('getIsLoggedIn', true);
    userService.isLoggedIn().then((res) => {
      loadingService.setReason('getIsLoggedIn', false);
      if (res) {
        navigate('/');
      }
    });
  }, [navigate, userService, loadingService]);

  // Example async functions
  function asyncFunction1() {
    return new Promise((resolve) => {
      resolve(userService.getUserDataT())
    });
  }

  function asyncFunction2() {
    return new Promise((resolve) => {
      resolve(candidateService.getCandidateDataT())
    });
  }

  function asyncFunction3() {
    return new Promise((resolve) => {
      resolve(dataService.getAllList())
    });
  }

  async function fetchData() {
    try {
      const [data1, data2, data3]:any = await Promise.all([
        asyncFunction1(),
        asyncFunction2(),
        asyncFunction3(),
      ]);
      user.setUserData(data1);
      user.setCandidateData(data2);
      masterData.setMasterData(data3)

    } catch (error :any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  function addCountryCode(phoneNumber:any) {
    // Remove any non-digit characters from the phone number
    const digitsOnly = phoneNumber.replace(/\D/g, '');

    // Check if the number starts with '0'
    if (digitsOnly.startsWith('0')) {
      // Replace the '0' with '61' (Australian country code)
      return `+61${digitsOnly.substring(1)}`;
    } else {
      // If it doesn't start with '0', assume it already has the country code
      return phoneNumber;
    }
  }
  // eslint-disable-next-line
  const handleSubmit = async function (values: SignUpProps, { setFieldError }: { setFieldError: any }) {
    loadingService.setReason('signUp', true);
    try {
      const isMobileValid = true
      if (isMobileValid) {
        await Auth.signUp({
          username: values.email,
          password: values.password,
          attributes: {
            name: values.firstName + ' ' + values.lastName,
            given_name: values.firstName,
            family_name: values.lastName,
            phone_number:  addCountryCode(values.contactNumber),
          },
        });
        await Auth.signIn(values.email, values.password);
        localStorage.setItem('login_form', 'App');
        await  fetchData();
        loadingService.setReason('signUp', false);
        navigate('/gettingStart')
        await Auth.verifyCurrentUserAttribute('email');
        const userData =   await userService.updateUserData({ mobile_number: values.contactNumber });
        user.setUserData(userData);
        user.setCandidateData({
          "profile_pages": "{}",
        });
      } else {
        setFieldError('contactNumber', 'Please enter a new mobile number! This number is already registered.');
        loadingService.setReason('signUp', false);
      }
    } catch (err: any) {
      if (err.name === 'UsernameExistsException' || err.name === 'UserLambdaValidationException') {
        setFieldError('email', 'An account with the given email already exists.');
      } else if (err.name === 'InvalidPasswordException') {
        setFieldError('password', err.message + '.');
      } else {
        setFieldError('email', 'Failed to sign up.');
      }
      loadingService.setReason('signUp', false);
    }
  };

  const redirect = () => {
    window.location.href = `${Urls.APP_COGNITO}/oauth2/authorize?response_type=code&client_id=${Urls.APP_CLIENT_ID}&redirect_uri=${Urls.APP_REDIRECT}`;
  }



  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik initialValues={initialVal} validationSchema={validationSchema} onSubmit={handleSubmit} validateOnChange>
          {({ values, setFieldValue, errors, touched }) => (
            <Form>
              <div className={'d-flex justify-content-between w-100'}>
                <div className={styles.formContainer}>
                  <div className={styles.logo}>
                    <img src={ProfLogo} alt="react logo" className={styles.logoImage} />
                  </div>
                  <div className={styles.leftContentBox}>
                    <div className={styles.middleContent}>
                      <h1 className={'text-almostBlack text-bolder mt-3'}>Sign Up</h1>
                      <h6 className={'mt-3 text-normal signInLineHeight'}>There’s a brand new way to be matched to your next Finance role based on your preferences, skills and location. Let the market match your rate. Tell us what you need; we’ll tell you what’s out there right now.</h6>
                      {emailForm ?
                        <>
                          <div className={'mt-4 row'}>
                            <div className={'col-sm-6'}>
                              {/* eslint-disable-next-line */}
                              <CustomInputField name={'firstName'}
                                onChange={(event: any) => setFieldValue('firstName', event.target.value)}
                                placeholder={'First Name *'} />
                            </div>
                            <div className={'col-sm-6 mt-3 mt-md-0'}>
                              {/* eslint-disable-next-line */}
                              <CustomInputField name={'lastName'}
                                onChange={(event: any) => setFieldValue('lastName', event.target.value)}
                                placeholder={'Last Name *'} />
                            </div>
                          </div>
                          <div className={'d-flex mt-3 w-100'}>
                            {/* eslint-disable-next-line */}
                            <CustomInputField name={'email'}
                              onChange={(event: any) => setFieldValue('email', event.target.value)}
                              placeholder={'Email *'} className={'w-100'} />
                          </div>
                          <div className={'mt-3 w-100'}>
                            {/* eslint-disable-next-line */}
                            <CustomInputField name={'contactNumber'} type={'text'}
                              onChange={(event: any) => setFieldValue('contactNumber', event.target.value)}
                              placeholder={'Mobile Phone *'} className={'w-100'} />
                          </div>
                          <div className={'mt-3'}>
                            {/* eslint-disable-next-line */}
                            <CustomInputField type={'password'} onChange={(event: any) => {
                              setFieldValue('password', event.target.value);
                              touched.password = true
                            }} placeholder={'Password *'} name={'password'} pwError />
                          </div>
                          {errors.password && touched.password && <div className={styles.error}>{errors.password}</div>}
                          <div className={'mt-3'}>
                            {/* eslint-disable-next-line */}
                            <CustomInputField type={'password'} onChange={(event: any) => {
                              setFieldValue('confirmPassword', event.target.value);
                              touched.confirmPassword = true
                            }} placeholder={'Confirm Password *'} name={'confirmPassword'} pwError />
                          </div>
                          {errors.confirmPassword && touched.confirmPassword &&
                            <div className={styles.error}>{errors.confirmPassword}</div>}
                          <div className={'d-flex justify-content-between w-100 mt-3'}>
                            <div className="form-check">
                              <input className="form-check-input" type="checkbox"
                                onChange={() => setFieldValue('userAgreement', !values.userAgreement)}
                                id="flexCheckDefault" />
                              <label className="form-check-label" htmlFor="">
                                <p className={'text-almostBlack text-bold font-size-semi'}>I have read and agree to
                                  the <a className={'text-decoration-underline text-almostBlack'}
                                    href={'https://betterbusinessbasics.com.au/privacy-policy/'} rel={'noreferrer'}
                                    target={'_blank'}>privacy policy</a> and <span
                                      className={'text-decoration-underline'}>terms /conditions</span></p>
                              </label>
                              {touched.userAgreement && errors.userAgreement ?
                                <div className={styles.error}>{errors.userAgreement}</div> : null}
                            </div>
                          </div>
                          <div className={'mt-4'}>
                            <CustomButton type={'submit'} text={'Sign Up with BBB'} />
                          </div>
                        </>
                        :
                        <>
                          <div className={'mt-4'}>
                            <CustomButton type={'button'}
                                          onClick={redirect}
                                          text={'Sign Up with LinkedIn'} icon={<LinkedInBlackIcon />} iconSide={'left'}
                                          className={styles.outlineBtn} />
                          </div>
                          <div className={'mt-4 d-flex justify-content-between align-items-center'}>
                            <div className={styles.devidedLine} />
                            <p className={styles.fontColor}>Or</p>
                            <div className={styles.devidedLine} />
                          </div>
                          <div className={'mt-4'}>
                            <CustomButton onClick={() => setEmailForm(true)} text={'Sign Up with BBB'} />
                          </div>

                        </>
                      }
                    </div>

                    <p className={'text-almostBlack text-normal font-size-semi mt-5'}>Already have an account? <span className={'text-bold pointer'} onClick={() => navigate('/signIn?type=candidateSignIn')}>Sign In</span></p>
                  </div>
                </div>
                <div className={styles.imageContainer}>
                  <img src={SignInImage} alt={'signUp'} className={styles.image} />
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default SignUp
