import React, { useEffect, useState } from 'react'
import styles from '../analysis/styles.module.scss'
import CustomModal from '../shared/customModal/customModal'
import CustomButton from '../shared/CustomButton/customButton'

type ErrorBannerModalProps = {
  open: boolean
  onClose: (val: boolean) => void
  onYesClick?: () => void
  confirmMessage?: string
  title?: string
  yesText?: string;
  noText?: string;
}

function ConfirmBannerModel({
  open,
  onClose,
  confirmMessage,
  onYesClick,
  title,
  yesText,
  noText,
}: ErrorBannerModalProps) {
  const [openModal, setOpenModal] = useState<boolean>(false)

  useEffect(() => {
    setOpenModal(open)
  }, [open])

  return (
    <CustomModal
      open={openModal}
      onCloseModal={() => {
        setOpenModal(false)
        onClose(false)
      }}
      title={title}
    >
      <div>
        <div className={'d-flex align-items-center'}>
          <h5 className={'text-black ms-3 mb-5'}>{confirmMessage}</h5>
        </div>

        <div className={'d-flex align-items-center justify-content-end'}>
          <CustomButton
            text={noText ?? 'No'}
            className={`${styles.outlineBtn} ${styles.widthButton}`}
            onClick={() => onClose(false)}
          />

          <CustomButton
            className={`ms-2 ${styles.widthButton}`}
            text={yesText ?? 'Yes'}
            onClick={() => {
              (onYesClick ?? (() => { }))();
              onClose(false);
            }}
          />
        </div>
      </div>
    </CustomModal>
  )
}

export default ConfirmBannerModel
