import React from 'react';

interface Props {
  size?: number
  color?: string
}

const OrgDetailsIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 20}
      height={size ? size + 1 : 21}
      fill="none"
      viewBox={'0 0 20 21'}
    >
      <path
        d="M1 19.5h18M7 6.5h1m-1 4h1m-1 4h1m4-8h1m-1 4h1m-1 4h1m-10 5v-16a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v16"
        stroke={color ?? "#D9D9D9"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default OrgDetailsIcon
