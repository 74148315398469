import React, { useMemo } from 'react'

import styles from '../gettingStart/GettingStart.module.scss'

import { Form, Formik } from "formik";
import CustomButton from "../shared/CustomButton/customButton";
import PlusIcon from "../../icons/plus.icon";
import DegreeIcon from "../../icons/degree.icon";
import EditIcon from "../../icons/edit.icon";
import CalenderIcon from "../../icons/calender.icon";
import RecycleBinIcon from "../../icons/recycleBinIcon";
import CertificateIcon from "../../icons/certificate.icon";
import CustomInputField from "../shared/customInputField/CustomInputField";
import CustomDropdown from "../shared/CustomDropdown/customDropdown";
import CustomLayout from "../layout/commonLayout";



function QualificationWithoutImport() {

  const initialVal = useMemo(() => {
    return {
      code: '',
    }
  }, [])

  const handleSubmit = ()=>{
  }

  return (
    <CustomLayout title={'Qualifications'} subTitle={'Tell us about your education qualifications'} pageName={'qualification'} progressValue={40}>
      <Formik initialValues={initialVal} onSubmit={()=>handleSubmit()}>
        <Form>
              <div className={styles.outerContent}>
                <div>
                <p className={'text-bold'}>Australian Education</p>
                <div className={styles.educationCardWithoutImport}>
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <DegreeIcon/>
                    <EditIcon/>
                  </div>
                  <div>
                    <h6 className={'text-bold gray-color-text'}>Master of Accounting and Business Management</h6>
                    <p className={'text-normal text font-size-semi gray-color-text'}>University of Melbourne</p>

                    <div className={'d-flex'}>
                      <CalenderIcon/>
                      <div className={'d-flex justify-content-between align-items-center w-100'}>
                        <p className={'ms-2'}>March 2018 - Jan 2022</p>
                        <RecycleBinIcon/>
                      </div>
                    </div>

                  </div>
                </div>
                <div>
                </div>
                </div>
                  <div className={styles.innerContent}>
                    <CustomInputField name={'school'} placeholder={'School / Institute *'} className={styles.inputWidth}/>
                    <CustomInputField name={'level of qualification'} placeholder={'Level of qualification'} className={`mt-3 ${styles.inputWidth}`}/>
                    <CustomInputField name={'Field of Study'} placeholder={'Field of Study *'} className={`mt-3 ${styles.inputWidth}`}/>
                    <p className={'mt-3 w-100 mb-3'}>Start Date</p>
                    <div className={'d-flex w-100'}>
                      <div className={styles.monthDropdown}>
                      <CustomDropdown dataList={[{ value:'0',text:'January' }]} placeHolder={'Month *'}/>
                      </div>
                      <div className={`ms-2`}>
                      <CustomDropdown dataList={[{ value:'0',text:'2022' }]} placeHolder={'Year *'} />
                      </div>
                    </div>
                    <p className={'mt-3 w-100 mb-3'}>End Date</p>
                    <div className={`d-flex w-100 ${styles.borderBottom}`}>
                      <div className={styles.monthDropdown}>
                        <CustomDropdown dataList={[{ value:'0',text:'January' }]} placeHolder={'Month11 *'}/>
                      </div>
                      <div className={`ms-2`}>
                        <CustomDropdown dataList={[{ value:'0',text:'2022' }]} placeHolder={'Year *'} />
                      </div>
                    </div>
                    <div className={'d-flex justify-content-between w-100 mt-4'}>
                      <div className={'d-flex'}>
                        <RecycleBinIcon/>
                        <p className={'text-normal text-almostBlack ms-2'}>Discard</p>
                      </div>
                    <CustomButton type={'button'} onClick={()=> {}} text={'Add Education1111'} icon={<PlusIcon/>} iconSide={'left'} variant={'transparent'} className={styles.fontColor}/>
                    </div>
                  </div>
                </div>

              <div className={`mt-4 ${styles.outerContent}`}>
                <div>
                  <p className={'text-bold'}>Other Licence & Certificates</p>
                  <div className={styles.educationCardWithoutImport}>
                    <div className={'d-flex justify-content-between align-items-center'}>
                      <CertificateIcon/>
                      <EditIcon/>
                    </div>
                    <div>
                      <h6 className={'text-bold gray-color-text'}>Master of Accounting and Business Management</h6>
                      <p className={'text-normal text font-size-semi gray-color-text'}>University of Melbourne</p>

                      <div className={'d-flex'}>
                        <CalenderIcon/>
                        <div className={'d-flex justify-content-between align-items-center w-100'}>
                          <p className={'ms-2'}>March 2018 - Jan 2022</p>
                          <RecycleBinIcon/>
                        </div>
                      </div>

                    </div>
                  </div>
                  <div>
                  </div>
                </div>
                <div className={styles.innerContent}>
                  <CustomInputField name={'name'} placeholder={'Name *'} className={`mt-3 ${styles.inputWidth}`}/>
                  <CustomInputField name={'Issuing Organisation '} placeholder={'Issuing Organisation *'} className={`mt-3 ${styles.inputWidth}`}/>
                  <p className={'mt-3 w-100 mb-3'}>Issuing Date</p>
                  <div className={'d-flex w-100'}>
                    <div className={styles.monthDropdown}>
                      <CustomDropdown dataList={[{ value:'0',text:'January' }]} placeHolder={'Month *'}/>
                    </div>
                    <div className={`ms-2`}>
                      <CustomDropdown dataList={[{ value:'0',text:'2022' }]} placeHolder={'Year *'} />
                    </div>
                  </div>

                  <div className={'d-flex justify-content-between w-100 mt-4'}>
                    <div className={'d-flex'}>
                      <RecycleBinIcon/>
                      <p className={'text-normal text-almostBlack ms-2'}>Discard</p>
                    </div>
                    <CustomButton type={'button'} text={'Add Certification'} icon={<PlusIcon/>} iconSide={'left'} variant={'transparent'} className={styles.fontColor}/>
                  </div>
                </div>
              </div>
        </Form>
      </Formik>
    </CustomLayout>
  )
}

export default QualificationWithoutImport
