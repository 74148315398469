import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from '../jobInner/JobInner.module.scss'
import TopNavigation from "../topNavigation/topNavigation";
import CustomButton from "../shared/CustomButton/customButton";
import LeftArrow from "../../icons/leftArrow.icon";
import TimerClock from "../shared/timer/countUpTimer";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import colors from "../../styles/variables.module.scss";
import SendIcon from "../../icons/send.icon";
import CustomModal from "../shared/customModal/customModal";
import { useNavigate } from "react-router-dom";
import LoadingService from '../../services/loading/loading.service';
import UserService from '../../services/user/user.service';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import { CandidateService } from '../../services/candidate/candidate.service';
import { StartAssessmentResponse } from '../../services/candidate/candidate.res.model';
import { UserContext } from '../../context';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

enum QuestionType {
  MultipleChoice = 'multiple_choice',
  Freeform = 'freeform',
}
type Question = StartAssessmentResponse["questions"][0];
type Answer = StartAssessmentResponse["questions"][0]["answers"][0];

function Assessment() {
  const navigate = useNavigate();
  const user = useContext(UserContext);

  const service: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  const userService: UserService = useMemo(() => {
    return new UserService();
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const [questions, setQuestions] = useState<Question[]>([]);
  const [modal, setModal] = useState(false);
  const [answers, setAnswers] = useState<{ [key: string]: string }>({});
  const [submitError, setSubmitError] = useState(false);
  const [currentTime, setCurrentTime] = useState<any>();
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  useEffect(() => {
    loadingService.await(userService.isLoggedIn()).then(res => !res ? navigate('/') : undefined);
    loadingService.await(service.startAssessment()).then(data => {
      if (!data || data.status !== 'success') {
        navigate('/dashboard/topmatches');
        return;
      }
      setQuestions(data.questions);
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [navigate, service, loadingService, userService]);

  useEffect(() => {
    // Disable the browser refresh button.
    // When the user clicks the refresh button an alert will be shown.
    const onUnload = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      e.returnValue = '';
    };
    window.addEventListener('beforeunload', onUnload);
    return () => window.removeEventListener('beforeunload', onUnload);
  }, []);

  useEffect(() => {
    // Disable the browser back button.
    // When the user clicks the back button, the modal will be shown.
    let eventCounter = 0;
    window.history.pushState(null, '', window.location.href);
    const onGoBack = () => {
      eventCounter++;
      if (eventCounter > 1) setModal(true);
      window.history.go(1);
    };
    window.addEventListener('popstate', onGoBack);
    return () => window.removeEventListener('popstate', onGoBack);
  }, []);

  function captureAnswer(question: string, answer: string, e?: React.ChangeEvent<HTMLInputElement>) {
    if (e) {
      if (e?.currentTarget.checked) {
        setAnswers({ ...answers, [question]: answer });
      } else {
        setAnswers(current => {
          const copy = { ...current };
          delete copy[question];

          return copy;
        });
      }
    } else {
      setAnswers({ ...answers, [question]: answer });
    }
  }

  async function submit() {
    setSubmitError(false);
    const answersToSubmit: { question_id: string, answer_id?: string, answer_text?: string }[] = [];
    Object.keys(answers).forEach((key: string) => {
      if (questions.find(q => q.id === key)?.type === QuestionType.Freeform) {
        answersToSubmit.push({
          question_id: key,
          answer_text: answers[key],
        });
      } else if (questions.find(q => q.id === key)?.type === QuestionType.MultipleChoice) {
        answersToSubmit.push({
          question_id: key,
          answer_id: answers[key],
        });
      }
    });


    const res = await loadingService.await(service.submitAssessment(answersToSubmit, JSON.stringify(currentTime)));
    if (res !== null && res.status === 'success') {
      setModal(false);
      user.setIsAssessmentCompleted(true);
      navigate('/Profile');
    } else {
      setSubmitError(true);
    }
  }


  return (
    <div>
      {isLoading ? <LoadingSpinner/> : <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <TopNavigation isReview tabValue={'0'}/>
        <div className={`${styles.contentAssessment}`}>
          <CustomButton type={'button'} text={'Go Back'} icon={<LeftArrow/>} iconSide={'left'}
                        className={styles.whiteBtn} onClick={() => setModal(true)}/>
          <div className={'d-flex justify-content-end align-items-center'}>
            <div className={`d-flex align-items-center ${styles.assesmentClock}`}>
              <TimerClock
                hours={0}
                minutes={0}
                seconds={0}
                doTick={!isLoading}
                onTimeChange={(newTime) => setCurrentTime(newTime)} // Update the current time
              />
              <div style={{ width: '50px' }} className={'ms-3'}>
                <CircularProgressbar value={(Object.keys(answers).length / questions.length) * 100 || 0}
                                     text={`${Math.floor((Object.keys(answers).length / questions.length) * 100) || 0}%`}
                                     styles={buildStyles({
                                       pathColor: `${(Object.keys(answers).length / questions.length) * 100 < 50 ? colors.dangerColor : (Object.keys(answers).length / questions.length) * 100 < 75 ? colors.orangeColor : colors.successColor}`,
                                       textSize: '18px',
                                     })}/>
              </div>
            </div>
          </div>
          <div className={'mt-5'}>
            {questions.map((question: Question, index: number) => (
              <div className={`mt-5 ${styles.borderBottom}`} key={index}>
                <h6 className={'gray-color-text'}><span className={'me-2'}>{index + 1}.</span>{question.text}</h6>

                  {question.type === QuestionType.MultipleChoice && question.answers.map((answer: Answer) => (

                    <div className={`form-check mt-4 `} key={answer.id}>
                      <input className={`form-check-input ${styles.assesmentAnswers}`} type="radio" name={`${question.id}`} id={`${question.id}`}
                             onChange={(e) => captureAnswer(question.id, answer.id,e)}
                             checked={Object.entries(answers).findIndex((fd)=>(fd[0] === question.id && fd[1] === answer.id)) !== -1}
                             value={answer.id}
                      />
                      <label className="form-check-label" htmlFor={`${answer.id}`} onClick={(e) => e.preventDefault()}>
                        <h6 className={'text-normal gray-color-text me-4'}>{answer.text}</h6>
                      </label>
                    </div>

                  ))}

                  { question.type === QuestionType.Freeform && (
                    <div className={'mt-4'}>
                      <textarea className={'form-control'} rows={3} value={answers[question.id]} onChange={(e) => {
                        if (e.target.value.split(' ').length <= 500) {
                          captureAnswer(question.id, e.target.value);
                        }
                      }}/>
                      <h6 className={'text-normal gray-color-text mt-2'}>{answers[question.id] ? answers[question.id].split(' ').length : 0}/500</h6>
                    </div>
                  )}

              </div>
            ))}
            <div className={`w-100 d-flex justify-content-center align-items-center mt-5 ${styles.borderTop}`}>
              <CustomButton className={styles.widthButton} type={'submit'} text={'Submit Answers'}
                            icon={<SendIcon color={colors.whiteColor}/>} iconSide={'right'}
                            onClick={() => setModal(true)}
                            disabled={isLoading || Object.keys(answers).length !== questions.length}
              />
            </div>

              {Object.keys(answers).length !== questions.length && (
                <center>Please answer all the questions before submitting.</center>
              )}
          </div>
        </div>
        {modal &&
            <CustomModal title={Object.keys(answers).length !== questions.length ? 'Are you sure you want to quit the assessment? You won`t be able to resume it.' : 'Are you sure you want to complete the assessment now?'} open={modal} onCloseModal={(val) => {
              setModal(val);
              setSubmitError(false);
            }}>
              { Object.keys(answers).length !== questions.length ? (
                  <div className={styles.modal}>
                    <div className={'d-flex justify-content-end align-items-center mt-5'}>
                        <CustomButton type={'button'} text={'No, Continue Assessment'} variant={'transparent'}
                                      className={styles.OutlinedBtn} onClick={() => setModal(false)}/>
                        <CustomButton type={'submit'} text={'Yes, Quit'} variant={'primary'}
                                      icon={<SendIcon color={colors.whiteColor}/>} iconSide={'right'}
                                      className={`ms-3 ${styles.widthButton}`} onClick={submit}/>
                    </div>
                  </div>
              ) : (
                  <div className={styles.modal}>
                    { submitError && (
                      <div className={'alert alert-danger mt-3'} role="alert">
                        Something went wrong. Please try again.
                      </div>
                    )}
                    <div className={'d-flex justify-content-end align-items-center mt-4'}>
                        <CustomButton type={'button'} text={'No, Go Back'} variant={'transparent'}
                                      className={styles.OutlinedBtn} onClick={() => {
                                        setModal(false);
                                        setSubmitError(false);
                                      }}/>
                        <CustomButton type={'submit'} text={'Yes, Submit Answers'} variant={'primary'}
                                      icon={<SendIcon color={colors.whiteColor}/>} iconSide={'right'}
                                      className={`ms-3 ${styles.widthButton}`} onClick={submit}/>
                    </div>
                  </div>
              )
              }
            </CustomModal>
        }
      </div>
      }
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Assessment
