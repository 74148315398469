import { CognitoUser } from "@aws-amplify/auth";
import { Auth } from "aws-amplify";
import FileService from "../file/file.service";
import HttpService from "../http/http.service";
import { UploadedFile, UploadedFileType } from "../file/file.model";
import { GetProfileImageResponse, GetUserDataResponse, GetUserRole, SetProfileImageResponse, UpdateUserDataResponse, ValidatePropertyResponse } from "./user.res.model";
import { UpdateUserDataRequest } from "./user.req.model";
export default class UserService {
  constructor(private readonly httpService: HttpService = new HttpService(), private readonly fileService: FileService = new FileService()) { }

  // Get the current user instance
  public async getCurrentUser(): Promise<CognitoUser | null> {
    return new Promise((callback: (user: CognitoUser | null) => void) => {
      Auth.currentAuthenticatedUser().then((user: CognitoUser) => {
        callback(user);
      }).catch(() => {
        callback(null);
      });
    });
  }

  // Check if there is a user logged in
  public async isLoggedIn(): Promise<boolean> {
    if (localStorage.getItem('login_form') !== "App") {
      if (localStorage.getItem('token')) {
        const   token:any = localStorage.getItem('token')
        return  ( token !== undefined);
      } else {
        return false
      }
    } else {
      return ( await this.getCurrentUser()) !== null;
    }


  }

  // Get the user JWT id token
  public async getUserToken(): Promise<string> {
    if ( localStorage.getItem('login_form') === "App") {
      return (await this.getCurrentUser())?.getSignInUserSession()?.getIdToken().getJwtToken() || '';
    } else {
      return  localStorage.getItem('token') ?? "undefined";
    }

  }

  // Get a user attribute from cognito
  public async getAttribute(name: string): Promise<string | null> {
    if (!(await this.isLoggedIn())) {
      return null;
    }
    const user = await this.getCurrentUser();
    if (!user) {
      return null;
    }
    return new Promise((callback: (value: string | null) => void) => {
      user.getUserAttributes(function (err, attrs) {
        if (err || !attrs) {
          callback(null);
          return;
        }
        attrs.forEach((attr: { Name: string; Value: string }) => {
          if (attr.Name === name) {
            callback(attr.Value);
          }
        });
        callback(null);
      });
    });
  }

  // Was the user account created through LinkedIn?
  public async isLinkedInAccount(): Promise<boolean> {
    const identities = await this.getAttribute('identities');
    if (!identities) {
      return false;
    }
    return JSON.parse(identities)[0].providerName === 'LinkedIn';
  }

  // Get the user role
  public async getUserRole(): Promise<GetUserRole | null> {
    return this.httpService.sendGetRequest('user/role', await this.getUserToken());
  }

  // Get user data
  public async getUserDataT(): Promise<GetUserDataResponse | null> {
    return this.httpService.sendGetRequest('user/get', await this.getUserToken());
  }

  // Set user data, note that amplify attributes may need to be updated as well
  public async updateUserData(data: UpdateUserDataRequest): Promise<UpdateUserDataResponse | null> {
    return this.httpService.sendPostRequest('user/update?' + new URLSearchParams(data), await this.getUserToken());
  }

  // Update user last page visisted
  public async updateLastPage(page: string): Promise<boolean> {
    const res = this.updateUserData({ last_page: page });
    return res !== null;
  }

  // Get and set user profile image
  public async getProfileImage(): Promise<GetProfileImageResponse | null> {
    return this.httpService.sendGetRequest('user/image/get', await this.getUserToken());
  }

  public async updateProfileImage(file: UploadedFile): Promise<SetProfileImageResponse | null> {
    if (file.type !== UploadedFileType.File || !file.original) {
      return null;
    }
    return this.httpService.sendPostRequest('user/image/update', await this.getUserToken(), this.fileService.attachToForm(file.original));
  }

  // Validate the email address or moble number on sign up to prevent duplicate properties across users
  public async validateEmailAddress(email: string): Promise<ValidatePropertyResponse | null> {
    return this.httpService.sendGetRequest('user/validate?' + new URLSearchParams({ email: email }), await this.getUserToken());
  }

  public async validateMobileNumber(mobileNumber: string): Promise<ValidatePropertyResponse | null> {
    return this.httpService.sendGetRequest('user/validate?' + new URLSearchParams({ mobile_number: mobileNumber }));
  }

  // Delete the user account
  public async deleteUser(): Promise<boolean> {
    return await this.httpService.sendPostRequest('user/delete', await this.getUserToken()) !== null && await Auth.deleteUser() === 'SUCCESS';
  }

  public async updateUserEmail(data: UpdateUserDataRequest): Promise<UpdateUserDataResponse> {
    return this.httpService.sendPostRequest('user/update?' + new URLSearchParams(data), await this.getUserToken());
  }

  // Send a password reset request
  public changePasswordRequest = async (email: string): Promise<any> => {
    return this.httpService.sendPostRequest('user/change-password-request', await this.getUserToken(), { email: email })
  }

  // Change the user password
  public changePassword = async (payload: any): Promise<any> => {
    return this.httpService.sendPostRequest('user/change-password', await this.getUserToken(), { ...payload })
  }

  // backend method for uploading and etraxting file for role description
  public async roleDesciptionExtract(file: File): Promise<{ status: string, result: string } | null > {
    return this.httpService.sendPostRequest('roleExtraction/extract', await this.getUserToken(), this.fileService.attachToForm(file));
  }

  // backend method to check if the entered email exists in the db
  public async checkEmailExists(email: string): Promise<any> {
    return this.httpService.sendGetRequest('user/check-email?' + new URLSearchParams({ email: email }), undefined);
  }

  public async linkedInAuthorize(code: string): Promise<any> {
    return this.httpService.sendPostRequest('user/linkedIn/code', undefined ,{ code: code });
  }
}
