export enum QuestionType {
  FinancialsValue = 'formula-value',
  RewardPenalty = 'reward-penalty',
  WrittenResponse = 'written-response',
}

export enum NeedsAnalysisTaskType {
  General = 'General',
  Software = 'Software',
  Payroll = 'General Payroll',
  Banking = 'Bank Reconciliations',
  AR = 'Accounts Receivable',
  AP = 'Accounts Payable',
  EOM = 'EOM Reporting',
  EOFY = 'EOFY Reconciliations',
  BAS = 'IAS & BAS Reconciliations & Lodgement',
}