import React from 'react'

import styles from './tooltip.module.scss'
import colors from '../../../styles/variables.module.scss'
import QuestionMarakIcon from '../../../icons/questionMarak.icon'



type TooltipProps = {
  body:string
  leftAlign?:boolean
}

function Tooltip({ body,leftAlign }: TooltipProps) {

  return (
  <div className={styles.tooltip}>
    <QuestionMarakIcon color={colors.infoColor} size={18}/>
    <span className={leftAlign ? styles.leftAlign : styles.tooltiptext}>{body}</span>
  </div>
  )
}

export default Tooltip
