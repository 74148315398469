import React, { useState } from 'react'
import styles from './assesmentCard.module.scss'
import InfoIcon from "../../icons/info.icon";
import colors from '../../styles/variables.module.scss'
import CustomButton from "../shared/CustomButton/customButton";
import RightArrowIcon from "../../icons/rightArrow.icon";
import CustomModal from "../shared/customModal/customModal";
import StartAssessmentModal from '../assesment/startAssessmentModal';

type assessmentProps = {
  softSkill?:boolean
  title?:string
  description?:string
  topthree?:boolean
}


function AssessmentCard({ softSkill,title,description,topthree }:assessmentProps) {
  const [modal, setModal] = useState(false);
  return (
    <div className={topthree ? styles.topthreeCard : styles.card} style={softSkill ? { borderColor:colors.infoColor } : { borderColor:colors.dangerColor }}>
      <div className={softSkill ? styles.blueTag : styles.redTag}>
        <InfoIcon color={softSkill ? colors.infoColor : colors.dangerColor} />
        {softSkill ? <h6 className={'text-info-color ms-2 text-bold'}>Optional</h6>
          :
          <h6 className={'text-danger ms-2 text-bold'}>Required to Unlock your Match</h6>
        }
      </div>
        <h3 className={'mt-3 text-bolder'}>{title}</h3>
      {softSkill ?
        <h6 className={'text-normal gray-color-text mt-3'}><span className={'text-bold me-2'}>Coming soon -</span>{description}</h6>
        :
        <h6 className={'text-normal gray-color-text mt-3'}>{description}</h6>
      }

      <CustomButton disabled={!!softSkill} text={'Start Now'} type={'button'} iconSide={'right'} icon={<RightArrowIcon color={softSkill ? colors.teritaryColor500 : colors.whiteColor}/>} className={`mt-4 ${softSkill ? styles.disabledBtn : styles.widthBtn}`} onClick={()=>setModal(true)}/>
      <CustomModal title={'Welcome to the Technical Ability Assessment'}  open={modal} onCloseModal={(val)=>setModal(val)}  >
        <StartAssessmentModal  />
      </CustomModal>

    </div>
  )
}

export default AssessmentCard
