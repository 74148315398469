import React, { useCallback, useEffect, useMemo, useState } from 'react'
import styles from './../assesmentCard/assesmentCard.module.scss'
import CustomButton from "../shared/CustomButton/customButton";
import { Form, Formik } from "formik";
import InfoIcon from "../../icons/info.icon";
import LoadingService from "../../services/loading/loading.service";
import * as Yup from "yup";
import EclipseIcon from "../../icons/eclipseIcon";
import LeftArrow from '../../icons/leftArrow.icon';
import OrganizationService from '../../services/organization/organization.service';
import { useNavigate, useSearchParams } from 'react-router-dom';
import SuccessIcon from '../../icons/success.icon';
import InProgressIcon from '../../icons/inProgress.icon';
import { MyobConfirmAccessResponse, MyobRequestStatus, XeroConfirmAccessResponse } from '../../services/organization/organization.res.model';
import { RequestStatus } from '../../services/http/http.model';
import CustomDropdown from '../shared/CustomDropdown/customDropdown';
import CustomInputField from '../shared/customInputField/CustomInputField';
import { MyobExtractCredentialsRequest } from '../../services/organization/organization.req.model';
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';

export enum FinancialSystemOption {
  MYOB = 'MYOB',
  XERO = 'XERO',
}

type CompanySizingProps = {
  closeModal: () => void;
  orgID: string;
  page: string;
  jobID?: string;
  availableOptions: (FinancialSystemOption | string)[];
};

type ConnectFormValues = {
  option?: FinancialSystemOption;
};

type MyobDetailsFormValues = {
  file?: string;
  fileUsername?: string;
  filePassword?: string;
};

enum ExtractStatus {
  NotStarted = 'Not Started',
  InProgress = 'In Progress',
  Success = 'Success',
  Failed = 'Failed',
}

enum CompanySizingModalPage {
  Connect = 'connectApi',
  MyobDetails = 'myobDetails',
  Finishing = 'finishing',
}

function CompanySizingModal({ closeModal, orgID, page, jobID, availableOptions }: CompanySizingProps) {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState<CompanySizingModalPage>(CompanySizingModalPage.Connect);
  const [service, setService] = useState<FinancialSystemOption | null>(null);
  const [status, setStatus] = useState<ExtractStatus>(ExtractStatus.NotStarted);
  const [showLogin, setShowLogin] = useState<boolean>(false);
  const [companyFiles, setCompanyFiles] = useState<{ name: string; uri: string }[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  // Services.
  const loadingService = useMemo(() => new LoadingService(setIsLoading), []);
  const orgService = useMemo(() => new OrganizationService(), []);

  // Validation schema for the first page.
  const connectValidationSchema = Yup.object().shape({
    option: Yup.string().required('Please select an option.'),
  });

  // Validation scheme for the myob details page.
  const myobDetailsValidationSchema = Yup.object().shape({
    file: Yup.string().required('Please select a file.'),
    fileUsername: Yup.string().test('username', 'Please enter a valid username.', (value) => {
      return (value !== undefined && value !== null && value.length > 0) || !showLogin;
    }),
    filePassword: Yup.string().test('password', 'Please enter a valid password.', (value) => {
      return (value !== undefined && value !== null && value.length > 0) || !showLogin;
    }),
  });

  /**
   * Request Xero access. Get the authorization url and redirect to it.
   * @returns void
   */
  const requestXeroAccess = useCallback((): void => {
    loadingService.await(orgService.getXeroUrl({
      id: orgID,
      state: JSON.stringify({ service: FinancialSystemOption.XERO, id: jobID ? jobID : orgID }),
      from: page,
    })).then((res) => {
      if (res !== null && res.status === RequestStatus.Success) {
        window.location.replace(res.result);
      }
    }).catch((error: any)=>{
      setErrorMessage(error.message)
    });
  }, [loadingService, orgService, orgID, jobID, page]);

  /**
   * Confirm Xero access. Send the code to the backend and extract data.
   * @param code The code from the url.
   * @returns void
   */
  const confirmXeroAccess = useCallback((code: string): void => {
    loadingService.await(orgService.confirmXeroAccess({ id: orgID }, { code, from: page })).then((res: XeroConfirmAccessResponse | null) => {
      setSearchParams({});
      if (res !== null && res.status === RequestStatus.Success) {
        setStatus(ExtractStatus.Success);
      } else {
        setStatus(ExtractStatus.Failed);
      }
    });
  }, [loadingService, orgService, orgID, page, setSearchParams]);

  /**
   * Request Myob access. Get the authorization url and redirect to it.
   * @returns void
   */
  const requestMyobAccess = useCallback((): void => {
    loadingService.await(orgService.getMyobUrl({
      id: orgID,
      state: JSON.stringify({ service: FinancialSystemOption.MYOB, id: jobID ? jobID : orgID }),
      from: page,
    })).then((res) => {
      if (res !== null && res.status === MyobRequestStatus.Success) {
        window.location.replace(res.result);
      }
    }).catch((error: any)=>{
      setErrorMessage(error.message)
    });
  }, [loadingService, orgService, orgID, jobID, page]);

  /**
   * Confirm Myob access. Send the code to the backend and either extract data or move to the next modal stage.
   * @param code The code from the url.
   * @returns void
   */
  const confirmMyobAccess = useCallback((code: string): void => {
    loadingService.await(orgService.confirmMyobAccess({ id: orgID }, { code, from: page })).then((res: MyobConfirmAccessResponse | null) => {
      setSearchParams({});
      if (res === null) {
        setStatus(ExtractStatus.Failed);
        return;
      }
      switch (res.status) {
        case MyobRequestStatus.ExtractSuccess:
          // If the access was confirmed, and the data was extracted, set the status to success.
          setStatus(ExtractStatus.Success);
          break;
        case MyobRequestStatus.Success:
          // If the access was confirmed, but the data was not extracted, move to the next modal stage.
          setTitle(CompanySizingModalPage.MyobDetails);
          break;
        case MyobRequestStatus.AccessDenied:
          // If the access was denied, move to the next modal stage, and ask the user for their login details.
          // This status will only be returned from the confirm request if the user has one company file, but did not have direct access to it.
          setTitle(CompanySizingModalPage.MyobDetails);
          setShowLogin(true);
          break;
        default:
          setStatus(ExtractStatus.Failed);
          break;
      }
    });
  }, [loadingService, orgService, orgID, page, setSearchParams]);

  /**
   * Extract Myob data. Send the file uri and credentials to the backend and extract data.
   * @param fileUri The file uri.
   * @param credentials The credentials (optional).
   * @param helpers The formik helpers for the myob details form.
   * @returns void
   */
  const extractMyobData = useCallback((fileUri: string, credentials: MyobExtractCredentialsRequest | null): void => {
    setTitle(CompanySizingModalPage.Finishing);
    setStatus(ExtractStatus.InProgress);
    loadingService.await(orgService.extractMyobData({ id: orgID, uri: fileUri }, credentials)).then((res) => {
      if (res === null) {
        setStatus(ExtractStatus.Failed);
        return;
      }
      switch (res.status) {
        case MyobRequestStatus.Success:
          // If the data was extracted, set the status to success.
          setStatus(ExtractStatus.Success);
          break;
        case MyobRequestStatus.AccessDenied:
          // If the access was denied, move back to the files page, and ask the user for their login details.
          setTitle(CompanySizingModalPage.MyobDetails);
          setShowLogin(true);
          // If the user has already entered their login details, show an error message.
          if (credentials !== null) {
            setErrorMessage('Invalid username or password.');
          }
          break;
        default:
          setStatus(ExtractStatus.Failed);
          break;
      }
    });
  }, [loadingService, orgService, orgID]);

  // When the title changes to MyobDetails page, get the company files.
  useEffect(() => {
    if (title === CompanySizingModalPage.MyobDetails) {
      loadingService.await(orgService.getMyobCompanyFiles({ id: orgID })).then((res) => {
        if (res !== null && res.status === MyobRequestStatus.Success) {
          setCompanyFiles(res.result);
        } else {
          setTitle(CompanySizingModalPage.Finishing);
          setStatus(ExtractStatus.Failed);
        }
      }).catch((error: any)=>{
        setErrorMessage(error.message)
      });
    }
  }, [loadingService, orgID, orgService, title]);

  // When the user returns to the app from the authorization url, get the code from the url and confirm access.
  useEffect(() => {
    const state = searchParams.get('state');
    const code = searchParams.get('code');

    // useEffect will run twice, cancel the second instance.
    if (loadingService.isLoading() || !code) {
      return;
    }

    if (state !== null) {
      try {
        const stateObj = JSON.parse(state);
        if (stateObj !== null && stateObj.id && (stateObj.id === orgID || stateObj.id === jobID)) {
          setTitle(CompanySizingModalPage.Finishing);
          setService(stateObj.service);
          setStatus(ExtractStatus.InProgress);

          // Confirm access.
          if (stateObj.service === FinancialSystemOption.XERO) {
            confirmXeroAccess(code);
          } else if (stateObj.service === FinancialSystemOption.MYOB) {
            confirmMyobAccess(code);
          }
        }
      } catch (err :any) {
        setErrorMessage(err.message)
      }
    }
  }, [searchParams, orgID, jobID, loadingService, orgService, setSearchParams, page, confirmXeroAccess, confirmMyobAccess]);


  /**
   * Handle the form submit on the first page.
   * Redirect to the authorization url of the selected financial system.
   * @param values The form values.
   * @returns void
   */
  const connectHandleSubmit = (values: ConnectFormValues): void => {
    if (title !== CompanySizingModalPage.Connect) {
      return;
    }
    if (values.option === FinancialSystemOption.XERO) {
      requestXeroAccess();
    } else if (values.option === FinancialSystemOption.MYOB) {
      requestMyobAccess();
    }
  };

  /**
   * Handle the form submit on the MyobDetails page.
   * Try to extract data from the selected company file.
   * @param values The form values.
   * @returns void
   */
  const myobDetailsHandleSubmit = (values: MyobDetailsFormValues): void => {
    if (title !== CompanySizingModalPage.MyobDetails || !values.file) {
      return;
    }
    extractMyobData(values.file, showLogin ? { username: values.fileUsername, password: values.filePassword } : null);
  };

  /**
   * Handle the modal close.
   * If the extract was successful, refresh the page to update company/ job details.
   * @returns void
   */
  const handleClose = (): void => {
    if (status === ExtractStatus.Success) {
      navigate(0);
    } else {
      closeModal();
    }
  };

  return (
    <div>
      <ErrorBannerModal open={errorMessage !== null} onClose={() => setErrorMessage(null)} errorMessage={errorMessage ?? ''} />
      {title === CompanySizingModalPage.Connect ?
        <Formik initialValues={{ option: availableOptions.includes(FinancialSystemOption.XERO) ? FinancialSystemOption.XERO : FinancialSystemOption.MYOB }} onSubmit={connectHandleSubmit} validationSchema={connectValidationSchema}>
          {({ setFieldValue, errors }) => (
            <Form>
              <div>
                <h3 className={'text-bolder'}>Role Costing based on your Organisation’s Transactions</h3>
                <h6 className={'mt-5 gray-color-text'}>You’ve told us that the financial accounting system you use is:</h6>
                <div className={'d-flex align-items-center mt-5'}>
                  {availableOptions.includes(FinancialSystemOption.MYOB) &&
                    <div className="form-check">
                      <input className="form-check-input" type="radio" name="option" id="system1" value='system1' checked={availableOptions.length > 1 ? false : availableOptions.includes(FinancialSystemOption.MYOB)}
                        onClick={() => setFieldValue('option', FinancialSystemOption.MYOB)} />
                      <label className="form-check-label" htmlFor="system1">
                        <h6 className={'gray-color-text text-normal'}>MYOB</h6>
                      </label>
                    </div>
                  }
                  {availableOptions.includes(FinancialSystemOption.XERO) &&
                    <div className="form-check ms-5">
                      <input className="form-check-input" type="radio" name="option" id="system2" value='system2' checked={availableOptions.length > 1 ? false : availableOptions.includes(FinancialSystemOption.XERO)}
                        onClick={() => setFieldValue('option', FinancialSystemOption.XERO)} />
                      <label className="form-check-label" htmlFor="system2">
                        <h6 className={'gray-color-text text-normal'}>XERO</h6>
                      </label>
                    </div>
                  }
                </div>
                {errors.option && <div className={'text-danger'}>{errors.option}</div>}
                <div className={styles.infoBox}>
                  <div>
                    <div className={'d-flex'}>
                      <InfoIcon />
                      <h6 className={'ms-3 text-info-color'}>Information</h6>
                    </div>
                    <h6 className={'text-normal text-info-color'}>{`We have developed a way of accurately costing your finance via the ${availableOptions.includes(FinancialSystemOption.XERO) ? 'XERO' : 'MYOB'} API (application programming interface - a way for two or more computer programs to communicate with each other). By clicking on Continue below, you will allow us to use the ${availableOptions.includes(FinancialSystemOption.XERO) ? 'XERO' : 'MYOB'} API for 30 minutes to assess what your role should cost based on a number of key tasks and the average transactions per month for each task. This process accesses non-confidential information by accessing your accounting data without accessing your password and is very secure.`} </h6>
                  </div>
                </div>
              </div>
              <div className={'d-flex justify-content-between mt-4'}>
                <CustomButton text={'Go Back'} icon={<LeftArrow />} className={styles.nonOutlinedButton} onClick={closeModal} disabled={isLoading} />
                <CustomButton text={'Continue'} className={styles.widthButton} type={'submit'} disabled={isLoading} loading={isLoading} />
              </div>
            </Form>
          )}
        </Formik>
        : title === CompanySizingModalPage.Finishing ?
          <div>
            <h3 className={'text-bolder'}>Connecting financial systems</h3>
            <div className={'d-flex  align-items-center justify-content-center mt-5'}>
              <h3 className={'text-bolder me-5'}>FinMatched</h3>
              <EclipseIcon />
              <h3 className={'text-bolder ms-5'}>{service}</h3>
            </div>
            <div className={styles.loadingContent}>
              <div className={`d-flex justify-content-between align-items-center mt-4 ${styles.Loadingcard}`}>
                <h6 className={'gray-color-text text-normal'}>Extracting data...</h6>
                {
                  status === ExtractStatus.InProgress ?
                    <div className={'d-flex align-items-center'}>
                      <h6 className={'text-warning me-2'}>In Progress</h6>
                      <InProgressIcon />
                    </div>
                    : status === ExtractStatus.Success ?
                      <SuccessIcon />
                      : status === ExtractStatus.Failed ?
                        <h6 className={'gray-color-text me-2'}>Failed</h6>
                        :
                        <h6 className={'gray-color-text me-2'}>Pending</h6>
                }
              </div>
            </div>
            <div className={'d-flex justify-content-between mt-4'}>
              <CustomButton text={'Go Back'} icon={<LeftArrow />} className={styles.nonOutlinedButton} onClick={handleClose} disabled={isLoading} />
              <CustomButton text={'Finish'} className={styles.widthButton} onClick={handleClose} disabled={isLoading} loading={isLoading} />
            </div>
          </div>
          : title === CompanySizingModalPage.MyobDetails ? (
            <Formik initialValues={{}} onSubmit={myobDetailsHandleSubmit} validationSchema={myobDetailsValidationSchema}>
              {({ setFieldValue, errors }) => (
                <Form>
                  <div>
                    <h3 className={'text-bolder'}>Select the Organisation Data File</h3>
                    <div className={'mt-3'}>
                      <CustomDropdown name={'file'} placeHolder={'Organisation Data File *'} dataList={companyFiles.map(value => ({ text: value.name, value: value.uri }))}
                        getSelectedItem={(item) => { setFieldValue('file', item.value) }} />
                      {errors.file && (
                        <div className={styles.error}>{errors.file}</div>
                      )}
                    </div>
                    {showLogin && (
                      <div>
                        <CustomInputField name={'fileUsername'} placeholder={'File Username *'} className={'mt-3'} onChange={(event: any) => setFieldValue('fileUsername', event.target.value)}/>
                        <CustomInputField name={'filePassword'} type={'password'} placeholder={'File Password *'} className={'mt-3'} onChange={(event: any) => setFieldValue('filePassword', event.target.value)}/>
                      </div>
                    )}
                  </div>
                  <div className={'d-flex justify-content-between mt-4'}>
                    <CustomButton text={'Go Back'} icon={<LeftArrow />} className={styles.nonOutlinedButton} onClick={handleClose} disabled={isLoading} />
                    <CustomButton text={'Extract'} type={'submit'} className={styles.widthButton} disabled={isLoading} loading={isLoading}/>
                  </div>
                </Form>
              )}
            </Formik>
          ) : 'Error'}
    </div>
  )
}

export default CompanySizingModal;
