import React from 'react'

import styles from './ProgressBox.module.scss'
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import colors from '../../styles/variables.module.scss'
type progressBoxProps = {
  value:number
  organization?:boolean
}

function ProgressBox({ value, organization }:progressBoxProps) {
  return (
    <div className={styles.progressBox} style={ value < 50 ? { background:colors.dangerColor50 } : value < 75 ? { background:colors.orangeColor50 } : { background:colors.successColor50 }}>
      <div>
        {organization ?
          <>
          <h4 className={'text-almostBlack text-bolder signInLineHeight'}>Complete The Process Now</h4>
            <h6 className={'mt-2 text-normal'}>For a better experience and a better match.</h6>
          </>
          :
          <>
          <h4 className={'text-almostBlack text-bolder signInLineHeight'}>Complete Your Profile Now</h4>
          <h6 className={'mt-2 text-normal'}>For a better experience and a better match.</h6>
          </>
        }
      </div>
      <div style={{ width:'100px' }}>
        <CircularProgressbar value={value}
                             text={`${value}%`}
                             styles={buildStyles({ pathColor: `${value < 50 ? colors.dangerColor : value < 75 ? colors.orangeColor : colors.successColor}`,textSize:'18px',textColor:colors.almostBlack })} />
      </div>

    </div>
  )
}

export default ProgressBox
