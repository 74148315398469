import React, { useContext, useEffect, useMemo, useState } from 'react'

import styles from './sinIn.module.scss'
import SignInImage from '../../../src/images/Professional Sign In.jpg'
import { Form, Formik } from "formik";
import CustomButton from "../shared/CustomButton/customButton";
import CustomInputField from "../shared/customInputField/CustomInputField";
import LinkedInBlackIcon from "../../icons/linkedInBlackIcon";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as Yup from 'yup';
import { Auth } from 'aws-amplify';
import UserService from '../../services/user/user.service';
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner';
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingService from '../../services/loading/loading.service';
import ProfLogo from "../../images/BBB Logo_Professional.jpg";
import ORGLogo from "../../images/BBB Logo_Organisation.jpg";
import { UserContext } from '../../context'
import { CandidateService } from '../../services/candidate/candidate.service'
import DataService from '../../services/data/data.service'
import { MasterDataContext } from '../../context/masterData'
import CustomModal from "../shared/customModal/customModal";
import ForgotModal from "./forgotModal";
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import { Urls } from '../../context/Urls'


type SignInProps = {
  email: string;
  password: string;
  rememberMe: boolean;
}

function SignIn() {
  const userData:any = useContext(UserContext);
  const masterData = useContext(MasterDataContext);
  const [searchParams] = useSearchParams();
  const [signInType] = useState(searchParams.get('type'));
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModel, setErrorModel] = useState(false)
  const navigate = useNavigate();
  const userService: UserService = useMemo(() => {
    return new UserService();
  }, []);

  const candidateService: CandidateService = useMemo(() => {
    return new CandidateService();
  }, []);

  const dataService: DataService = useMemo(() => {
    return new DataService();
  }, []);

  const [isLoading, setIsLoading] = useState(false);
  const [forgot, setForgot] = useState(false);

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading);
  }, []);

  const initialVal: SignInProps = useMemo(() => {
    return {
      email: '',
      password: '',
      rememberMe: false,
    };
  }, []);

  const validationSchema = Yup.object().shape({
    email: Yup.string().required('Please enter your email address.').email('Please enter a valid email address.'),
    password: Yup.string().required('Please enter your password.'),
  });

  useEffect(() => {
    loadingService.await(userService.isLoggedIn().then((res) => {
      if (res)  navigate('/lastPage');
    }));
  }, [navigate, userService, loadingService]);

  // Example async functions
  function asyncFunction1() {
    return new Promise((resolve) => {
      resolve(userService.getUserDataT())
    });
  }

  function asyncFunction2() {
    return new Promise((resolve) => {
      resolve(candidateService.getCandidateDataT())
    });
  }

  function asyncFunction3() {
    return new Promise((resolve) => {
      resolve(dataService.getAllList())
    });
  }

  async function fetchData(userData:any) {
    try {
      const user = await Auth.currentAuthenticatedUser()
      if (user.attributes['custom:role'] === 'user' || user.attributes['custom:role'] === undefined) {
        const [data2]:any = await Promise.all([
          asyncFunction2(),
        ]);
        userData.setCandidateData(data2);
      }
      const [data1,data3]:any = await Promise.all([
        asyncFunction1(),
        asyncFunction3(),
      ]);
      userData.setUserData(data1);
      masterData.setMasterData(data3)
    } catch (error: any) {
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const handleSubmit = async function (values: SignInProps, { setFieldError }: { setFieldError: any }) {
    loadingService.setReason('signIn', true);
    try {
      localStorage.setItem('login_form', 'App');
      await Auth.signIn(values.email, values.password);
      if (values.rememberMe) {
      }
      await fetchData(userData);


      loadingService.setReason('signIn', false);
      if (await userService.getAttribute('email_verified') === 'true') {
        navigate('/lastPage');
      } else {
        navigate('/emailVerification');
      }
    } catch (err: any) {
      if (err.name === 'NotAuthorizedException') {
        const validateRes = await userService.validateEmailAddress(values.email);
        if (validateRes === null || validateRes.status !== 'success' || validateRes.result === false) {
          setFieldError('email', 'Account not found. If you haven\'t registered yet, please sign up for a new account.');
        } else {
          setFieldError('password', 'Incorrect password. Please try again.');
        }
      } else {
        setFieldError('email', 'Failed to sign in.');
      }
      loadingService.setReason('signIn', false);
    }
  };

  const redirect = () => {
    window.location.href = `${Urls.APP_COGNITO}/oauth2/authorize?response_type=code&client_id=${Urls.APP_CLIENT_ID}&redirect_uri=${Urls.APP_REDIRECT}`;
  }

  return (
    <div>
      {isLoading && <LoadingSpinner/>}
      {(signInType === 'organizationSignIn' || signInType === 'candidateSignIn') && <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <Formik initialValues={initialVal} validationSchema={validationSchema} onSubmit={handleSubmit}>
          {({ setFieldValue }) => (
            <Form>
              <div className={'d-flex justify-content-between w-100'}>
                <div className={styles.formContainer}>
                  <div className={styles.logo}>
                    {signInType === 'organizationSignIn' ?
                    <img src={ORGLogo} alt="org logo" className={styles.logoImage}/>
                      :
                    <img src={ProfLogo} alt="react logo" className={styles.logoImage}/>
                    }
                  </div>
                  <div className={styles.leftContentBox}>
                  <div className={styles.middleContent}>
                    <h1 className={'text-almostBlack text-bolder mt-3 mt-xl-0'}>Sign In</h1>
                    {(signInType === null || signInType === 'candidateSignIn')  ?
                      <h6 className={'mt-5 text-normal signInLineHeight'}>Welcome back! Sign in to update your
                        preferences, qualifications and experience or tell us about great new skills you’ve acquired to
                        keep your matches live.</h6>
                      :
                      <h6 className={'mt-5 text-normal signInLineHeight'}>Welcome back! Sign in to update your role requirements, your role’s desciption and budget or reconnect to recost the role you’re looking to fill to keep your matches live.</h6>
                    }

                            <div className={'mt-5'}>
                                <CustomInputField name={'email'}
                                                  onChange={(event: any) => setFieldValue('email', event.target.value)}
                                                  placeholder={'Email *'}/>
                            </div>
                            <div className={'mt-3'}>
                                <CustomInputField type={'password'}
                                                  onChange={(event: any) => setFieldValue('password', event.target.value)}
                                                  placeholder={'Password *'} name={'password'}/>
                            </div>
                            <div className={'d-flex justify-content-end w-100 mt-3'}>
                                <p className={'text-bold text-almostBlack pointer'}
                                   onClick={() => setForgot(true)}>Forgot Password?</p>
                            </div>
                    <div className={'mt-4'}>
                      <CustomButton disabled={isLoading} type={'submit'} text={'Sign In with BBB'}/>
                    </div>
                    {(signInType === null || signInType === 'candidateSignIn') && <div>
                      <div className={'mt-4 d-flex justify-content-between align-items-center'}>
                        <div className={styles.devidedLine}/>
                        <p className={styles.fontColor}>Or</p>
                        <div className={styles.devidedLine}/>
                      </div>
                      <div className={'mt-4'}>
                        <CustomButton disabled={isLoading} type={'button'} text={'Sign In with LinkedIn'} onClick={redirect} icon={<LinkedInBlackIcon />} iconSide={'left'} className={styles.outlineBtn}/>
                      </div>
                    </div>}
                  </div>
                    <p className={'text-almostBlack text-normal font-size-semi mt-5'}>Don’t have an account yet?
                      <span className={'text-bold pointer '} onClick={() => { if (!isLoading) navigate('/signUp') }}> Find a role </span>
                      or
                      <span className={'text-bold pointer '} onClick={() => { if (!isLoading) navigate('/orgsignup') }}> Fill a Role </span>
                    </p>
                  </div>
                  </div>
                <div className={styles.imageContainer}>
                  <img src={SignInImage} alt={'signIn'} className={styles.image}/>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>}
      {forgot &&
          <CustomModal open={forgot} onCloseModal={(val)=>setForgot(val)}>
              <ForgotModal/>
          </CustomModal>
      }
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default SignIn
