import React from 'react';
interface Props {
  size?: number
  color?: string
}

const LocationIcon: React.FC<Props> = ({ size }) => {
  return (
        <svg
        xmlns="http://www.w3.org/2000/svg"
        width={size ?? 18}
        height={size ? size + 3 : 21}
        fill="none"
        viewBox="0 0 18 21"
      >
        <path
          stroke="#636363"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9 12a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"
        />
        <path
          stroke="#636363"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M14.657 14.657 10.414 18.9a2 2 0 0 1-2.827 0l-4.244-4.243a8 8 0 1 1 11.314 0Z"
        />
      </svg>
  )
}

export default LocationIcon
