import { RequestStatus } from "../http/http.model";

export type UploadedFile = {
  name?: string;
  contents: string;
  original?: File;
  type: UploadedFileType;
};

export enum UploadedFileType {
  File = 'file',
  URL = 'url',
}

export type GetFileResponse = {
  status: RequestStatus;
  result: { key: string, data: any } | null;
};

export type UploadedFileResponse = {
  status: RequestStatus;
};
