import React, { useEffect, useMemo, useState } from 'react'
import styles from './styles.module.scss'
import TopNavigation from '../topNavigation/topNavigation'
import LoadingService from '../../services/loading/loading.service'
import OrganizationService from '../../services/organization/organization.service'
import { useLocation, useNavigate } from 'react-router-dom'
import NeedsAnalysisService from '../../services/needsAnalysis/needsanalysis.service'
import {
  Answer,
  GetQuestionsResponse,
  Question,
  Response as NeedsAnalysisResponse,
} from '../../services/needsAnalysis/needsanalysis.res.model'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import CustomButton from '../shared/CustomButton/customButton'
import { RequestStatus } from '../../services/http/http.model'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import { NeedsAnalysisTaskType, QuestionType } from '../../services/needsAnalysis/needsanalysis.model'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import SendIcon from '../../icons/send.icon'
import colors from "../../styles/variables.module.scss";
import JobService from '../../services/job/job.service'
import SaveIcon from '../../icons/save.icon'
import ConfirmBannerModel from '../confirmBannerModal/confirmBannerModal'
import InfoIcon from "../../icons/info.icon";

// Define the order of the categories in the right side panel
const CategoryOrder = [
  NeedsAnalysisTaskType.General,
  NeedsAnalysisTaskType.Software,
  NeedsAnalysisTaskType.AR,
  NeedsAnalysisTaskType.AP,
  NeedsAnalysisTaskType.Banking,
  NeedsAnalysisTaskType.Payroll,
  NeedsAnalysisTaskType.BAS,
  NeedsAnalysisTaskType.EOM,
  NeedsAnalysisTaskType.EOFY,
]

function Analysis() {
  const navigate = useNavigate();
  const location = useLocation();

  // Define state variables
  const [selected, setSelected] = useState<NeedsAnalysisTaskType>(NeedsAnalysisTaskType.General);
  const [questions, setQuestions] = useState<Question[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [navigateAfterError, setNavigateAfterError] = useState('');
  const [jobID, setJobID] = useState<string>('');
  const [orgID, setOrgID] = useState<string>('');
  const [responses, setResponses] = useState<NeedsAnalysisResponse[]>([]);
  const [saveModalOpen, setSaveModalOpen] = useState<boolean>(false);

  // Define services
  const loadingService = useMemo(() => new LoadingService(setIsLoading), []);
  const orgService = useMemo(() => new OrganizationService(), []);
  const needsAnalysisService = useMemo(() => new NeedsAnalysisService(), []);
  const jobService = useMemo(() => new JobService(), []);
  const [errorModel, setErrorModel] = useState(false)
  /**
   * Handles the response from the get questions request
   * Sets the questions and responses state variables
   * @param res The response from the get questions request
   * @returns {void}
   */
  function handleQuestionsResponse(res: GetQuestionsResponse): void {
    if (res.status === RequestStatus.Success && res.result !== null) {
      setQuestions(res.result);
      setResponses(res.result.map((q) => ({
        question_id: q.id,
        answer_id: q.answer?.id ?? null,
        response: q.response ?? null,
      })));
    } else {
      setErrorMessage('Error loading questions. Please try again later.');
      setNavigateAfterError('/jobs');
    }
  }

  /**
   * Validates the responses
   * @param task (optional) Validate only a specific task instead of all required tasks
   * @returns {boolean} Whether all required questions have been answered
   */
  function validateResponses(task?: string): boolean {
    // Get the required financials value questions and responses for the required tasks
    const requiredQuestions = questions.filter((q: Question) => (task !== undefined ? q.task === task : true));
    const requiredResponses = responses.filter((r) => requiredQuestions.map((q) => q.id).includes(r.question_id))
      .filter((r) => (r.response !== null && r.response !== '') || r.answer_id !== null);
    // If the number of required questions and responses don't match, return false
    if (requiredResponses.length !== requiredQuestions.length) {
      return false;
    }
    return true;
  }

  /**
   * Submits the responses to the server.
   * Redirects to the relevant page on success.
   * @returns {void}
   * @async
   */
  async function submitResponses(): Promise<void> {
    // Check if all required questions have been answered
    if (!validateResponses()) {
      setErrorMessage('Please answer all required questions.');
      return;
    }

    // Send the request to the server
    const res = await loadingService.await(
      needsAnalysisService.submitResponses(orgID, responses.filter((r) => r.answer_id !== null || r.response !== null).map((r) => ({
        ...r,
        answer_id: r.answer_id ?? '',
        response: r.response ?? '',
      }))),
    );

    // Check if the request was successful
    if (res !== null && res.status === RequestStatus.Success) {
      if (jobID === '') {
        navigate('/companies');
      } else {
        navigate('/jobs');
      }
    } else {
      setErrorMessage('Error submitting responses. Please try again later.');
    }
  }

  /**
   * Saves the responses to the server.
   * Redirects to the relevant page on success.
   * @returns {void}
   * @async
   */
  async function saveResponses(): Promise<void> {
    // Send the request to the server
    const res = await loadingService.await(
      needsAnalysisService.saveResponses(orgID, responses.filter((r) => r.answer_id !== null || r.response !== null).map((r) => ({
        ...r,
        answer_id: r.answer_id ?? '',
        response: r.response ?? '',
      }))),
    );

    // Check if the request was successful
    if (res !== null && res.status === RequestStatus.Success) {
      if (jobID === '') {
        navigate('/companies');
      } else {
        navigate('/jobs');
      }
    } else {
      setErrorMessage('Error saving responses. Please try again later.');
    }
  }
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, [selected]);
  // Check if user is an organization user
  // If not, redirect to home page
  useEffect(() => {
    loadingService.await(orgService.isOrgUser()).then((res: boolean) => {
      if (!res) {
        navigate('/')
      }
    });
  }, [navigate, loadingService, orgService]);

  // Get questions for the specified job or organization
  // The jobID and orgID is passed in the location state
  useEffect(() => {
    // Check if the state is valid
    if (!location.state || !location.state.orgID) {
      setErrorMessage('Invalid state value.');
      setNavigateAfterError('/');
      return;
    }

    setOrgID(location.state.orgID);

    // Check if the jobID is specified, this is prioritized over the orgID
    if (location.state.jobID) {
      setJobID(location.state.jobID);

      // Get the questions for the job
      loadingService.await(needsAnalysisService.getQuestionsForJob(location.state.jobID)).then(handleQuestionsResponse).catch((error)=>{
        setErrorModel(true)
        setErrorMessage(error.message)
      });

      // If the jobID is not specified, get the questions for the organization
    } else {
      // Get the questions for the organization
      loadingService.await(needsAnalysisService.getQuestionsForOrg(location.state.orgID)).then(handleQuestionsResponse).catch((error)=>{
        setErrorModel(true)
        setErrorMessage(error.message)
      });
    }
  }, [navigate, loadingService, needsAnalysisService, location.state, jobService]);

  /**
   * Renders a question
   * @param question The question to display
   * @param index The index of the question
   * @returns A JSX element containing the question
   */
  function displayQuestion(question: Question, index: number): JSX.Element {
    return (
      <div
        className="mt-4 mb-2"
        key={`question-number-${index}`}
      >
        <h6>
          {question.text}
          {
            (
              responses.find((r) => r.question_id === question.id) === undefined ||
              (
                responses.find((r) => r.question_id === question.id)?.response === null ||
                responses.find((r) => r.question_id === question.id)?.response === ''
              ) &&
              responses.find((r) => r.question_id === question.id)?.answer_id === null
            ) && (
              <span className={styles.error}>*</span>
            )
          }
        </h6>
        <div className={'mt-1'}>
          {
            question.answers.length > 0 ?
              question.answers.sort((a, b) => (a.order ?? 0) - (b.order ?? 0)).map((answer: Answer) => (
                <form className={`form-check mt-4 `} key={answer.id}>
                  <input className={`form-check-input ${styles.assesmentAnswers}`} type="checkbox" name={`input ${question.id}`} id={`${question.id}`}
                    onChange={() => setResponses([...responses.filter((r) => r.question_id !== question.id), {
                      question_id: question.id,
                      answer_id: answer.id,
                      response: null,
                    }])}
                    checked={responses.find((r) => r.question_id === question.id)?.answer_id === answer.id}
                    value={answer.id}
                  />
                  <label className="form-check-label" htmlFor={`${answer.id}`} onClick={(e) => e.preventDefault()}>
                    <h6 className={'text-normal gray-color-text me-4'}>{answer.text}</h6>
                  </label>
                </form>
              )) : (
                <div className={'mt-4'}>
                  <textarea className={`form-control ${question.type !== QuestionType.WrittenResponse && styles.noResize}`} rows={question.type === QuestionType.WrittenResponse ? 3 : 1}
                    value={responses.find((r) => r.question_id === question.id)?.response == 'NaN' ? "" : responses.find((r) => r.question_id === question.id)?.response ?? ''} onChange={(e) => {
                      setResponses([...responses.filter((r) => r.question_id !== question.id), {
                        question_id: question.id,
                        answer_id: null,
                        response: question.type === QuestionType.FinancialsValue ? e.target.value.replace(/\D/g, '') : e.target.value,
                      }])
                    }} />
                </div>
              )
          }
        </div>
      </div>
    )
  }

  // Render the component
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <TopNavigation organization tabValue={jobID ? '2' : '3'} organizationLogo />
        <ErrorBannerModal open={errorMessage !== ''} onClose={() => {
          if (navigateAfterError !== null) {
            navigate(navigateAfterError);
          } else {
            setErrorMessage('');
          }
        }} errorMessage={errorMessage} />
        <ConfirmBannerModel
          open={saveModalOpen}
          onYesClick={saveResponses}
          onClose={() => setSaveModalOpen(false)}
          confirmMessage={'Are you sure you want to exit? Your responses will be saved, and you can finish the Needs Analysis later.'}
          title={'Save & Exit'}
          noText={'Cancel'}
          yesText={'Save & Exit'}
        />
        <div className={styles.contentAnalysis}>
          <div className={styles.infoBox}>
            <div className={'d-flex'}>
              <InfoIcon />
              <h6 className={'ms-3 text-info-color'}>Information</h6>
            </div>
            <h6 className={'text-normal text-info-color'}>Based on the tasks that you have told us you need completed, the sections below contain questions that tell us enough to be able to give you an accurate cost per month depending on the finance task not on the time it takes to complete it. </h6>
            <h6 className={'text-normal text-info-color'}>The information we gather through asking these questions allow us to cost your finance function based on quantitative (how many transactions you have on average) and qualitative data (how efficient your current processes are). Each question must be answered. If there is already a value in any field, it has been calculated by connecting your accounting data file in the previous step and doesn't need to be manually entered again.</h6>
          </div>
        <div className={`d-md-flex justify-content-between mt-5`}>

          <div
            className={`mx-0 ms-lg-5 me-lg-5 ${styles.organizationsFilter} col-sm-12 col-lg-3`}
          >
            <div className={`d-flex justify-content-center align-items-center flex-column py-4 mb-4 `}>
              <div>
                <h4 className="text-almostBlack text-bolder pb-3">Needs Analysis</h4>
              </div>
            </div>
            {questions.filter((q, i, a) => a.indexOf(a.find(q1 => q1.task === q.task) ?? q) === i)
              .sort((a, b) => CategoryOrder.indexOf(a.task) - CategoryOrder.indexOf(b.task))
              .map((value: Question, index: number) => (
                <div
                  className={`${styles.settingsSelection} mb-1 ${selected === value.task
                    ? styles.settingsSelected
                    : ''
                  }`}
                  key={index}
                  onClick={() => setSelected(value.task)}
                >
                  <h6
                    className={`${styles.settingsTitle} ${selected === value.task
                      ? styles.settingsTitleSelected
                      : ''
                    }`}
                  >
                    {value.task}
                    {!validateResponses(value.task) && (
                      <span className={styles.error}>*</span>
                    )}
                  </h6>
                </div>
              ))
            }
          </div>
          <div className={`mt-3 mt-lg-0 ${styles.rightSide}`}>
            <h3 className={'text-bolder'}>{selected}</h3>
            <p style={{ fontSize:'medium' }}>
              {selected == 'General' ? 'The following questions are about your current finance function, how you manage your information, who we will be communicating with and your preferences. It helps in understanding the culture of your organisation.' :
                selected == 'Software' ? 'The following questions are about the online and offline software applications you use and how they communicate with your accounting data.' +
                  ' It will also highlight any areas where you can create time efficiencies easily by integrating them.' :
                  selected == 'Accounts Receivable' ? 'The following questions are about how you manage your Accounts Receivable function -' +
                    ' how you earn money coming into your business and how you manage the invoices you issue to your customers. It will also highlight any areas where you can streamline how ' +
                    'you get paid and increase the cashflow in your business model' :
                    selected == 'Accounts Payable' ? 'The following questions are about how you manage your Accounts Payable function - how you pay your bills and supplier invoices. ' +
                      'It will also highlight any areas where you can reduce your reliance on paper and manual systems to create time efficiencies by easily integrating them with your accounting software.' :
                      selected == 'Bank Reconciliations' ? 'The following questions are about how you receive money in and out of your bank accounts and how you use your company credit cards. ' +
                        'It will also highlight any areas where you can reduce processing time by integrating your accounting software with payment gateways and your bank' :
                        selected == 'General Payroll' ? 'The following questions are about how you pay your employees including how often, any Awards and EBAs that ' +
                          'apply and tasks and communication associated with Government agencies. It will also highlight any areas where you can improve data integrity by streamlining how payroll information is captured. ' :
                          selected == 'IAS & BAS Reconciliations & Lodgement' ? 'The following questions are about how you lodge Instalment Activity Statements (IAS) ' +
                            'and Business Activity Statements (BAS) with the Australian Taxation Office and the basis on which you do so. ' :
                            selected == 'EOM Reporting' ? 'The following questions are about your End of Month processes and reporting. It includes reporting timeframes, ' +
                              'structure and who your reporting analysis is shared with. It will also highlight any areas where you can integrate your historic data to better analyse your results and ' +
                              'forecast where you might be heading. '  : 'The following questions are about your End of Year reconciliations, processes and reporting. It includes whether you have an annual ' +
                              'audit and who your finance team regularly communicates with externally.' +
                              ' It will also highlight any areas where you can improve the flow of information for a fast and efficient end to the financial year.'
              }
            </p>

            {questions.filter(q => q.task === selected && q.type === QuestionType.FinancialsValue).length > 0 && (
              <div className={'mt-4'}>
                <h5>
                  Transaction Questions
                </h5>
                {questions.filter(q => q.task === selected && q.type === QuestionType.FinancialsValue).map(displayQuestion)}
                <br />
              </div>
            )}
            {questions.filter(q => q.task === selected && q.type === QuestionType.RewardPenalty).length > 0 && (
              <div className={'mt-4'}>
                <h5>
                  Efficiency Questions
                </h5>
                {questions.filter(q => q.task === selected && q.type === QuestionType.RewardPenalty).map(displayQuestion)}
                <br />
              </div>
            )}
            {questions.filter(q => q.task === selected && q.type === QuestionType.WrittenResponse).length > 0 && (
              <div className={'mt-4'}>
                <h5>General Questions</h5>
                {questions.filter(q => q.task === selected && q.type === QuestionType.WrittenResponse).map(displayQuestion)}
                <br />
              </div>
            )}
            <div className={`w-100 d-flex  align-items-center mt-5 mb-5 ${styles.borderTop}`}>
              {
                !validateResponses() && (
                  <div>
                    <p className={`text-danger ${styles.error}`}>Please answer all required questions.</p>
                    <br />
                    <p className={`text-danger ${styles.error}`}>There are unanswered questions in the following categories:</p>
                    {questions
                      .filter((q, i, a) => a.indexOf(a.find(q1 => q1.task === q.task) ?? q) === i && !validateResponses(q.task))
                      .sort((a, b) => CategoryOrder.indexOf(a.task) - CategoryOrder.indexOf(b.task))
                      .map((q, i) => (
                        <b key={i} className={`text-danger ${styles.error}`}>
                          {q.task}<br />
                        </b>
                      ))
                    }
                  </div>
                )
              }
            </div>
            <div className={'d-flex justify-content-between align-items-center mt-5'}>
              <div className={`d-flex justify-content-center align-items-center mt-5 mb-5 ${styles.borderTop}`}>
                <CustomButton className={`${styles.outlineBtn} ${styles.widthButton}`} type={'button'} text={'Save & Exit'}
                              icon={<SaveIcon />} iconSide={'right'}
                              onClick={() => setSaveModalOpen(true)}
                              disabled={isLoading} />
              </div>
              <div className={` d-flex justify-content-center align-items-center mt-5 mb-5 ${styles.borderTop}`}>
                <CustomButton className={styles.widthButton} type={'submit'} text={'Submit Responses'}
                              icon={<SendIcon color={colors.whiteColor} />} iconSide={'right'}
                              onClick={submitResponses}
                              disabled={isLoading || !validateResponses()} />
              </div>

            </div>
          </div>
          <div
            className={
              'd-flex justify-content-between align-items-center mt-5'
            }
          >
          </div>
        </div>
      </div>

      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Analysis
