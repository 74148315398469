import React from 'react';

interface Props {
  size?: number
  color?: string
}

const FilterIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size ?? 18}
      height={size ? size - 2 : 16}
      viewBox={'0 0 18 16'}
      fill={'none'}
    >
      <path
        stroke={color ?? "#636363"}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M2.5 1h13a1 1 0 0 1 .5 1.5L11 8v7l-4-3V8L2 2.5A1 1 0 0 1 2.5 1Z"
      />
    </svg>
  )
}

export default FilterIcon
