import React, { useContext, useEffect, useMemo, useState } from 'react'

import styles from './../gettingStart/GettingStart.module.scss'

import { Form, Formik, FormikHelpers, FormikValues } from 'formik'

import CustomLayout from '../layout/commonLayout'
import { useNavigate } from 'react-router-dom'
import CustomButton from '../shared/CustomButton/customButton'
import SaveIcon from '../../icons/save.icon'
import RightLongArrowIcon from '../../icons/rightLongArrow.icon'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'
import CustomInputField from '../shared/customInputField/CustomInputField'
import PlusIcon from '../../icons/plus.icon'
import EditIcon from '../../icons/edit.icon'
import * as Yup from 'yup'
import 'yup-phone'
import LoadingService from '../../services/loading/loading.service'
import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import CloseIcon from '../../icons/close.icon'
import {
  CreateOrgResponse,
  OrgDetailsResponse,
  UpdateOrgResponse,
} from '../../services/organization/organization.res.model'
import ListService from '../../services/data/data.service'
import OrganizationService from '../../services/organization/organization.service'
import axios from 'axios'
import { Urls } from '../../context/Urls'
import { Auth } from 'aws-amplify'
import UserService from '../../services/user/user.service'

import ErrorBannerModal from '../errorBannerModal/errorBannerModal'
import { MasterDataContext } from '../../context/masterData'
import { ScrollToFieldError } from "../../scrollToFieldError/scrollToFieldError";

export type HireForProps = {
  hiringFor: string
  relationship: string
  email: string
  contactNo: string
  companyName: string
  clientID: string
  clientName: string
  clientABN: string
  clientIndustry: string
  clientType: string
  clientEmail: string
  clientContactNo: string
  clientAddress: string
  clientWebsite: string
  streetName: string
  city: string
  postalCode: string
  state: string
}

type Client = {
  id: string
  businessName: string
  ABN: string
  industry: string
  type: string
  email: string
  contactNo: string
  address: string
  website: string
  relationship: string
  streetName?: string
  city?: string
  postalCode?: string
  state?: string
}

enum HiringForOptions {
  MyOrganization = 'organization',
  ForSomeone = 'forSomeone',
}

const MANUAL_ENTRY = 'MANUAL_ENTRY'

function HireFor() {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModel, setErrorModel] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [inputLoc, setInputLoc] = useState('')
  const [modal, setModal] = useState(false)
  const [modalText, setModalText] = useState('')
  const masterData = useContext(MasterDataContext);
  const [initialVal, setInitialVal] = useState<HireForProps>({
    hiringFor: localStorage.getItem('hiringFor') ?? '',
    relationship: '',
    email: '',
    contactNo: '',
    companyName: '',
    clientID: '',
    clientName: '',
    clientABN: '',
    clientIndustry: '',
    clientType: '',
    clientEmail: '',
    clientContactNo: '',
    clientAddress: '',
    clientWebsite: '',
    streetName: '',
    city: '',
    postalCode: '',
    state: '',
  })

  const [clients, setClients] = useState<Client[]>([])
  const [editable, setEditable] = useState(false)
  const [relationships, setRelationships] = useState<any[]>([])
  const [types, setTypes] = useState<any[]>([])
  const [industries, setIndustries] = useState<any[]>([])

  const validationSchema = Yup.object().shape({
    hiringFor: Yup.string().required('Please select an option.'),
    relationship: Yup.string().when(['hiringFor', 'clientID'], {
      is: (hiringFor: string, clientID: string) =>
        hiringFor === HiringForOptions.ForSomeone && clientID === MANUAL_ENTRY,
      then: Yup.string().required('Please select an option.'),
    }),
    email: Yup.string().when('hiringFor', {
      is: (hiringFor: string) => hiringFor === HiringForOptions.ForSomeone,
      then: Yup.string()
        .required('Please enter your email address.')
        .email('Please enter a valid email address.'),
    }),
    contactNo: Yup.string().when('hiringFor', {
      is: (hiringFor: string) => hiringFor === HiringForOptions.ForSomeone,
      then: Yup.string()
        .required('Please enter your contact number.')
        .matches(/^(\+\d{1,15}|\d{1,15})$/, 'Invalid phone number format.').required('Please enter your contact number.').phone('AU', false, 'Please enter a valid phone number.'),
    }),
    companyName: Yup.string().when('hiringFor', {
      is: (hiringFor: string) => hiringFor === HiringForOptions.ForSomeone,
      then: Yup.string().required('Please enter your company name.'),
    }),
    clientID: Yup.string().when('hiringFor', {
      is: (hiringFor: string) => hiringFor === HiringForOptions.ForSomeone,
      then: Yup.string().required('Please select a client.'),
    }),
    clientName: Yup.string().when(['hiringFor', 'clientID'], {
      is: (hiringFor: string, clientID: string) =>
        hiringFor === HiringForOptions.ForSomeone && clientID === MANUAL_ENTRY,
      then: Yup.string().required('Please enter the client name.'),
    }),
    clientABN: Yup.string()
      .when(['hiringFor', 'clientID'], {
        is: (hiringFor: string, clientID: string) =>
          hiringFor === HiringForOptions.ForSomeone &&
          clientID === MANUAL_ENTRY,
        then: Yup.string().required('Please enter the client ABN.')
          .matches(/^[0-9]+$/, 'Must be only digits')
          .min(11, 'ABN must have 11 digits')
          .max(11, 'ABN must have 11 digits'),
      }),
    clientIndustry: Yup.string().when(['hiringFor', 'clientID'], {
      is: (hiringFor: string, clientID: string) =>
        hiringFor === HiringForOptions.ForSomeone && clientID === MANUAL_ENTRY,
      then: Yup.string().required('Please select an option.'),
    }),
    clientType: Yup.string().when(['hiringFor', 'clientID'], {
      is: (hiringFor: string, clientID: string) =>
        hiringFor === HiringForOptions.ForSomeone && clientID === MANUAL_ENTRY,
      then: Yup.string().required('Please select an option.'),
    }),
    clientEmail: Yup.string().when(['hiringFor', 'clientID'], {
      is: (hiringFor: string, clientID: string) =>
        hiringFor === HiringForOptions.ForSomeone && clientID === MANUAL_ENTRY,
      then: Yup.string()
        .required('Please enter the client email address.')
        .email('Please enter a valid email address.'),
    }),
    clientContactNo: Yup.string().when(['hiringFor', 'clientID'], {
      is: (hiringFor: string, clientID: string) =>
        hiringFor === HiringForOptions.ForSomeone && clientID === MANUAL_ENTRY,
      then: Yup.string()
        .required('Please enter the client contact number.')
        .phone('AU', true, 'Please enter a valid contact number.'),
    }),
    clientWebsite: Yup.string().when(['hiringFor', 'clientID'], {
      is: (hiringFor: string, clientID: string) =>
        hiringFor === HiringForOptions.ForSomeone && clientID === MANUAL_ENTRY,
      then: Yup.string().matches(
        /^\S*$/,
        "Web address couldn't contain any blank spaces",
      ),
    }),
  })

  const orgService: OrganizationService = useMemo(() => {
    return new OrganizationService()
  }, [])

  const loadingService: LoadingService = useMemo(() => {
    return new LoadingService(setIsLoading)
  }, [])

  const listService: ListService = useMemo(() => {
    return new ListService()
  }, [])

  const userService: UserService = useMemo(() => {
    return new UserService()
  }, [])


  useEffect(() => {
    if (masterData?.masterData) {
      setRelationships(masterData.masterData.filter((fd:any)=> fd.category === 'organization_relationship').sort((a: any, b: any) => a.order - b.order));
      setTypes(masterData.masterData.filter((fd:any)=> fd.category === 'org_type').sort((a: any, b: any) => a.order - b.order));
      setIndustries(masterData.masterData.filter((fd:any)=> fd.category === 'organization_industry').sort((a: any, b: any) => a.order - b.order));
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }

  }, [masterData]);


  // Get values from backend on initial page load
  useEffect(() => {
    loadingService.await(orgService.isLoggedIn()).then((res) => {
      if (!res) navigate('/signIn?type=organizationSignIn')
    })
    loadingService.await(orgService.isOrgUser()).then((res) => {
      if (!res) navigate('/')
    })
    loadingService.await(orgService.getOrgData()).then((res) => {
      if (res !== null && res.status === 'success' && res.result !== null) {

        setInitialVal((i) => ({
          ...i,
          companyName: res.result?.name ?? '',
          email: res.result?.email ?? '',
          contactNo: res.result?.phone ?? '',
          streetName: '',
          city: '',
          state: '',
          postalCode: '',
        }))
      }
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    })
    loadingService.await(orgService.getClients()).then((res) => {
      if (res === null) return
      if (res.status === 'no_data') {
        setEditable(true)
        setInitialVal((i) => ({
          ...i,
          clientID: MANUAL_ENTRY,
        }))
      }
      if (res.status !== 'success') return
      setClients(
        res.result.map((client: OrgDetailsResponse) => {
          const formattedAddress = client.address.split(',').map((component) => component.trim());
          const addressComponents = formattedAddress[0].split(' ');
          const streetNumber = addressComponents.shift() ?? '';
          // const streetName = streetNumber ? `${streetNumber}, ${addressComponents.join(' ')}` : addressComponents.join(' ');
          let streetName = streetNumber
            ? `${streetNumber} ${formattedAddress[1]}`.replace(/,/g, '')
            : formattedAddress[1].replace(/,/g, ''); // Remove commas from streetName
          const city = formattedAddress[formattedAddress.length - 3];
          const state = formattedAddress[formattedAddress.length - 2];
          const postalCode = formattedAddress[formattedAddress.length - 1];

          return {
            id: client.id,
            businessName: client.name,
            ABN: client.ABN,
            industry: client.industry,
            type: client.type,
            email: client.email,
            contactNo: client.phone,
            address: client.address,
            streetName,
            city,
            state,
            postalCode,
            website: client.website,
            relationship: client.user_relationship,
          };
        }),
      );
    }).catch((error: any)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    })

  }, [navigate, loadingService, orgService, listService])

  // Handle change client in dropdown menu
  function handleSelectClient(
    clientID: string,
    setFieldValue: (field: string, value: string) => void,
  ) {

    const client = clients.find((client) => client.id === clientID)

    setInputLoc(client?.address ?? '')
    if (!client) {
      setFieldValue('clientName', '')
      setFieldValue('clientABN', '')
      setFieldValue('clientIndustry', '')
      setFieldValue('clientType', '')
      setFieldValue('clientEmail', '')
      setFieldValue('clientContactNo', '')
      setFieldValue('clientAddress', '')
      setFieldValue('clientWebsite', '')
      setFieldValue('relationship', '')
      setFieldValue('streetName', '')
      setFieldValue('city', '')
      setFieldValue('state', '')
      setFieldValue('postcode', '')
      return
    }
    setFieldValue('clientName', client.businessName)
    setFieldValue('clientABN', client.ABN)
    setFieldValue('clientIndustry', client.industry)
    setFieldValue('clientType', client.type)
    setFieldValue('clientEmail', client.email)
    setFieldValue('clientContactNo', client.contactNo)
    setFieldValue('clientAddress', client.address)
    setFieldValue('clientWebsite', client.website)
    setFieldValue('relationship',  client.relationship)
    setFieldValue('streetName', client.streetName ?? '')
    setFieldValue('city', client.city ?? '')
    setFieldValue('state', client.state ?? '')
    setFieldValue('postalCode',  client.postalCode ?? '')
  }

  function handleEditClient() {
    setEditable(true)
  }

  const [locations, setLocations] = useState([])
  const [addresses, setAddresses] = useState([])
  const handleLocationChange = async (
    event: string,
    values: any,
    setFieldValue: (field: string, value: any) => void,
  ) => {
    event ? setInputLoc(event) : setInputLoc(values.suburb)
    try {
      if (event === '') {
        setFieldValue('clientAddress', '');
        setFieldValue('city', '');
        setFieldValue('state', '');
        setFieldValue('postalCode', '');
        setLocations([])
        setInputLoc('')
      } else {
        setIsLoading(true)
        if (inputLoc === '') {
          setIsLoading(false)
          setLocations([])
          return
        }
        const response = await axios.get(
          `${Urls.APP_BASE_URL}/location/autocompletePlace?input=${inputLoc}&specific=true`,
        )
        const arr = response.data
        setAddresses(arr)
        setLocations(
          arr.map((value: any) => {
            return {
              value: value.address,
              text: value.address,
            }
          }),
        )
        setIsLoading(false)

        // Find the selected address
        const selectedAddress = arr.find((item: any) => item?.address === event)

        // Set the address values in formik
        setFieldValue('streetName', selectedAddress?.streetAddress)
        setFieldValue('city', selectedAddress?.city)
        setFieldValue('state', selectedAddress?.state)
        setFieldValue('postalCode', selectedAddress?.postalCode)
      }
    } catch (error: any) {
      setIsLoading(false)
      setErrorModel(true)
      setErrorMessage(error.message)
    }
  }

  const handleAddressSelect = async (i: any, setFieldValue: any) => {
    if (addresses && addresses.length > 0) {
      const selectedAddress: any = addresses.find(
        (item: any) => item.streetAddress === i.value || item.address === i.value,
      );

      if (selectedAddress) {
        // If selectedAddress.streetAddress contains 'undefined', then use selectedAddress.address
        const streetName = selectedAddress.streetAddress.includes('undefined')
          ? selectedAddress.address
          : selectedAddress.streetAddress.replace(/,/g, ''); // Remove commas from streetAddress

        setFieldValue('streetName', streetName);
        setFieldValue('city', selectedAddress.city);
        setFieldValue('state', selectedAddress.state);
        setFieldValue('postalCode', selectedAddress.postalCode);
        setInputLoc(streetName);
      }
    }
  };

  // Handle save client data, send to backend and then update clients state value
  async function handleSaveClient(values: HireForProps, setFieldValue: (field: string, value: string) => void) {
    if (values.clientName) {
      if (values.streetName === '' || inputLoc === '') {
        setModal(true)
        setModalText('Street Name can not be empty!')
        return
      }
    } else {
      setModal(true)
      setModalText('Ensure that all the required fields have been filled.')
    }


    try {
      let res: CreateOrgResponse | UpdateOrgResponse | null
      if (values.clientID === '' || values.clientID === MANUAL_ENTRY) {
        res = await loadingService.await(
          orgService.createClient({
            name: values.clientName,
            ABN: values.clientABN,
            industry: values.clientIndustry,
            type: values.clientType,
            email: values.clientEmail,
            phone: values.clientContactNo,
            address: `${values.streetName.trim()},${values.city.trim()},${values.state.trim()},${values.postalCode.trim()}`,
            website: values.clientWebsite,
            user_relationship: values.relationship,
          }),
        )
      } else {
        res = await loadingService.await(
          orgService.updateClient(
            { id: values.clientID },
            {
              name: values.clientName,
              ABN: values.clientABN,
              industry: values.clientIndustry,
              type: values.clientType,
              email: values.clientEmail,
              phone: `${values.clientContactNo}`,
              address: `${values.streetName.trim()},${values.city.trim()},${values.state.trim()},${values.postalCode.trim()}`,
              website: values.clientWebsite,
              user_relationship: values.relationship,
            },
          ),
        )
      }
      if (res === null || res.status !== 'success') return
      setClients([
        ...clients.filter((client) => client.id !== values.clientID),
        {
          id: res.result.id,
          businessName: values.clientName,
          ABN: values.clientABN,
          industry: values.clientIndustry,
          type: values.clientType,
          email: values.clientEmail,
          contactNo: values.clientContactNo,
          address: `${values.streetName.trim()},${values.city.trim()},${values.state.trim()},${values.postalCode.trim()}`,
          website: values.clientWebsite,
          relationship: values.relationship,
        },
      ])
      setEditable(false)
      setFieldValue('clientID', res.result.id)

    } catch (error) {
      setModal(true)
      setModalText('Please enter a valid street name.')
    }
  }

  // Handle discard changes. Reset field values by calling handleSelectClient.
  function handleDiscardChanges(
    values: HireForProps,
    setFieldValue: (field: string, value: string) => void,
  ) {
    handleSelectClient(values.clientID, setFieldValue),
    setEditable(false)
  }

  function handleCreateClient(
    setFieldValue: (field: string, value: string) => void,
  ) {
    setFieldValue('clientID', MANUAL_ENTRY)
    setFieldValue('clientName', '')
    setFieldValue('clientABN', '')
    setFieldValue('clientIndustry', '')
    setFieldValue('clientType', '')
    setFieldValue('clientEmail', '')
    setFieldValue('clientContactNo', '')
    setFieldValue('clientAddress', '')
    setFieldValue('clientWebsite', '')
    setFieldValue('relationship', '')
    setFieldValue('streetName', '')
    setFieldValue('city', '')
    setFieldValue('state', '')
    setFieldValue('postalCode', '')
    setInputLoc('')
    setEditable(true)
  }

  // Update org and client data if provided.
  async function handleSubmit(
    values: HireForProps,
    { setFieldError }: FormikHelpers<HireForProps>,
  ) {

    if (values.hiringFor === HiringForOptions.MyOrganization) {
      navigate('/organizationDetails')
    } else {
      if (values.streetName === '' || inputLoc === '') {
        setModal(true)
        setModalText('Street Name can not be empty!')
        return
      }
      if (editable && clients.length > 0) {
        setFieldError(
          'clientName',
          'Please save the client details before proceeding.',
        )
        return
      }
      const res = await loadingService.await(
        orgService.updateOrgData({
          email: values.email,
          phone: `${values.contactNo}`,
          name: values.companyName,
        }),
      )

      if (res === null || res.status !== 'success') {
        // TODO: Handle error
        return
      }

      if (values.streetName === '' && inputLoc === '') {
        setModal(true)
        setModalText('Street Name can not be empty!')
        return
      }

      if (clients.length === 0 || values.clientID === MANUAL_ENTRY) {
        const res2 = await loadingService.await(
          orgService.createClient({
            name: values.clientName,
            ABN: values.clientABN,
            industry: values.clientIndustry,
            type: values.clientType,
            email: values.clientEmail,
            phone: values.clientContactNo,
            address: `${values.streetName.trim()},${values.city.trim()},${values.state.trim()},${values.postalCode.trim()}`,
            website: values.clientWebsite,
            user_relationship: values.relationship,
          }),
        )

        if (res2 === null || res2.status !== 'success') {
          return
        }
        await loadingService.await(
          userService.updateUserData({ last_page: 'jobs' }),
        )
        navigate('/jobDetails', {
          state: {
            orgID: res2.result.id,
            orgName: res2.result.name,
            from: 'hireFor',
          },
        })
      } else {
        await loadingService.await(
          userService.updateUserData({ last_page: 'jobs' }),
        )
        navigate('/jobDetails', {
          state: {
            orgID: values.clientID,
            orgName: values.clientName,
            from: 'hireFor',
          },
        })
      }
    }
  }


  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSaveAndExit = async (values: HireForProps, setFieldError: any) => {
    await handleSubmit(values, setFieldError);
    await loadingService.await(userService.updateUserData({ last_page: "hireFor" }))
    localStorage.removeItem('masterData');
    localStorage.removeItem('token');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('tokenExpiration');
    localStorage.removeItem('login_form');
    await loadingService.await(Auth.signOut());
    navigate('/signIn?type=organizationSignIn');
  }
  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <ErrorBannerModal errorMessage={modalText} open={modal} onClose={(val) => {
        setIsLoading(false)
        setModal(val)
      }}/>
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <CustomLayout
          organization
          title={'Hiring For'}
          subTitle={`Who you're hiring for?`}
          pageName={'hiringFor'}
          hideGoBack
          progressValue={0}
        >
          <Formik
            initialValues={initialVal}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
            enableReinitialize={true}
          >
            {({ values, setFieldValue, setFieldError, errors, touched }) => (
              <Form>
                <ScrollToFieldError/>
                <div className={styles.outerContent}>
                  <h6 className={'gray-color-text w-25'}>I’m hiring</h6>
                  <div className={styles.widthRight}>
                    <div className={'d-flex'}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="organization"
                          id="organization"
                          value={HiringForOptions.MyOrganization}
                          onChange={(e) => {
                            setFieldValue('hiringFor', e.target.value)
                            localStorage.setItem('hiringFor', e.target.value)
                          }}
                          checked={
                            values.hiringFor === HiringForOptions.MyOrganization
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="organization"
                        >
                          <h6 className={'text-normal gray-color-text'}>
                            for my organisation
                          </h6>
                        </label>
                      </div>
                      <div className="form-check ms-5">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="forSomeone"
                          id="forSomeone"
                          value={HiringForOptions.ForSomeone}
                          onChange={(e) => {
                            setFieldValue('hiringFor', e.target.value)
                            localStorage.setItem('hiringFor', e.target.value)
                          }}
                          checked={
                            values.hiringFor === HiringForOptions.ForSomeone
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="forSomeone"
                        >
                          <h6 className={'text-normal gray-color-text'}>
                            on behalf of someone
                          </h6>
                        </label>
                      </div>
                      {errors.hiringFor && touched.hiringFor && (
                        <div className={styles.error}>{errors.hiringFor}</div>
                      )}
                    </div>
                    <div
                      className={styles.educationCard}
                      hidden={values.hiringFor !== HiringForOptions.ForSomeone}
                    >
                      {clients.length > 0 ? (
                        <div className={'d-flex justify-content-between mt-4'}>
                          <div className={styles.visaDropdown}>
                            <CustomDropdown
                              name={'clientID'}
                              disabled={editable}
                              dataList={clients.map((client) => ({
                                text: client.businessName,
                                value: client.id,
                              }))}
                              placeHolder={'Select Client *'}
                              getSelectedItem={(item) => {
                                handleSelectClient(item.value, setFieldValue)
                                setFieldValue('clientID', item.value)
                              }
                              }
                              selectedValue={
                                values.clientID === ''
                                  ? {
                                    text: "",
                                    value: "",
                                  }
                                  : {
                                    text: values.clientName,
                                    value: values.clientID,
                                  }
                              }
                            />
                            {errors.clientID && (
                              <div className={styles.error}>
                                {errors.clientID}
                              </div>
                            )}
                          </div>
                          <div>
                            {editable ? (
                              <div
                                className={`d-flex justify-content-between align-items-center ${styles.widthEdit}`}
                              >
                                <div onClick={() => handleSaveClient(values, setFieldValue)}>
                                  <SaveIcon />
                                </div>
                                <div
                                  onClick={() =>
                                    handleDiscardChanges(values, setFieldValue)
                                  }
                                >
                                  <CloseIcon />
                                </div>
                              </div>
                            ) : (
                              <div
                                className={`d-flex justify-content-between align-items-center ${styles.widthEdit}`}
                              >
                                <div
                                  onClick={() =>
                                    handleCreateClient(setFieldValue)
                                  }
                                >
                                  <PlusIcon />
                                </div>
                                {values.clientID !== '' && (
                                  <div onClick={handleEditClient}>
                                    <EditIcon />
                                  </div>
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <h6 className={'mt-3 text-normal text-almostBlack'}>
                          Client Details
                        </h6>
                      )}
                      <div className={'mt-3'}>
                        <CustomInputField
                          name={'clientName'}
                          placeholder={'Business Name (Trading Name) *'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                          disabled={!editable}
                        />
                      </div>
                      <div className={'mt-3'}>
                        <CustomInputField
                          name={'clientABN'}
                          placeholder={'ABN *'}
                          type={'number'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                          disabled={!editable}
                        />
                      </div>
                      <div className={'mt-3'}>
                        <CustomDropdown
                          disabled={!editable}
                          dataList={industries.map((rel: any) => ({
                            value: rel.id,
                            text: rel.value,
                          }))}
                          placeHolder={'Industry *'}
                          getSelectedItem={(item) =>
                            setFieldValue('clientIndustry', item.value)
                          }
                          selectedValue={industries.filter((fd)=> fd.id === values.clientIndustry).map((md:any)=>({
                            value: md.id,
                            text: md.value,
                          }))[0]}
                        />
                        {errors.clientIndustry && touched.clientIndustry && (
                          <div className={styles.error}>
                            {errors.clientIndustry}
                          </div>
                        )}
                      </div>
                      <div className={'mt-3'}>
                        <CustomDropdown
                          disabled={!editable}
                          dataList={types.map((rel: any) => ({
                            value: rel.id,
                            text: rel.value,
                          }))}
                          placeHolder={'Organisation Type *'}
                          getSelectedItem={(item) =>
                            setFieldValue('clientType', item.value)
                          }
                          selectedValue={types.filter((fd)=> fd.id === values.clientType).map((md:any)=> ({
                            value: md.id,
                            text: md.value,
                          }))[0]}
                        />
                        {errors.clientType && touched.clientType && (
                          <div className={styles.error}>
                            {errors.clientType}
                          </div>
                        )}
                      </div>
                      <div className={'mt-3'}>
                        <CustomInputField
                          name={'clientEmail'}
                          placeholder={'Email *'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                          disabled={!editable}
                        />
                      </div>
                      <div className={'mt-3'}>
                        <CustomInputField
                          name={'clientContactNo'}
                          placeholder={'Mobile Phone *'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                          disabled={!editable}
                        />
                      </div>

                      <div className="mt-2">
                        <CustomDropdown
                          disabled={!editable}
                          name={'streetName'}
                          placeHolder={'Street Name *'}
                          onChange={(e: string) => {
                            handleLocationChange(e, values, setFieldValue)
                          }}
                          dataList={locations}
                          selectedValue={{ value: inputLoc, text: inputLoc }}
                          getSelectedItem={(i) => {
                            if (i.text) {
                              setInputLoc(i.text)
                              handleAddressSelect(i, setFieldValue)
                            }
                          }}
                        />
                        {errors.streetName && touched.streetName && (
                          <div className={styles.error}>{errors.streetName}</div>
                        )}
                      </div>

                      <div className="d-flex justify-content-between mt-2">
                        <CustomInputField
                          disabled={!editable}
                          name={'city'}
                          placeholder={'Suburb *'}
                          className={''}
                          onChange={(event: FormikValues) =>
                            setFieldValue('city', event.target.value)
                          }
                          value={values.city}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <CustomInputField
                          disabled={!editable}
                          name={'postalCode'}
                          placeholder={'Post Code *'}
                          className={''}
                          onChange={(event: FormikValues) =>
                            setFieldValue('postalCode', event.target.value)
                          }
                          value={values.postalCode}
                        />
                        &nbsp;&nbsp;&nbsp;
                        <CustomInputField
                          disabled={!editable}
                          name={'state'}
                          placeholder={'State *'}
                          className={''}
                          onChange={(event: FormikValues) =>
                            setFieldValue('state', event.target.value)
                          }
                          value={values.state}
                        />
                      </div>

                      <div className={`mt-3  ${styles.borderClass}`}>
                        <CustomInputField
                          placeholder={'Web Address'}
                          name={'clientWebsite'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                          disabled={!editable}
                        />
                      </div>
                      <h6 className={'gray-color-text mt-3 mb-3'}>Tell us about them</h6>
                      <div>
                        <CustomDropdown
                          disabled={!editable}
                          dataList={relationships.map((rel: any) => ({
                            value: rel.id,
                            text: rel.value,
                          }))}
                          placeHolder={'Relationship *'}
                          getSelectedItem={(item) =>
                            setFieldValue('relationship', item.value)
                          }
                          selectedValue={relationships.filter((fd:any) => fd.id === values?.relationship).map((md:any)=>({
                            value: md.id,
                            text: md.value,
                          }))[0] }
                        />
                        {errors.relationship && touched.relationship && (
                          <div className={styles.error}>
                            {errors.relationship}
                          </div>
                        )}
                      </div>
                      <div className={'mt-3'}>
                        <CustomInputField disabled={true}
                          name={'email'}
                          placeholder={'Email *'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                        />
                      </div>
                      <div className={'mt-3'}>
                        <CustomInputField
                          name={'contactNo'}
                          placeholder={'Mobile Phone *'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                        />
                      </div>
                      <div className={`mt-3`}>
                        <CustomInputField
                          name={'companyName'}
                          placeholder={'Referring Company Name *'}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            setFieldValue(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className={
                    'd-flex justify-content-between align-items-center mt-5'
                  }
                >
                  <div className={'d-flex align-items-center'}>
                    <CustomButton
                      type={'button'}
                      text={'Save & Exit'}
                      icon={<SaveIcon />}
                      iconSide={'left'}
                      className={`ms-3 ${styles.outlineBtn}`}
                      disabled={isLoading}
                      onClick={() => handleSaveAndExit(values, setFieldError)}
                    />
                  </div>
                  <div className={'d-flex align-items-center'}>
                    <CustomButton
                      type={'submit'}
                      text={'Continue'}
                      icon={<RightLongArrowIcon size={14} />}
                      iconSide={'right'}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </CustomLayout>
      </div>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default HireFor
