import "./App.scss";
import "react-circular-progressbar/dist/styles.css";
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom'
import GettingStart from "./components/gettingStart/gettingStart";
import ResumeImport from "./components/resumeImport/resumeImport";
import Qualification from "./components/qualification/qualification";
import QualificationWithoutImport from "./components/qualification/qualificationWithoutImport";
import Experience from "./components/experience/experience";
import Finishing from "./components/finish/finishing";
import EmailVerify from "./components/emailVerify/emailVerify";
import SignIn from "./components/signInForms/signIn";
import SignUp from "./components/signInForms/signUp";
import OrgSignUp from "./components/signInForms/organizationSignUp";
import awsConfig from "./aws-exports";
import { Amplify, Hub } from 'aws-amplify'
import Dashboard from "./components/dashboard/dashboard";
import TopThreeMatches from "./components/dashboard/topthreeMatches";
import Profile from "./components/profile/profile";
import Assessment from "./components/assesment/assessment";
import UserProvider from "./context";
import JobInner from "./components/jobInner/jobInner";
import HireFor from "./components/organizationFlow/hireFor";
import CompanyDetails from "./components/organizationFlow/companyDetails";
import Jobs from "./components/organizationFlow/jobs";
import JobDetails from "./components/organizationFlow/jobDetails";
import TopThreeResources from "./components/organizationFlow/topThreeResources";
import ProfileOverview from "./components/organizationFlow/profileOverview";
import React from 'react'
import CompanyList from "./components/organizationFlow/companyList";
import Redirect from "./components/redirect/redirect";
import AddReview from "./components/addReview/addReview";
import MasterDataProvider from './context/masterData'
import Analysis from "./components/analysis/analysis";
import TokenRefreshProvider from './context/tokenRefreshProvider'
// Configure Amplify to ignore auth events on /jobs and /companies
// Otherwise .../jobs?code=123 will trigger a sign in
const replaceState = window.history.replaceState;
if (window.location.pathname.startsWith('/jobs') || window.location.pathname.startsWith('/companies')) {
  window.history.replaceState = () => false;
}
Amplify.configure(awsConfig);
Hub.listen('auth', (data) => {
  if (data.payload.event === 'signIn_failure') {
    window.history.replaceState = replaceState;
  }
});

function AppWrapper() {
  return (
    <div className="App">
<TokenRefreshProvider>
      <BrowserRouter>
        <MasterDataProvider >
        <UserProvider >
          <Routes>
            <Route path={"*"} element={<Redirect />} />
            <Route path={"/"} element={<Redirect />} />
            <Route path={"/lastPage"} element={<Redirect />} />
            <Route path={"/gettingStart"} element={<GettingStart />} />
            <Route path={"/resumeImport"} element={<ResumeImport />} />
            <Route path={"/qualification"} element={<Qualification />} />
            <Route
              path={"/qualificationWithoutImport"}
              element={<QualificationWithoutImport />}
            />
            <Route path={"/experience"} element={<Experience />} />
            <Route path={"/finish"} element={<Finishing />} />
            <Route path={"/emailVerification"} element={<EmailVerify />} />
            <Route path={"/dashboard"} element={<Dashboard />} />
            <Route
              path={"/dashboard/TopMatches"}
              element={<TopThreeMatches />}
            />
            <Route path={"/assessment"} element={<Assessment />} />
            <Route path={"/JobInner"} element={<JobInner />} />
            <Route path={"/Profile"} element={<Profile />} />
            <Route path={"/SignIn"} element={<SignIn />} />
            <Route path={"/signUp"} element={<SignUp />} />
            <Route path={"/orgSignUp"} element={<OrgSignUp />} />
            <Route path={"/hireFor"} element={<HireFor />} />
            <Route path={"/organizationDetails"} element={<CompanyDetails />} />
            <Route path={"/jobDetails"} element={<JobDetails />} />
            <Route path={"/jobs"} element={<Jobs />} />
            <Route path={"/topThreeResources"} element={<TopThreeResources />} />
            <Route path={"/profileOverview"} element={<ProfileOverview />} />
            <Route path={"/companies"} element={<CompanyList />} />
            <Route path={'/referee_comment'} element={<AddReview />} />
            <Route path={'/analysis'} element={<Analysis />} />
          </Routes>
        </UserProvider>
        </MasterDataProvider>
      </BrowserRouter>
</TokenRefreshProvider>
    </div>
  );
}

/** function App added to control behaviour of browser router which fires up routes before interceptor
 *
 * @returns JSX Element
 */

export default AppWrapper;
