import React from 'react';

interface Props {
  size?: number
  color?: string
}

const RecycleBinIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 18}
      height={size ?? 20}
      viewBox={'0 0 18 20'}
      fill="none"

    >
      <path
        d="M1 5h16M7 9v6m4-6v6M2 5l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2l1-12M6 5V2a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v3"
        stroke={color ?? "#1A3665"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default RecycleBinIcon
