import React from 'react';

interface Props {
  size?: number
  color?: string
}

const SaveIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 18}
      height={size ?? 18}
      fill="none"
      viewBox={'0 0 18 18'}
    >
      <path
        d="M11 1v4H5V1M3 1h10l4 4v10a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2Zm8 10a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
        stroke={color ?? "#1F1F1F"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default SaveIcon
