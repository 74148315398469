import React, { useContext } from 'react'
import { useEffect, useMemo, useState } from 'react'
import styles from './Profile.module.scss'
import EditIcon from "../../icons/edit.icon";
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss';
import LoadingService from '../../services/loading/loading.service';
import BagIcon from "../../icons/bag.icon";
import CustomInputField from "../shared/customInputField/CustomInputField";
import MinusIcon from "../../icons/close.icon";
import CustomDropdown, { DropdownDataList } from "../shared/CustomDropdown/customDropdown";
import { Formik, Form } from 'formik';
import CloseIcon from '../../icons/close.icon';
import PlusIcon from '../../icons/plus.icon';
import CustomButton from '../shared/CustomButton/customButton';
import { CandidateService } from '../../services/candidate/candidate.service';
import { MasterDataContext } from '../../context/masterData'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal';
import colors from '../../styles/variables.module.scss'

export type PersonalProfileProps = {
  pageIsLoading: boolean;
  onUpdate: () => void;
};

type SavedRole = {
  id: string;
  role: string;
  years_of_experience: number;
};

type NewRole = {
  role: string;
  years_of_experience: number;
};

function WorkExperienceProfile({ pageIsLoading, onUpdate }: PersonalProfileProps) {
  const masterData = useContext(MasterDataContext);
  const [isLoading, setIsLoading] = useState(false);
  const [editable, _setEditable] = useState(false);
  const [savedRoles, setSavedRoles] = useState<SavedRole[]>([]);
  const [savedRolesToRemove, setSavedRolesToRemove] = useState<SavedRole[]>([]);
  const [newRoles, setNewRoles] = useState<NewRole[]>([]);
  const [roleList, setRoleList] = useState<DropdownDataList[]>([]);
  const [updateFlag, setUpdateFlag] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModel, setErrorModel] = useState(false)
  const loadingService = useMemo(() => new LoadingService(setIsLoading), []);
  const candidateService = useMemo(() => new CandidateService(), []);
  useEffect(() => {
    if (masterData?.masterData) {
      setRoleList(masterData.masterData.filter((fd:any)=> fd.category === 'finance_jobs').sort((a: any, b: any) => a.order - b.order).map((item:any) => ({ value: item.id, text: item.value })));
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingService, masterData?.masterData]);

  useEffect(() => {
    loadingService.await(candidateService.getWorkExperience()).then((res) => {
      if (res !== null && res.status === 'success') {
        setSavedRoles(res.result);
      }
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [loadingService, candidateService, updateFlag]);

  function handleUpdate() {
    setUpdateFlag(!updateFlag);
    onUpdate();
  }

  function setEditable(value: boolean) {
    _setEditable(value);
    setSavedRolesToRemove([]);
    setNewRoles([]);
  }

  function addBlankRole() {
    setNewRoles([...newRoles, { role: '', years_of_experience: 0 }]);
  }

  function validateRoleName(role: NewRole, index: number): boolean {
    if (index === newRoles.length - 1 && role.role === '') {
      return true;
    }
    return newRoles.concat(savedRoles.filter(value => !savedRolesToRemove.map(r => r.id).includes(value.id))).filter(r => r.role === role.role).length <= 1 && role.role !== '';
  }

  function validateRoleYears(role: NewRole): boolean {
    return !Number.isNaN(role.years_of_experience)
  }

  function validateRoles(): boolean {
    const validRoles = newRoles.filter((role, index) => validateRoleName(role, index) && validateRoleYears(role));
    return validRoles.length === newRoles.length;
  }

  async function handleSubmit() {
    let res = await loadingService.await(candidateService.removeWorkExperience(savedRolesToRemove));
    if (res === null || res.status !== 'success') {
      setErrorMessage('Error removing work experience');
      return;
    }
    res = await loadingService.await(candidateService.addWorkExperience(newRoles.filter(role => role.role !== '').map(role => ({
      role: role.role,
      years_of_experience: role.years_of_experience || 0,
    }))));
    if (res === null || res.status !== 'success') {
      setErrorMessage('Error adding work experience');
      return;
    }
    handleUpdate();
    setEditable(false);
  }

  if (newRoles.length === 0) addBlankRole();

  const initialVal: { [key: string]: string | number } = {};
  newRoles.forEach(role => {
    initialVal['preferredRole_' + newRoles.indexOf(role)] = role.role;
    initialVal['yearsInRole_' + newRoles.indexOf(role)] = Number.isNaN(role.years_of_experience) ? 0 : role.years_of_experience;
  });

  return (
    <div className={`${isLoading && loadingStyles.app_while_loading}`}>
      <ErrorBannerModal open={errorMessage !== ''} onClose={() => {
        setErrorMessage('');
      }} errorMessage={errorMessage} />
      <Formik initialValues={initialVal} onSubmit={handleSubmit} enableReinitialize={true}>
        <Form>
          <div className={`d-flex align-items-center justify-content-between ${styles.borderBottom}`}>
            <div className={'d-flex align-items-center'}>
              <BagIcon color={colors.blackColor}/>
              <h4 className={'text-almostBlack ms-3'}>Work Experience</h4>
            </div>
            <div >
                {!editable && <div onClick={() => { if (!isLoading && !pageIsLoading) setEditable(true) }}><EditIcon/></div> }
                {editable && <div onClick={() => { if (!isLoading && !pageIsLoading) setEditable(false) }}><CloseIcon/></div>}
            </div>
          </div>
          <div className={'d-flex mt-5'}>
            <h6 className={`text-normal gray-color-text ${styles.job}`}>Role Title</h6>
            <h6 className={`text-normal gray-color-text w-50`}>Relevant Experience</h6>
          </div>
          <div className={'d-flex align-items-center w-100 flex-wrap'}>
            {savedRoles.filter(value => !savedRolesToRemove.map(r => r.id).includes(value.id)).map((role: SavedRole, index) => (
              <div className={'d-flex mt-3  align-items-center w-100'} key={index}>
                <div className={styles.job}>
                  <CustomInputField name={`savedPreferredRole_${index}`} defaultValue={ roleList.filter((fd)=> fd.value === role.role)[0]?.text ?? role.role}   readOnly disabled/>
                </div>
                <div>
                  <CustomInputField name={`savedYearsInRole_${index}`} defaultValue={`${role.years_of_experience}`} className={'d-flex ms-3 '} readOnly disabled/>
                </div>
                {editable &&
                  <div className={'d-flex justify-content-end mt-0 ms-4 align-items-center'} onClick={() => setSavedRolesToRemove([...savedRolesToRemove, role])}>
                    <MinusIcon/>
                  </div>
                }
              </div>
            ))}
          </div>
          {editable &&
            <div>
              {newRoles.map((role: NewRole, index) => (
                <div className={'d-flex mt-3 '} key={index}>
                  <div className={styles.job}>
                    <CustomDropdown name={`preferredRole_${index}`} placeHolder={'Job Role *'} dataList={roleList} selectedValue={roleList.filter((fd)=> fd.value === role.role)[0]}
                                  onChange={(value) => setNewRoles([...newRoles.filter(value => newRoles.indexOf(value) < index), { ...role, role: value }, ...newRoles.filter(value => newRoles.indexOf(value) > index)])}
                                  getSelectedItem={(item: DropdownDataList) => setNewRoles([...newRoles.filter(value => newRoles.indexOf(value) < index), { ...role, role: item.value }, ...newRoles.filter(value => newRoles.indexOf(value) > index)])}/>

                    {role.role === '' && index < newRoles.length - 1 ? (
                      <div className={styles.error}>Job role is required.</div>
                    ) : !validateRoleName(role, index) && (
                      <div className={styles.error}>Duplicate role name.</div>
                    )}
                  </div>
                  <div>
                    <CustomInputField name={`yearsInRole_${index}`} placeholder={'Years *'} className={'ms-3'} value={role.years_of_experience ? `${role.years_of_experience}` : ''}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => setNewRoles([...newRoles.filter(value => newRoles.indexOf(value) < index), { ...role, years_of_experience: parseInt(event.target.value) }, ...newRoles.filter(value => newRoles.indexOf(value) > index)])}/>
                    {!validateRoleYears(role) && (
                      <p className={styles.error}>Years of experience must be a number.</p>
                    )}
                  </div>
                  { index < newRoles.length - 1 && (
                    <div className={'d-flex justify-content-end mt-0 ms-5 align-items-center'}
                          onClick={() => setNewRoles(newRoles.filter(value => newRoles.indexOf(value) !== index))}>
                      <MinusIcon/>
                    </div>
                  )}
                </div>
              ))}
              <div className={'d-flex justify-content-end mt-3 ms-3'} style={{ position: 'relative' }}>
                  <CustomButton text={'Add Another Role'} icon={<PlusIcon/>} iconSide={'left'}
                                variant={'transparent'} className={styles.fontColor} onClick={addBlankRole}
                                disabled={isLoading || pageIsLoading}/>
              </div>
              <div className={'d-flex mt-3'} style={{ position: 'relative' }}>
                  <CustomButton type={'submit'} text={'Save Changes'} className={styles.saveBtn}
                                disabled={isLoading || pageIsLoading || !validateRoles()}/>
              </div>
            </div>
          }
      </Form>
    </Formik>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default WorkExperienceProfile
