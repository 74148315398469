import React from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import CustomButton from '../shared/CustomButton/customButton'
import { Formik, Form } from 'formik'
import RecycleBinIcon from '../../icons/recycleBinIcon'
import * as Yup from 'yup'

export type AddNewKeySkillModalProps = {
  onSubmit: (data: string) => void;
  closeModal: () => void;
  initialVal: string;
};

function AddNewKeySkillModal({
  onSubmit,
  closeModal,
  initialVal,
}: AddNewKeySkillModalProps) {

  function handleSubmit({ newSkillDescription }: { newSkillDescription: string }) {
    onSubmit(newSkillDescription);
    closeModal();
  }

  const validationSchema = Yup.object().shape({
    newSkillDescription: Yup.string().required('Please enter the skill description.'),
  });

  return (
    <Formik initialValues={{ newSkillDescription: initialVal }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize={true}
    >
      {({ values, setFieldValue, errors }) => (
        <Form className={styles.modal}>
          <div>
            <div className={`mt-3 ml-3 ${styles.borderBottomLight}`}>
              <textarea
                name="newSkillDescription"
                value={values.newSkillDescription ?? ''}
                onChange={(e) => setFieldValue('newSkillDescription', e.target.value.split('.').join(''))}
                placeholder="Skill Description *"
                className={styles.textareaStyle}
              />
            </div>
            <div className={'d-flex justify-content-between mt-5'}>
              <CustomButton
                text={'Discard'}
                icon={<RecycleBinIcon />}
                onClick={closeModal}
                className={styles.outlineDiscardBtn}
              />
              <CustomButton
                text={initialVal === '' ? 'Add Skill' : 'Update Skill'}
                disabled={errors.newSkillDescription !== undefined}
                className={styles.widthBtn}
                type="submit"
              />
            </div>
          </div>
        </Form>
      )}
    </Formik>
  )
}

export default AddNewKeySkillModal
