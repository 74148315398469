import React from 'react';

interface Props {
  size?: number
  color?: string
}

const CertificateIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 48}
      height={size ?? 48}
      fill="none"
      viewBox={'0 0 48 48'}
    >
      <rect width={size ?? 48} height={size ?? 48} rx={24} fill="#F0F8FA" />
      <path
        d="M24 16v3m-4-3v6m8-6v6m-4 8.5L21 32l.5-3.5-2-2 3-.5 1.5-3 1.5 3 3 .5-2 2L27 32l-3-1.5Z"
        stroke={color ?? "#49B0BD"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CertificateIcon
