import React from 'react';

interface Props {
  size?: number
  color?: string
}

const CloseIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 20}
      height={size ?? 20}
      fill="none"
      viewBox={'0 0 20 20'}
    >
      <path
        d="m8 8 4 4m0-4-4 4m11-2a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
        stroke={color ?? "#636363"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default CloseIcon
