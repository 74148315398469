import React, { useEffect, useMemo, useState } from 'react'
import styles from './../dashboard/dashboard.module.scss'
import TopNavigation from '../topNavigation/topNavigation'

import LoadingSpinner from '../shared/CustomLoadingSpinner/LoadingSpinner'
import loadingStyles from '../shared/CustomLoadingSpinner/LoadingSpinner.module.scss'
import FilterIcon from '../../icons/filter.icon'
import CustomDropdown from '../shared/CustomDropdown/customDropdown'
import SortByIcon from '../../icons/sortBy.icon'
import JobCard from './jobCard'
import CustomButton from '../shared/CustomButton/customButton'
import PlusIcon from '../../icons/plus.icon'
import LoadingService from '../../services/loading/loading.service'
import JobService from '../../services/job/job.service'
import { GetUserJobsResponse, JobDetails } from '../../services/job/job.res.model'
import { useNavigate } from 'react-router'
import { JobStatus } from '../../services/job/job.model'
import OrganizationService from '../../services/organization/organization.service'
import NotificationBox from '../shared/notificationBox/notificationBox'
import CustomModal from '../shared/customModal/customModal'
import CompanySizingModal, { FinancialSystemOption } from './companySizingModal'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'


function Jobs() {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [jobs, setJobs] = useState<JobDetails[]>([]);
  const [activeStatusIndex, setActiveStatusIndex] = useState('0');
  const [activeSortIndex, setActiveSortIndex] = useState('0');
  const [titleFilter, setTitleFilter] = useState('');
  const [companySizing, setCompanySizing] = useState(false);
  const loadingService = useMemo(() => new LoadingService(setIsLoading), []);
  const orgService = useMemo(() => new OrganizationService(), []);
  const jobService = useMemo(() => new JobService(), []);
  const [orgId, setOrgId] = useState<string>("");
  const [errorModel, setErrorModel] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  useEffect(() => {
    loadingService.await(orgService.isOrgUser()).then((res: boolean) => {
      if (!res) {
        navigate('/');
      }
    });
    loadingService.await(jobService.getUserJobs()).then((res: GetUserJobsResponse) => {
      if (res.status === 'success') {
        setJobs(res.result);
      }
    }).catch((error)=>{
      setErrorModel(true)
      setErrorMessage(error.message)
    });
  }, [navigate, loadingService, jobService, orgService]);

  function filterJobs(job: JobDetails) {
    if (activeStatusIndex === '1' && job.status !== JobStatus.Open) {
      return false;
    }
    if (activeStatusIndex === '2' && job.status === JobStatus.Open) {
      return false;
    }
    if (titleFilter !== '' && job.title !== titleFilter) {
      return false;
    }
    return true;
  }

  function sortJobs(a: JobDetails, b: JobDetails) {
    if (activeSortIndex === '1') {
      if (!a.title) return 1;
      if (!b.title) return -1;
      return a.title.localeCompare(b.title);
    }
    return 0;
  }
  useEffect(() => {
    window.scrollTo(0, 0); // Scrolls to the top of the page
  }, []);

  const statusList = [{ value: '0', text: 'All' }, { value: '1', text: 'Live' }, { value: '2', text: 'Unpublished' }];
  const sortByList = [{ value: '0', text: 'Date Created' }, { value: '1', text: 'Role Title' }];
  return (
    <div>
      {isLoading && <LoadingSpinner />}
      <div className={`${isLoading && loadingStyles.app_while_loading}`}>
        <div className={'w-100'}>
          <TopNavigation organization tabValue={'2'} organizationLogo />
          <div className={styles.content}>
            <div className={styles.leftSide}>
              <NotificationBox clickNotification={(id)=>{setOrgId(id);setCompanySizing(true) }} />
              <div className={`${styles.statusCard}`}>
                <div className={'d-flex justify-content-between align-items-center'}>
                  <h6 className={'gray-color-text '}>Status</h6>
                  {/*<div><EyeIcon /></div>*/}
                </div>
                <div className={`d-flex w-50 justify-content-between mt-4 `}>
                  {statusList.map((val, index) => (
                    <button key={index} className={activeStatusIndex === `${index}` ? `me-3 ${styles.activeBtn}` : `me-3 ${styles.btn}`} onClick={() => setActiveStatusIndex(val.value)}>{val.text}</button>
                  ))}
                </div>
                <div className={`mt-4 mb-4 pt-4 pb-4 ${styles.filter}`}>
                  <div className={'d-flex justify-content-between align-items-center'}>
                    <h6 className={'gray-color-text'}>Filter</h6>
                    <div><FilterIcon /></div>
                  </div>
                  <div className={'w-100 mt-4'}>
                    <CustomDropdown clear placeHolder={'Role Title *'} name={'JobTitle'}
                      dataList={jobs.map((job) => job.title).filter((title, index, array) => array.indexOf(title) === index).map((title) => ({ text: title ?? '', value: title ?? '' }))}
                      getSelectedItem={(item) => setTitleFilter(item.value)}/>
                  </div>
                </div>
                <div className={'d-flex justify-content-between align-items-center'}>
                  <h6 className={'gray-color-text'}>Sort by</h6>
                  <div><SortByIcon /></div>
                </div>
                <div className={'d-flex'}>
                  {sortByList.map((value, index) => (
                    <button key={index} className={`me-3 ${activeSortIndex === `${index}` ? styles.activeBtn : styles.btn}`}
                      onClick={() => setActiveSortIndex(value.value)}>{value.text}</button>
                  ))}
                </div>
              </div>
            </div>
            <div className={'w-100'}>
              <div className={'d-flex justify-content-between  w-100'}>
                <h3 className={'text-almostBlack mb-5 text-bolder mt-4 mt-xl-0'}>Roles</h3>
                <CustomButton text={'Post a new Role'}  icon={<PlusIcon />} iconSide={'left'} variant={'transparent'} className={`mt-3 mt-md-0 ${styles.addBtn}`} onClick={() => navigate('/hireFor')} />
              </div>
              {
                jobs.filter(filterJobs).sort(sortJobs).map((job: JobDetails, index: number) => (
                  <div className={'mt-3'} key={index} >
                    <JobCard id={job.id} position={job.title ?? ""} commitment={job.commitment}
                      weeklyHours={job.weekly_work_hours} workType={job.work_type} orgID={job.__organization__?.id ?? ''}
                      taskExpertise={job.skills.filter(s => s.type === 'task_expertise').map(s => s.value)} orgName={job.__organization__?.name ?? ''}
                      status={job.status} pageIsLoading={isLoading} orgFinancialsConnected={job.__organization__?.financials_connected ?? false}
                      jobStatus={job.jobs_status ?? ''}
                      systemExpertise={job.skills.filter(s => s.type === 'system_expertise').map(s => s.value)} />
                  </div>
                ))
              }
            </div>
          </div>
        </div>
      </div>
      <CustomModal open={companySizing} onCloseModal={(val) => setCompanySizing(val)} hideCloseButton={true}>
        <CompanySizingModal closeModal={() => setCompanySizing(false)} orgID={orgId} page={'companies'}  availableOptions={jobs.filter((fd)=> fd.__organization__?.id === orgId)[0]?.skills?.filter(s => s.type === 'system_expertise').map(s => s.value) ?? [FinancialSystemOption.XERO, FinancialSystemOption.MYOB]}/>
      </CustomModal>
      <ErrorBannerModal
        open={errorModel}
        onClose={() => {
          setErrorModel(false)
        }}
        errorMessage={errorMessage}
      />
    </div>
  )
}

export default Jobs
