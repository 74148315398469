import React from 'react';

interface Props {
  size?: number
  color?: string
}

const InfoIcon: React.FC<Props> = ({ size, color }) => {
  return (
    <svg
      width={size ?? 18}
      height={size ?? 18}
      fill="none"
      viewBox={'0 0 18 18'}
    >
      <path
        d="M8.5 5.667h.008M7.667 9H8.5v3.333h.833M16 9A7.5 7.5 0 1 1 1 9a7.5 7.5 0 0 1 15 0Z"
        stroke={color ?? "#1D4FC4"}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export default InfoIcon
