import React, { useContext, useEffect, useMemo, useState } from 'react'
import styles from '../gettingStart/GettingStart.module.scss'
import CustomButton from "../shared/CustomButton/customButton";
import CustomDropdown from "../shared/CustomDropdown/customDropdown";
import RecycleBinIcon from "../../icons/recycleBinIcon";
import CustomInputField from "../shared/customInputField/CustomInputField";
import { Formik,Form } from "formik";
import { Certification } from '../profile/certificateProfile';
import * as Yup from 'yup';
import YearPicker from '../shared/calender/YearPicker';
import CustomSearch from '../shared/listSearch/listSearch';
import { MasterDataContext } from '../../context/masterData'
import ErrorBannerModal from '../errorBannerModal/errorBannerModal'

const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
const listMonths = months.map(value => { return { value, text: value } });

export type AddCertificationModalProps = {
  certification: Certification | null,
  onSubmit: (data: Partial<Certification>) => void,
  closeModal: () => void,
};

type CertificationInput = {
  certification: string;
  organization: string;
  referenceID: string;
  issueMonth: string;
  issueYear: number;
};

function AddAnotherModal({ certification, onSubmit, closeModal }: AddCertificationModalProps) {
  const masterData = useContext(MasterDataContext);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorModel, setErrorModel] = useState(false)
  const initialVal: CertificationInput = useMemo(() => {
    if (certification) {
      return {
        certification: certification.certification,
        organization: certification.organization,
        referenceID: certification.reference_id ?? '',
        issueMonth: months[new Date(certification.issue_date).getMonth()],
        issueYear: new Date(certification.issue_date).getFullYear() === 1970 ? 2000 : new Date(certification.issue_date).getFullYear(),
      };
    }
    return {
      certification: '',
      organization: '',
      referenceID: '',
      issueMonth: '',
      issueYear: 0,
    };
  }, [certification]);

  const [suggestions, setSuggestions] = useState<any[]>([]);


  useEffect(() => {

    if (masterData?.masterData) {
      setSuggestions(masterData.masterData.filter((fd:any)=> fd.category === 'licences_and_certifications').sort((a: any, b: any) => a.order - b.order));
    } else {
      if (masterData.isError) {
        setErrorModel(true)
        setErrorMessage('Failed to load master data. Please try again later.');
      }
    }


  }, [masterData]);

  const validationSchema = Yup.object().shape({
    certification: Yup.string().required('Please enter the certification.'),
    organization: Yup.string().required('Please enter the organization.'),
    referenceID: Yup.string().optional(),
    issueMonth: Yup.string().required('Please enter the issue month.'),
    issueYear: Yup.string().required('Please enter the issue year.'),
  });

  function handleSubmit(values: CertificationInput) {
    onSubmit({
      certification: values.certification,
      organization: values.organization,
      reference_id: values.referenceID,
      // Use the 10th to avoid weird timezone tricks where 1st month can become 31st previous month.
      issue_date: new Date('10 ' + values.issueMonth + ' ' + values.issueYear),
    });
    closeModal();
  }

  return (
    <> <Formik initialValues={initialVal} onSubmit={handleSubmit} enableReinitialize={true} validationSchema={validationSchema}>
      {({ values, setFieldValue, errors, touched }) => (
        <Form className={styles.modal}>
          <div >
            <div className={'mt-3'}>
              <CustomDropdown name={'certification'} placeHolder={'Name *'} dataList={suggestions.map(value => ({ text: value.value, value: value.id }))} searchedValue={values.certification}
                              onChange={(value: string) => setFieldValue('certification', value)} selectedValue={suggestions.filter((fd:any)=>fd.id === values.certification.toLowerCase()).map((md:any)=>({
                                text:md.value,
                                value:md.id,
                              }))[0]}
                              getSelectedItem={(item) => setFieldValue('certification', item.value)} />



              {errors.certification && touched.certification && (
                <div className={styles.error}>{ errors.certification }</div>
              )}
            </div>
            <div className={'mt-3'}>
              <CustomSearch name={'organization'} placeholder={'Issuing Organization *'} defaultValue={values.organization} onChange={(value: string) => setFieldValue('organization', value)}
                            endPoint={(input: string) => 'data/universities?' + new URLSearchParams({ input: input })}
                            mapResults={(res: any) => res.result.map((a: any) => a.name)}
                            minChars={3} timeout={1000} />
              {errors.organization && touched.organization && (
                <div className={styles.error}>{ errors.organization }</div>
              )}
            </div>
            <CustomInputField name={'referenceID'} placeholder={'Reference/Member number'} className={'mt-3'} onChange={(event: any) => setFieldValue('referenceID', event.target.value)}/>
            <h6 className={'text-almostBlack text-normal mt-3'}>Issuing Date</h6>
            <div className={`mt-3 ${styles.borderBottomLight} ${styles.monthYear}`}>
              <div className={styles.monthDropdownModal}>
                <CustomDropdown dropUp dataList={listMonths} placeHolder={'Month *'} selectedValue={listMonths.filter(m => m.value === values.issueMonth)[0]} getSelectedItem={(item: any) => setFieldValue('issueMonth', item.value)} />
                {errors.issueMonth && touched.issueMonth && (
                  <div className={styles.error}>{ errors.issueMonth }</div>
                )}
              </div>
              <div className={`ms-2 mt-3 mt-md-0 ${styles.yearDropdown}`}>
                <YearPicker label={'Year *'}
                            SelectYear={values.issueYear === 2000 ? new Date(values.issueYear) : values.issueYear !== 0 ? new Date(values.issueYear, 0) : undefined}
                            getSelectedYear={(date: Date) => setFieldValue('issueYear', date.getFullYear())}
                />
                {errors.issueYear && touched.issueYear && (
                  <div className={styles.error}>{ errors.issueYear }</div>
                )}
              </div>
            </div>
            <div className={'d-flex justify-content-between mt-5'}>
              <CustomButton type={'button'} className={styles.outlineDiscardBtn} icon={<RecycleBinIcon/>} iconSide={'left'} text={'Discard'} onClick={closeModal}/>
              { certification === null ?
                <CustomButton type={'submit'} text={'Add Certificate'} className={styles.modalBtn}/>
                :
                <CustomButton type={'submit'} text={'Edit Certificate'} className={styles.modalBtn} disabled={values === initialVal}/>
              }
            </div>
          </div>
        </Form>
      )}
    </Formik> <ErrorBannerModal
      open={errorModel}
      onClose={() => {
        setErrorModel(false)
      }}
      errorMessage={errorMessage}
    /></>


  )
}

export default AddAnotherModal
